/* eslint-disable camelcase */
import axiosWithAuth from '../utils/axiosWithAuth';

import { getCompleted, getCompletedList } from './completedActions';
import { errorWebhook } from './slackActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------FILE------->

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const downloadFile = (certificate_id) => (dispatch) => {
  dispatch({ type: DOWNLOAD_FILE });
  axiosWithAuth()
    .get(`${url}/api/file/download/${certificate_id}`)
    .then((res) => {
      dispatch({ type: DOWNLOAD_FILE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch(errorWebhook(DOWNLOAD_FILE_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: DOWNLOAD_FILE_FAILURE, payload: err.message });
    });
};

export const uploadFile = (file, completed_id) => (dispatch) => {
  dispatch({ type: UPLOAD_FILE });
  const formData = new FormData();
  formData.append('file', file);
  formData.append('completed_id', completed_id);

  axiosWithAuth()
    .post(`${url}/api/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      dispatch({ type: UPLOAD_FILE_SUCCESS, payload: res.data });
      dispatch(getCompleted());
      dispatch(getCompletedList());
    })
    .catch((err) => {
      dispatch(errorWebhook(UPLOAD_FILE_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: UPLOAD_FILE_FAILURE, payload: err.message });
    });
};

export const deleteFile = (certificate_id) => (dispatch) => {
  dispatch({ type: DELETE_FILE });
  axiosWithAuth()
    .delete(`${url}/api/file/delete/${certificate_id}`)
    .then((res) => {
      dispatch({ type: DELETE_FILE_SUCCESS, payload: res.data });
      dispatch(getCompleted());
      dispatch(getCompletedList());
    })
    .catch((err) => {
      dispatch(errorWebhook(DELETE_FILE_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: DELETE_FILE_FAILURE, payload: err.message });
    });
};
