import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
// import Divider from '@material-ui/core/Divider';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    width: '33.333%',
    minWidth: '300px',
  },
  card: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    height: '180px',
    boxShadow: theme.shadows[1],
    '&:hover': {
      boxShadow: theme.shadows[7],
    },
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      margin: theme.spacing(0.5, 1),
      padding: theme.spacing(2),
    },
  },
  icon: {
    margin: theme.spacing(1.5, 0),
  },
});

function ProfileCard(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Link to={props.link} key={props.link} style={{ textDecoration: 'none' }}>
        <Card className={classes.card} key={props.link}>
          <div className={classes.icon}>{props.icon}</div>
          <Typography variant="h5" color="secondary">
            {props.title}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {props.subtitle}
          </Typography>
        </Card>
      </Link>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(ProfileCard);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
