/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import CpeCompletedTable from '../components/completed/CpeCompletedTable';
import EmptyCompleted from '../components/completed/EmptyCompleted';
import Feedback from '../components/feedback/Feedback';
import { getCompleted, getCompletedList } from '../actions';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
  },
  content: {
    maxWidth: 1000,
    margin: 'auto',
  },
  heading: {
    padding: theme.spacing(1),
  },
  cpes: {
    width: 'auto',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
});

class CompletedView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // completed: [],
      getCompletedList: false,
    };
  }

  componentDidMount() {
    // console.log('CompletedView CDM');
    // Get the completed if they don't exist, OR if the lengths don't match between the completed (cards) and completedList (dictionary)
    if (
      this.props.completed.length === 0 ||
      this.props.completed.length !== Object.keys(this.props.completedList).length
    ) {
      // console.log('CompletedView CDM getCompleted()');
      this.props.getCompleted();
    }
    // If logged in and have not gotten the completedList (dictionary)
    if (
      this.props.loggingInSuccess &&
      !this.props.gettingCompletedList &&
      !this.props.getCompletedListSuccess
    ) {
      // console.log('CompletedView CDM getCompletedList()');
      this.props.getCompletedList();
      this.setState({ getCompletedList: true });
    }
  }

  componentDidUpdate() {
    // console.log(
    //   'CompletedView CDU',
    //   this.props.loggingInSuccess,
    //   this.props.gettingCompletedList,
    //   this.props.getCompletedListSuccess,
    //   this.state.getCompletedList
    // );
    if (
      this.props.loggingInSuccess &&
      !this.props.gettingCompletedList &&
      !this.props.getCompletedListSuccess &&
      !this.state.getCompletedList
    ) {
      // console.log('CompletedView CDU getCompletedList()');
      this.props.getCompletedList();
      this.setState({ getCompletedList: true });
    }
  }

  render() {
    // console.log(this.props.completed);
    const { classes } = this.props;

    const emptyOrNot =
      this.props.completed.length === 0 ? (
        <EmptyCompleted />
      ) : (
        <CpeCompletedTable completed={this.props.completed} />
      );

    const content =
      this.props.getCompletedSuccess === true ? (
        <>{emptyOrNot}</>
      ) : (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      );

    return (
      <>
        <Helmet>
          <title>Completed CPEUs | CPEU.org</title>
        </Helmet>
        <div className={classes.content}>
          <div className={classes.root}>
            <div className={classes.heading}>
              <Typography variant="h3" color="secondary">
                Completed
              </Typography>
            </div>
            <div className={classes.cpes}>{content}</div>
            <Feedback />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingInSuccess: state.userReducer.loggingInSuccess,
  completed: state.completedReducer.completed,
  getCompletedSuccess: state.completedReducer.getCompletedSuccess,

  completedList: state.completedReducer.completedList,
  gettingCompletedList: state.completedReducer.gettingCompletedList,
  getCompletedListSuccess: state.completedReducer.getCompletedListSuccess,
});

const mapActionsToProps = {
  getCompleted,
  getCompletedList,
};

const styledComponent = withStyles(styles)(CompletedView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
