import {
  GET_CPES,
  GET_CPES_SUCCESS,
  GET_CPES_FAILURE,
  CPE_DETAILS,
  CPE_DETAILS_SUCCESS,
  CPE_DETAILS_FAILURE,
  UPDATE_CPE,
  UPDATE_CPE_SUCCESS,
  UPDATE_CPE_FAILURE,
} from '../actions/index';

const initialState = {
  cpes: [],
  gettingCpes: false,
  getCpesSuccess: false,
  error: null,
  cpeCount: 0,
  next: null,
  previous: null,
  page: 1,

  cpeDetails: [],
  gettingCpeDetails: false,
  getCpeDetailsSuccess: false,

  updatingCpe: false,
  updateCpeSuccess: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CPES:
      return {
        ...state,
        gettingCpes: true,
        getCpesSuccess: false,
        error: null,
      };
    case GET_CPES_SUCCESS:
      return {
        ...state,
        gettingCpes: false,
        getCpesSuccess: true,
        cpes: action.payload.results, // could i use JSON.parse to speed this up?
        cpeCount: action.payload.count,
        page: action.payload.page,
        lastPage: action.payload.lastPage,
        limit: action.payload.limit,
      };
    case GET_CPES_FAILURE:
      return {
        ...state,
        gettingCpes: false,
        getCpesSuccess: false,
        error: action.payload,
      };

    case CPE_DETAILS:
      return {
        ...state,
        gettingCpeDetails: true,
        getCpeDetailsSuccess: false,
        error: null,
      };
    case CPE_DETAILS_SUCCESS:
      return {
        ...state,
        gettingCpeDetails: false,
        getCpeDetailsSuccess: true,
        cpeDetails: action.payload,
        updateCpeSuccess: false,
      };
    case CPE_DETAILS_FAILURE:
      return {
        ...state,
        gettingCpeDetails: false,
        getCpeDetailsSuccess: false,
        error: action.payload,
      };

    case UPDATE_CPE:
      return {
        ...state,
        updatingCpe: true,
        updateCpeSuccess: false,
        error: null,
      };

    case UPDATE_CPE_SUCCESS:
      return {
        ...state,
        updatingCpe: false,
        updateCpeSuccess: true,
      };

    case UPDATE_CPE_FAILURE:
      return {
        ...state,
        updatingCpe: false,
        updateCpeSuccess: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
