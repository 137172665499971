/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import auth0 from 'auth0-js';

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      // audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL,
      responseType: 'id_token token',
      scope: 'openid profile email',
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    // console.log('Auth.js getProfile', this.profile);
    return this.profile;
  }

  getIdToken() {
    // console.log('Auth.js getIdToken');
    return this.idToken;
  }

  getAccessToken() {
    // console.log('Auth.js getAccessToken');
    return this.accessToken;
  }

  isAuthenticated() {
    // console.log('Auth.js isAuthenticated');
    return new Date().getTime() < this.expiresAt;
  }

  signIn() {
    // console.log('Auth.js signIn');
    this.auth0.authorize();
  }

  signUp() {
    // console.log('Auth.js signUp');
    this.auth0.authorize({ screen_hint: 'signup' });
  }

  handleAuthentication() {
    // console.log('Auth.js handleAuthentication');
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          // console.log('Auth.js handleAuthentication err', err);
          return reject(err);
        }
        if (!authResult || !authResult.idToken) {
          // console.log('Auth.js handleAuthentication err2', err);
          return reject(err);
        }
        // console.log('Auth.js handleAuthentication authResult', authResult);
        this.setSession(authResult);
        // setAuth0Session(authResult)
        resolve();
      });
    }).catch((err) => {
      return new Error(err.description);
    });
  }

  setSession(authResult) {
    // console.log('Auth.js setSession:', authResult);
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    this.accessToken = authResult.accessToken;
    // set the time that the id token will expire at
    this.expiresAt = authResult.idTokenPayload.exp * 1000;
  }

  signOut() {
    // console.log('Auth.js signOut');
    this.auth0.logout({
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  }

  silentAuth() {
    // console.log('Auth.js silentAuth');
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          // console.log('Auth.js silentAuth err', err);
          return reject(err);
        }
        // console.log('Auth.js silentAuth authResult', authResult);
        this.setSession(authResult);
        // setAuth0Session(authResult);
        resolve();
      });
    }).catch((err) => {
      return new Error(err.description);
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
