/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';

// Icons
import { colors, icons, ActivityKey } from '../../_constants/icons';
// import ShareIcon from '@material-ui/icons/Share';

import CpeCompleted from './CpeCompleted';
import CpeBookmark from './CpeBookmarks';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
    '@media (max-width: 576px)': {
      margin: theme.spacing(0),
      padding: theme.spacing(1),
    },
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  headerTopLeft: {
    flex: '0 0 75%',
    flexGrow: 1,
    '@media (max-width: 600px)': {
      flex: '0 0 100%',
    },
  },
  headerTopRight: {
    flex: '0 0 25%',
    flexGrow: 1,

    justifyContent: 'center',
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      padding: theme.spacing(2, 0),
      alignItems: 'center',
    },
  },
  headerBottom: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'end',
    '@media (max-width: 600px)': {
      justifyContent: 'space-between',
    },
  },
  underHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  leftPad: {
    padding: theme.spacing(0, 0, 0, 1),
  },
}));

function CpeDetailsHeader({
  title,
  provider,
  activity_type,
  cpe_id,
  cost,
  urlHash,
}: {
  title: string;
  provider: any;
  activity_type: string;
  cpe_id: any;
  cost: any;
  urlHash: any;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const xsCost = useMediaQuery(theme.breakpoints.down('xs'));
  const smCost = useMediaQuery(theme.breakpoints.up('sm'));

  const match = activity_type.match(/[0-9]+/g);

  const colorKey = match ? match[0] : 'null';

  return (
    <div>
      <Paper className={classes.paper}>
        <div className={classes.headerTop}>
          <div className={classes.headerTopLeft}>
            <Typography variant="h5" color="secondary">
              {title}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {provider.name}
            </Typography>
          </div>
          <div className={classes.headerTopRight}>
            {urlHash && (
              <Button
                color="primary"
                variant="contained"
                href={`https://link.cpeu.org/${urlHash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Link To CPEU
              </Button>
            )}
            {cost && smCost && (
              <div>
                <Typography variant="h6" color="textPrimary" className={classes.leftPad}>
                  {cost === '0.00' ? 'Free' : `$${cost}`}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className={classes.headerBottom}>
          {cost && xsCost && (
            <div>
              <Typography variant="h6" color="textPrimary" className={classes.leftPad}>
                {cost === '0.00' ? 'Free' : `$${cost}`}
              </Typography>
            </div>
          )}
          <div className={classes.icons}>
            <CpeBookmark cpe_id={cpe_id} />
            <CpeCompleted cpe_id={cpe_id} />
          </div>
        </div>
      </Paper>
      <div className={classes.underHeader}>
        <div className={classes.line}>
          <Avatar
            style={{
              backgroundColor: colors[colorKey as ActivityKey],
            }}
          >
            {icons[colorKey as ActivityKey]}
          </Avatar>
          <Typography variant="body1" color="textPrimary" className={classes.leftPad}>
            {activity_type}
          </Typography>
        </div>
        {/* <Button color="secondary" size="small" startIcon={<ShareIcon />}>
          Share
        </Button> */}
      </div>
    </div>
  );
}

export default CpeDetailsHeader;
