/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { signInAuth0 } from '../actions';

function SecuredRoute(props) {
  const { component: Component, ...rest } = props;

  if (props.checkingSession || props.auth0SigningIn || props.auth0gettingProfile)
    return <h3 className="text-center">Validating session...</h3>;
  if (!props.auth0profile) {
    props.signInAuth0();
    return <div />;
  }
  if (props.subscriptionStatus === null || props.subscriptionStatus === 'canceled') {
    props.history.replace('/onboarding');
  }
  return (
    <Route exact={props.exact} {...rest}>
      <Component {...rest} history={props.history} />
    </Route>
  );
}

const mapStateToProps = (state) => ({
  auth0profile: state.userReducer.auth0profile,
  auth0SigningIn: state.userReducer.auth0SigningIn,
  auth0signedInSuccess: state.userReducer.auth0signedInSuccess,
  auth0gettingProfile: state.userReducer.auth0gettingProfile,
  subscriptionStatus: state.userReducer.user.subscription_status,
});

const mapActionsToProps = {
  signInAuth0,
};

export default connect(mapStateToProps, mapActionsToProps)(SecuredRoute);
