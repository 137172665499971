import React from 'react';
import { connect } from 'react-redux';
// import { getProviders } from '../actions';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import styled from '@material-ui/styles/styled';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = (theme) => ({
  card: {
    margin: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    boxShadow: theme.shadows[0],
    '&:hover': {
      boxShadow: theme.shadows[7],
    },
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      margin: theme.spacing(0.5, 1),
      padding: theme.spacing(2),
    },
  },
});

function ProviderCard(props) {
  // console.log("Provider Card props", props)
  return (
    <Card className={props.classes.card}>
      <CardContent>
        <Typography variant="h5" color="secondary">
          {props.provider.name}
        </Typography>
        {/* <Typography variant="subtitle1" color='textSecondary'>Accredited: {props.provider.accredit_date} </Typography> */}
        {/* <Typography variant="subtitle2" color='textSecondary'>Accredidation Start: {props.provider.accredit_start} </Typography> */}
        {/* <Typography variant="body1" color='textSecondary'>Accredidation End: {props.provider.accredit_end} </Typography> */}
        <Typography variant="body1" color="textPrimary">
          Website:{' '}
          <a href={props.provider.website} target="_blank" rel="noopener noreferrer">
            {props.provider.website}
          </a>{' '}
        </Typography>
      </CardContent>
    </Card>
  );
}
// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(ProviderCard);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
