/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Icons
import { colors, icons, ActivityKey } from '../../_constants/icons';
import CpeBookmark from './CpeBookmarks';
import CpeCompleted from './CpeCompleted';

import { CPEU } from '../../types/cpeu';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
  },
  CpeCards: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1.5),
    boxShadow: theme.shadows[0],
    '&:hover': {
      boxShadow: theme.shadows[7],
    },
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      margin: theme.spacing(0.5, 1),
      padding: theme.spacing(2),
    },
  },

  avatar: {
    width: theme.spacing(9.5),
    height: theme.spacing(9.5),
    '@media (max-width: 576px)': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '52px',
    },
  },
  avatarMobile: {
    display: 'none',
    '@media (max-width: 576px)': {
      display: 'flex',
      width: theme.spacing(5),
      height: theme.spacing(5),
      '& .MuiSvgIcon-root': {
        fontSize: '28px',
      },
    },
  },
  icon: {
    fontSize: '52px',
    '@media (max-width: 576px)': {
      fontSize: '28px',
    },
  },
  left: {
    padding: theme.spacing(2.5),
    '@media (max-width: 576px)': {
      padding: '0px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  right: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    '@media (max-width: 576px)': {
      padding: '0px',
    },
  },
  top: {
    paddingBottom: theme.spacing(1),
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    '@media (max-width: 576px)': {
      display: 'flex',
    },
  },
  bottomLeft: {
    // float: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  bottomLeftText: {
    '@media (max-width: 576px)': {
      paddingLeft: theme.spacing(1),
    },
  },
  bottomRight: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
  bottomRightIcons: {
    color: theme.palette.action.selected,
    // float: 'right',
  },
}));

function CpeCard({ cpe }: { cpe: CPEU }) {
  const classes = useStyles();
  const theme = useTheme();
  const xsCost = useMediaQuery(theme.breakpoints.down('xs'));

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const match = cpe.activity_type!.match(/[0-9]+/g);

  const colorKey = match ? match[0] : 'null';

  return (
    <Card className={classes.card} key={cpe.activity_number}>
      <Link
        to={`/cpe/${cpe.activity_number}`}
        key={cpe.activity_number}
        style={{ textDecoration: 'none' }}
      >
        <div className={classes.left}>
          <Avatar
            className={classes.avatar}
            style={{ backgroundColor: colors[colorKey as ActivityKey] }}
          >
            {icons[colorKey as ActivityKey]}
          </Avatar>
        </div>
      </Link>
      <div className={classes.right}>
        <Link
          to={`/cpe/${cpe.activity_number}`}
          key={cpe.activity_number}
          style={{ textDecoration: 'none' }}
        >
          <div className={classes.top}>
            <Typography variant="h5" color="secondary">
              {cpe.title}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {cpe.provider[0] ? cpe.provider[0].name : ''}
            </Typography>
          </div>
        </Link>
        <Divider />
        <div className={classes.bottom}>
          <Link
            to={`/cpe/${cpe.activity_number}`}
            key={cpe.activity_number}
            style={{ textDecoration: 'none' }}
            className={classes.bottomLeft}
          >
            <Avatar
              className={classes.avatarMobile}
              style={{ backgroundColor: colors[colorKey as ActivityKey] }}
            >
              {icons[colorKey as ActivityKey]}
            </Avatar>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.bottomLeftText}
            >
              Price: {cpe.cost === '0.00' ? 'Free' : cpe.cost === null ? '' : `$ ${cpe.cost}`}{' '}
              {xsCost && <br />}
              {/* <Hidden smUp>
                
              </Hidden> */}
              Hours: {cpe.hours}
            </Typography>
          </Link>
          <div className={classes.bottomRight}>
            <CpeBookmark cpe_id={cpe.cpe_id} />
            <CpeCompleted cpe_id={cpe.cpe_id} />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default CpeCard;
