/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import withStyles from '@material-ui/core/styles/withStyles';
import { clearSnackbar } from '../../actions';

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({});
// Severity can be:
// 'error' -> red
// 'warning' -> yellow
// 'info' -> blue
// 'success' -> green

function SimpleSnackbar(props) {
  function handleClose() {
    props.clearSnackbar();
  }
  return (
    <Snackbar open={props.snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={props.snackbarSeverity}>
        {props.snackbarMessage}
      </Alert>
    </Snackbar>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  snackbarMessage: state.uiReducer.snackbarMessage,
  snackbarOpen: state.uiReducer.snackbarOpen,
  snackbarSeverity: state.uiReducer.snackbarSeverity,
});

const mapActionsToProps = {
  clearSnackbar,
};

const styledComponent = withStyles(styles)(SimpleSnackbar);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
