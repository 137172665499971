/* eslint-disable no-console */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */

import React from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { FixedSizeList } from 'react-window';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import './slider.css';

import { getFilteredProviders, getFilteredCpes, getProviders } from '../../actions';

const styles = (theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '80vh',
    width: '500px',
    boxShadow: 'none',
    '@media (max-width: 576px)': {
      width: '90vw',
      height: '70vh',
    },
  },
  cardHeader: {
    padding: theme.spacing(2),
    height: '112px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'inherit',
    height: '72px',
  },
  list: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  listRow: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0),
    WebkitOverflowScrolling: 'touch',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  textField: {
    width: '100%',
  },
});

class ProvidersFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.providersSelectedProps,
      search: [],
    };
  }

  componentDidMount() {
    // console.log('Providers filter, CDM', this.props.getProvidersSuccess);
    if (!this.props.getProvidersSuccess) {
      this.props.getProviders();
    }
  }

  renderRow = ({ index, style, data }) => {
    if (this.props.getProvidersSuccess) {
      const { providers, checked } = data;
      const provider = providers[index];

      const results =
        provider.provider_id === '999' ? (
          'No results found'
        ) : (
          <ListItem
            alignItems="flex-start"
            dense
            button
            onClick={this.handleToggle(provider.provider_id)}
            className={this.props.classes.listRow}
            style={style}
            key={provider.provider_id}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography color="textPrimary" variant="body2">
                  {provider.name}
                </Typography>
              }
            />
            <Checkbox
              checked={checked.indexOf(provider.provider_id) !== -1}
              tabIndex={-1}
              color="primary"
            />
          </ListItem>
        );
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{results}</>;
    }
    return '';
  };

  createProviderData = memoize((providers, checked) => ({
    providers,
    checked,
  }));

  handleToggle = (value) => () => {
    // console.log('checked: ', value);
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  saveProviders = (target) => {
    // console.log('saveProviders', this.state.checked);
    // console.log('saveProviders', this.state.checked.toString());
    this.props.handleProvidersMenu(target);

    this.props.getFilteredProviders(this.state.checked);
    this.props.getFilteredCpes();
    this.props.history.push('/cpes/1');
  };

  searchProviders = (e) => {
    let providers = this.props.providers.filter((p) => {
      if (p.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        return p;
      }
      return null;
    });
    if (e.target.value !== '' && providers.length === 0) {
      providers = [{ provider_id: '999', name: 'None' }];
    }
    this.setState({ search: providers });
  };

  // Function to calculate the VH so the list can be properly sized
  vh = (v) => {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
  };

  render() {
    const providersList = this.state.search.length > 0 ? this.state.search : this.props.providers;

    const providersData = this.createProviderData(providersList, this.state.checked);
    const content = this.props.getProvidersSuccess ? (
      <List key={0} dense className={this.props.classes.list}>
        <FixedSizeList
          // height={this.vh(80) - 112 - 8}
          height={this.vh(80) - 112}
          // width={width}
          itemSize={60}
          itemCount={providersList.length}
          overscanCount={3}
          itemData={providersData}
        >
          {this.renderRow}
        </FixedSizeList>
      </List>
    ) : (
      <div className={this.props.classes.progress}>
        <CircularProgress />
      </div>
    );
    return (
      <>
        <Card className={this.props.classes.card}>
          <div className={this.props.classes.cardHeader}>
            <Typography variant="h6" color="secondary">
              Providers
            </Typography>
            <TextField
              name="search"
              id="search"
              label="Search"
              className={this.props.classes.textField}
              onChange={this.searchProviders}
              margin="dense"
              variant="outlined"
            />
          </div>
          <div className={this.props.classes.list}>{content}</div>
        </Card>
        <div className={this.props.classes.buttons}>
          <Button onClick={this.props.handleProvidersMenu} color="default">
            Close
          </Button>
          <Button onClick={this.saveProviders} color="primary">
            Save
          </Button>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  providersSelected: state.filterReducer.providersSelected,

  providers: state.providerReducer.providers,
  getProvidersSuccess: state.providerReducer.getProvidersSuccess,
});

const mapActionsToProps = {
  getFilteredProviders,
  getFilteredCpes,

  getProviders,
};

const styledComponent = withStyles(styles)(ProvidersFilter);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
