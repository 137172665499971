/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import { connect } from 'react-redux';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';

import clsx from 'clsx';
import moment from 'moment';
import Dropzone from './Dropzone';
import EditBox from './EditBox';

import { downloadFile, deleteFile, updateCompleted } from '../../actions';

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  done: {
    color: theme.palette.completed.main,
    '&:hover': {
      color: theme.palette.completed.hover,
    },
  },
  date: {
    width: '140px',
  },
  rowRecorded: {
    textDecoration: 'line-through',
  },
  tableHead: {
    lineHeight: 1,
    padding: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  dense: {
    padding: theme.spacing(0.5),
  },
  recordedColumn: {
    maxWidth: '95px',
    lineHeight: 1,
    padding: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  detailsColumn: {
    maxWidth: '50px',
    lineHeight: 1,
    padding: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  uploadedContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 0, 2, 0),
  },
  uploadedButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  downloadButton: {
    margin: theme.spacing(1, 2, 1, 0),
  },
  detailsText: {
    margin: theme.spacing(0, 0, 2, 1),
  },
});

class CpeCompletedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      completed: {},
      hoursCompleted: 0,
      hoursRecorded: 0,
      open: [],
      openedUrl: false,
    };
  }

  componentDidMount() {
    // console.log('Completed Table', this.props.completed);
    this.setState(
      {
        completed: this.props.completed,
      },
      () => this.getRowBuilder(this.state.completed)
    );
  }

  componentDidUpdate() {
    if (this.props.getUrlSuccess && this.state.openedUrl === false) {
      window.open(this.props.fileUrl);
      this.setState({ openedUrl: true });
    }
  }

  createData = (
    activity_number,
    recorded,
    learning_codes,
    performance_indicators,
    activity_type,
    title,
    provider,
    hours,
    date_completed,
    completed_id,
    file_uploaded,
    original_filename,
    certificate_id,
    paragraph
  ) => {
    // console.log(
    //   'createData',
    //   activity_number,
    //   recorded,
    //   learning_codes,
    //   performance_indicators,
    //   activity_type,
    //   title,
    //   provider,
    //   hours,
    //   date_completed,
    //   completed_id,
    //   file_uploaded,
    //   original_filename,
    //   certificate_id,
    //   paragraph
    // );
    return {
      activity_number,
      recorded,
      learning_codes,
      performance_indicators,
      activity_type,
      title,
      provider,
      hours,
      date_completed,
      completed_id,
      file_uploaded,
      original_filename,
      certificate_id,
      paragraph,
    };
  };

  getRowBuilder = (completed) => {
    // console.log('getRowBuilder', completed);
    // console.log('getRowBuilder', this.state.completed);

    const newRow = [];
    let hoursCompleted = 0;
    let hoursRecorded = 0;
    completed.map((course) => {
      newRow.push(
        this.createData(
          course.activity_number,
          course.recorded,
          course.learning_codes,
          course.performance_indicators,
          course.activity_type,
          course.title,
          course.provider[0].name,
          course.hours,
          course.date_completed,
          course.completed_id,
          course.file_uploaded,
          course.original_filename,
          course.certificate_id,
          course.paragraph === null ? '' : course.paragraph
        )
      );
      hoursCompleted += course.hours;
      hoursRecorded = course.recorded ? hoursRecorded + course.hours : hoursRecorded;
      return newRow;
    });
    // console.log("newRow", newRow);
    // this.setState({ listRan: true });
    this.setState({ rows: newRow, hoursCompleted, hoursRecorded });
  };

  handleParagraphChange = (e, activity_number) => {
    const { rows } = this.state;

    const index = rows.map((row) => row.activity_number).indexOf(activity_number);
    rows[index].paragraph = e.target.value;

    this.setState({ rows });
  };

  handleDateChange = (date_completed, activity_number) => {
    // console.log('handleDateChange', date_completed, activity_number, this.state.rows);

    const value = date_completed ? moment(date_completed).format('YYYY-MM-DD') : null;

    // console.log('handleDateChange value', value);
    const { rows } = this.state;

    const index = rows.map((e) => e.activity_number).indexOf(activity_number);
    rows[index].date_completed = value;

    // Update the database with the new date_completed
    const completed = {
      completed_id: rows[index].completed_id,
      date_completed: value,
    };
    this.props.updateCompleted(completed);

    this.setState({ rows });
  };

  handleParagraphSave = (activity_number) => {
    // console.log('handleParagraphSave', activity_number);
    const { rows } = this.state;

    const index = rows.map((e) => e.activity_number).indexOf(activity_number);

    // Update the database with the new paragraph
    const completed = {
      completed_id: rows[index].completed_id,
      paragraph: rows[index].paragraph,
    };
    this.props.updateCompleted(completed);

    this.setState({ rows });
  };

  handleDownload = (certificate_id) => {
    // console.log('handleDownload', certificate_id);

    this.props.downloadFile(certificate_id);
    this.setState({ openedUrl: false });
  };

  handleDelete = (e, certificate_id) => {
    e.preventDefault();
    // console.log('handleDelete', certificate_id);

    this.props.deleteFile(certificate_id);
  };

  handleSwitchChange = (event, activity_number) => {
    // console.log('handleSwitchChange', event.target.checked, activity_number);
    const { rows } = this.state;

    const index = rows.map((e) => e.activity_number).indexOf(activity_number);
    rows[index].recorded = event.target.checked;
    const hoursRecorded = event.target.checked
      ? this.state.hoursRecorded + rows[index].hours
      : this.state.hoursRecorded - rows[index].hours;

    // Update the database with the new recorded
    const completed = {
      completed_id: rows[index].completed_id,
      recorded: rows[index].recorded,
    };
    this.props.updateCompleted(completed);

    this.setState({ rows, hoursRecorded });
  };

  handleCollapse = (value) => {
    const { open } = this.state;
    const currentIndex = open.indexOf(value);
    const newOpen = [...open];

    if (currentIndex === -1) {
      newOpen.push(value);
    } else {
      newOpen.splice(currentIndex, 1);
    }

    this.setState({
      open: newOpen,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Typography variant="subtitle2" color="textSecondary">
          To open up your PDP in a new window, click{' '}
          <a href="https://my.cdrnet.org/PDP" target="_blank" rel="noopener noreferrer">
            here
          </a>
        </Typography>
        <Typography variant="h6" color="secondary">
          Total Hours Completed: {this.state.hoursCompleted}
        </Typography>
        <Typography variant="h6" color="secondary">
          Total Hours Recorded: {this.state.hoursRecorded}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.recordedColumn} align="center">
                Recorded with CDR?
              </TableCell>
              <Hidden xsDown>
                <TableCell className={classes.recordedColumn} align="center">
                  Certificate Uploaded?
                </TableCell>
              </Hidden>
              <TableCell className={classes.detailsColumn}>CPEU</TableCell>
              <TableCell className={classes.detailsColumn} align="center">
                Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rows.map((row) => (
              <React.Fragment key={row.activity_number}>
                <TableRow
                  hover
                  className={clsx({
                    [classes.rowRecorded]: !!row.recorded,
                  })}
                >
                  <TableCell padding="none" align="center">
                    <Switch
                      key={row.activity_number}
                      onChange={(e) => this.handleSwitchChange(e, row.activity_number)}
                      checked={!!row.recorded}
                      color="primary"
                    />
                  </TableCell>
                  <Hidden xsDown>
                    <TableCell padding="none" align="center">
                      <Checkbox
                        key={row.file_uploaded}
                        checked={row.file_uploaded}
                        color="primary"
                      />
                    </TableCell>
                  </Hidden>
                  <TableCell padding="none">
                    <Typography variant="h6" color="secondary">
                      {row.title}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {row.provider}
                    </Typography>
                  </TableCell>

                  <TableCell padding="none" align="center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => this.handleCollapse(row.activity_number)}
                    >
                      {this.state.open.indexOf(row.activity_number) !== -1 ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse
                      in={this.state.open.indexOf(row.activity_number) !== -1}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div" color="secondary">
                          CPEU Details
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          Activity Type
                        </Typography>
                        <div className={classes.detailsText}>
                          {row.activity_type !== 'Annual Meeting'
                            ? row.activity_type.match(/(\d\d\d)/)[0]
                            : 'Annual Meeting'}
                        </div>

                        <Typography variant="subtitle2" color="textSecondary">
                          Date Completed
                        </Typography>
                        <EditBox
                          date
                          value={row.date_completed}
                          handleDateChange={this.handleDateChange}
                          activity_number={row.activity_number}
                          date_completed={row.date_completed}
                        />
                        {this.props.learning_plan_type === 'lc' ? (
                          <>
                            <Typography variant="subtitle2" color="textSecondary">
                              Learning Codes
                            </Typography>
                            <div className={classes.detailsText}>
                              {row.learning_codes?.map((code) => {
                                return (
                                  <div key={code}>
                                    {code}
                                    {', '}
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            <Typography variant="subtitle2" color="textSecondary">
                              Performance Indicators (Choose one)
                            </Typography>
                            <div className={classes.detailsText}>
                              {row.performance_indicators.map((pi) => {
                                return (
                                  <div key={pi}>
                                    {pi.slice(1, -1)}
                                    {', '}
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                        <Typography variant="subtitle2" color="textSecondary">
                          Activity Title
                        </Typography>
                        <div className={classes.detailsText}>{row.title}</div>

                        <Typography variant="subtitle2" color="textSecondary">
                          Activity Provider
                        </Typography>
                        <div className={classes.detailsText}>{row.provider}</div>

                        <Typography variant="subtitle2" color="textSecondary">
                          CPE Units
                        </Typography>
                        <div className={classes.detailsText}>{row.hours}</div>

                        <Typography variant="subtitle2" color="textSecondary">
                          What impact did this activity have on your knowledge, skill, judgment, and
                          attitude and how will this potentially affect your current or future
                          practice? Will you do anything differently as a result of your
                          participation in this activity?
                        </Typography>
                        <EditBox
                          value={row.paragraph}
                          handleChange={this.handleChange}
                          handleParagraphChange={this.handleParagraphChange}
                          handleParagraphSave={this.handleParagraphSave}
                          activity_number={row.activity_number}
                        />

                        <Typography variant="h6" color="secondary" gutterBottom>
                          Certificate
                        </Typography>
                        {row.file_uploaded ? (
                          <div className={classes.uploadedContainer}>
                            <Typography color="textSecondary">{row.original_filename}</Typography>
                            <div className={classes.uploadedButtons}>
                              <Button
                                color="primary"
                                variant="contained"
                                className={classes.downloadButton}
                                onClick={() => this.handleDownload(row.certificate_id)}
                                startIcon={<GetAppIcon />}
                              >
                                {this.props.gettingUrl ? <CircularProgress /> : 'Download File'}
                              </Button>
                              <Button
                                color="secondary"
                                variant="contained"
                                className={classes.downloadButton}
                                onClick={(e) => this.handleDelete(e, row.certificate_id)}
                                startIcon={<DeleteIcon />}
                              >
                                Delete File
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <Dropzone completed_id={row.completed_id} />
                        )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  fileUrl: state.fileReducer.fileUrl,
  gettingUrl: state.fileReducer.gettingUrl,
  getUrlSuccess: state.fileReducer.getUrlSuccess,

  learning_plan_type: state.userReducer.user.learning_plan_type,
});

const mapActionsToProps = {
  // addCompleted,
  // deleteCompleted,
  downloadFile,
  deleteFile,
  updateCompleted,
};

const styledComponent = withStyles(styles)(CpeCompletedTable);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
