/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import auth0Client from '../auth/Auth';

function LoginView(props) {
  const login = () => {
    auth0Client.signIn();
    props.history.replace('/');
  };

  return <>{!auth0Client.isAuthenticated() ? login() : props.history.push('/')}</>;
}

export default LoginView;
