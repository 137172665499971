import auth0Client from '../auth/Auth';

import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  FIRST_LOGIN,
  // AUTH0
  AUTH0_SET_SESSION,
  AUTH0_SILENT_AUTH,
  AUTH0_SILENT_AUTH_SUCCESS,
  AUTH0_SILENT_AUTH_FAILURE,
  AUTH0_SIGN_IN,
  AUTH0_HANDLE_AUTHENTICATION,
  AUTH0_HANDLE_AUTHENTICATION_SUCCESS,
  AUTH0_HANDLE_AUTHENTICATION_FAILURE,
  // STRIPE
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
} from '../actions/index';

const initialState = {
  user: [],

  gettingUser: false,
  getUserSuccess: false,

  updatingUser: false,
  updateUserSuccess: false,

  // Entire login journey: Auth0 -> DB
  loggingIn: false,
  loggingInSuccess: false,
  loggingInFailure: false,

  firstLogin: true,

  // Auth0
  auth0idToken: null,
  auth0profile: null,

  auth0Tried: false,

  auth0signedInSuccess: false,
  auth0signedInFailure: false,

  auth0SigningIn: false,
  auth0auth0gettingProfile: false,

  // Stripe
  creatingCustomer: false,
  createCustomerSuccess: false,

  updatingCustomer: false,
  updateCustomerSuccess: false,

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        gettingUser: true,
        getUserSuccess: false,
        error: null,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        gettingUser: false,
        getUserSuccess: true,
        user: action.payload,
      };

    case GET_USER_FAILURE:
      return {
        ...state,
        gettingUser: false,
        getUserSuccess: false,
        error: action.payload,
      };

    case UPDATE_USER:
      return {
        ...state,
        updatingUser: true,
        updateUserSuccess: false,
        error: null,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatingUser: false,
        updateUserSuccess: true,
        user: action.payload[0],
      };

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updatingUser: false,
        updateUserSuccess: false,
        error: action.payload,
      };

    case USER_LOGIN:
      return {
        ...state,
        loggingIn: true,
        loggingInSuccess: false,
        loggingInFailure: false,
        error: null,
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggingInSuccess: true,
        loggingInFailure: false,
        user: action.payload,
      };

    case USER_LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggingInSuccess: false,
        loggingInFailure: true,
        error: action.payload,
      };

    case FIRST_LOGIN:
      return {
        ...state,
        firstLogin: false,
      };

    // Auth0
    case AUTH0_SILENT_AUTH:
      return {
        ...state,
        loggingIn: true,
        loggingInSuccess: false,
        loggingInFailure: false,

        auth0signedInSuccess: false,
        auth0signedInFailure: false,
        auth0SigningIn: true,
      };

    case AUTH0_SILENT_AUTH_SUCCESS:
      return {
        ...state,
        auth0Tried: true,
        auth0idToken: auth0Client.getIdToken(),

        auth0signedInSuccess: true,
        auth0signedInFailure: false,

        auth0SigningIn: false,
        auth0gettingProfile: true,

        error: null,
      };

    case AUTH0_SILENT_AUTH_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggingInSuccess: false,
        loggingInFailure: true,

        auth0Tried: true,

        auth0signedInSuccess: false,
        auth0signedInFailure: true,

        auth0SigningIn: false,
        auth0gettingProfile: false,

        error: action.payload,
      };

    case AUTH0_SIGN_IN:
      return {
        ...state,
        auth0Tried: true,

        auth0signedInSuccess: true,
        auth0signedInFailure: false,
        auth0SigningIn: false,
      };

    case AUTH0_SET_SESSION:
      return {
        ...state,
        auth0idToken: auth0Client.getIdToken(),
        auth0profile: auth0Client.getProfile(),
        auth0gettingProfile: false,
      };

    case AUTH0_HANDLE_AUTHENTICATION:
      return {
        ...state,
        loggingIn: true,
        loggingInSuccess: false,
        loggingInFailure: false,

        auth0SigningIn: true,

        auth0signedInSuccess: false,
        auth0signedInFailure: false,
        error: null,
      };

    case AUTH0_HANDLE_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        auth0Tried: true,
        auth0idToken: auth0Client.getIdToken(),
        auth0gettingProfile: true,
        auth0SigningIn: false,

        auth0signedInSuccess: true,
        auth0signedInFailure: false,
        error: null,
      };

    case AUTH0_HANDLE_AUTHENTICATION_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggingInSuccess: false,
        loggingInFailure: true,

        auth0Tried: true,
        auth0SigningIn: false,

        auth0signedInSuccess: false,
        auth0signedInFailure: true,
        auth0gettingProfile: false,

        error: action.payload,
      };

    case CREATE_CUSTOMER:
      return {
        ...state,
        creatingCustomer: true,
        createCustomerSuccess: false,
        error: null,
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        creatingCustomer: false,
        createCustomerSuccess: true,
        user: action.payload,
      };
    case CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        creatingCustomer: false,
        createCustomerSuccess: false,
        error: action.payload,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        updatingCustomer: true,
        updateCustomerSuccess: false,
        error: null,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        updatingCustomer: false,
        updateCustomerSuccess: true,
      };
    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        updatingCustomer: false,
        updateCustomerSuccess: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
