import { GET_PROVIDERS, GET_PROVIDERS_SUCCESS, GET_PROVIDERS_FAILURE } from '../actions/index';

const initialState = {
  providers: [],
  gettingProviders: false,
  getProvidersSuccess: false,
  error: null,
  next: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVIDERS:
      return {
        ...state,
        gettingProviders: true,
        getProvidersSuccess: false,
        error: null,
      };
    case GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        gettingProviders: false,
        getProvidersSuccess: true,
        // providers: [...state.providers, action.payload],
        providers: action.payload.results,
        next: action.payload.next,
      };
    case GET_PROVIDERS_FAILURE:
      return {
        ...state,
        gettingProviders: false,
        getProvidersSuccess: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
