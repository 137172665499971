/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import { connect } from 'react-redux';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import IconButton from '@material-ui/core/IconButton';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing(9),
    // padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      flexDirection: 'column',
      borderRadius: '0px',
    },
  },
  content: {
    maxWidth: '1000px',
    margin: 'auto',
    display: 'flex',
  },
  heading: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
  },
  done: {
    color: theme.palette.action.selected,
    '&:hover': {
      color: theme.palette.completed.hover,
    },
  },
  doneSelected: {
    color: theme.palette.completed.main,
    '&:hover': {
      color: theme.palette.completed.hover,
    },
  },
});

function EmptyCompleted(props) {
  const { classes } = props;

  return (
    <div className={classes.content}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.heading}>
            <Typography variant="h6" color="secondary">
              This is where your completed list will be.
            </Typography>
          </div>
          <Typography>
            <strong>What is the completed list?</strong>
          </Typography>
          <Typography>
            Great question! We want to make it as easy as possible to record your CPEUs with CDR.
            Whenever you complete a CPEU it will end up here. From here you can just copy and paste
            it into your PDP log.
          </Typography>
          <br />
          <Typography>
            <strong>How do I add a CPEU to the completed list?</strong>
          </Typography>
          <Typography>
            All you have to do is click on the trophy{' '}
            <IconButton color="default" size="small">
              <EmojiEventsOutlinedIcon className={classes.done} />
            </IconButton>
            icon on a CPEU and it will be automatically added here. When you click the trophy, it
            will turn solid gold{' '}
            <IconButton color="primary" size="small">
              <EmojiEventsIcon className={classes.doneSelected} />
            </IconButton>
            to let you know it is on the completed list.
          </Typography>
          <br />
          <Typography>
            <strong>Any other questions?</strong>
          </Typography>
          <Typography>
            Email us at <a href="mailto:hello@cpeu.org">hello@cpeu.org</a> and we will be happy to
            help!
          </Typography>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(EmptyCompleted);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
