/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import axiosWithAuth from '../utils/axiosWithAuth';

import { showSnackbar } from './uiActions';
import { errorWebhook } from './slackActions';

import {
  filterReset,
  getFilteredCpes,
  setFilteredLpSwitchOn,
  setFilteredLpSwitchOff,
  resetFilteredLpSwitch,
  getFilteredLearningCodes,
  getFilteredPerformanceIndicators,
} from './filterActions';

import { getUser } from './userActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------LP------->

export const GET_LP = 'GET_LP';
export const GET_LP_SUCCESS = 'GET_LP_SUCCESS';
export const GET_LP_FAILURE = 'GET_LP_FAILURE';
export const ADD_LP = 'ADD_LP';
export const ADD_LP_SUCCESS = 'ADD_LP_SUCCESS';
export const ADD_LP_FAILURE = 'ADD_LP_FAILURE';
// export const UPDATE_LP = 'UPDATE_LP';
// export const UPDATE_LP_SUCCESS = 'UPDATE_LP_SUCCESS';
// export const UPDATE_LP_FAILURE = 'UPDATE_LP_FAILURE';
export const DELETE_LP = 'DELETE_LP';
export const DELETE_LP_SUCCESS = 'DELETE_LP_SUCCESS';
export const DELETE_LP_FAILURE = 'DELETE_LP_FAILURE';
export const UPDATE_ONBOARDING_LP = 'UPDATE_ONBOARDING_LP';
export const ON_LOGIN_SETUP_LP = 'ON_LOGIN_SETUP_LP';

export const getLP = () => (dispatch) => {
  dispatch({ type: GET_LP });
  axiosWithAuth()
    .get(`${url}/api/lp/`)
    .then((res) => {
      // console.log("GET_LP_SUCCESS response", res);
      dispatch({ type: GET_LP_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log("GET_LP_FAILURE err", err);
      dispatch(errorWebhook(GET_LP_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_LP_FAILURE, payload: err.message });
    });
};

// export const updateLP = (lp) => (dispatch) => {
//   dispatch({ type: UPDATE_LP });
//   axiosWithAuth()
//     .put(`${url}/api/lp/`, lp)
//     .then((res) => {
//       // console.log(res);
//       dispatch({ type: UPDATE_LP_SUCCESS, payload: res.data });
//     })
//     .catch((err) => {
//       // console.log(err);
//       dispatch({ type: UPDATE_LP_FAILURE, payload: err.message });
//     });
// };

export const deleteLP = () => (dispatch) => {
  dispatch({ type: DELETE_LP });
  axiosWithAuth()
    .delete(`${url}/api/lp/`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: DELETE_LP_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(DELETE_LP_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: DELETE_LP_FAILURE, payload: err.message });
    });
};

export const updateOnboardingLP = (learningPlanArray, learningPlanType) => (dispatch) => {
  // console.log('******updateOnboardingLP******');
  const data = {
    learningPlanArray,
    learningPlanType,
  };
  dispatch({ type: UPDATE_ONBOARDING_LP, payload: data });
};

export const onLoginSetupLP = (user) => (dispatch, getState) => {
  // console.log('******onLoginSetupLP******');
  dispatch({ type: ON_LOGIN_SETUP_LP, payload: user });

  // After the user logs in, if they have completed the LP, turn the filter on
  // If the switch has already been set (lpFilterSet === true), then skip
  if (!getState().filterReducer.lpFilterSet) {
    if (user.learning_plan_completed) {
      dispatch(setFilteredLpSwitchOn());
      if (user.learning_plan_type === 'pi') {
        dispatch(getFilteredPerformanceIndicators(user.learning_plan_pi));
        dispatch(getFilteredCpes());
      } else {
        dispatch(getFilteredLearningCodes(user.learning_plan_lc));
        dispatch(getFilteredCpes());
      }
    } else {
      // User does not have a LP
      dispatch(setFilteredLpSwitchOff());
      dispatch(filterReset());
      dispatch(getFilteredCpes());
    }
  }
};

export const updateLP = (lp) => (dispatch) => {
  dispatch({ type: ADD_LP });
  axiosWithAuth()
    .put(`${url}/api/lp/`, lp)
    .then((res) => {
      dispatch({ type: ADD_LP_SUCCESS, payload: res.data });
      // Allow filtering by LP and update the user with the LP
    })
    .then(() => {
      dispatch(showSnackbar('Learning Plan updated!', 'success'));
      dispatch(getUser());
    })
    .then(() => {
      dispatch(resetFilteredLpSwitch());
      dispatch(filterReset());
      dispatch(onLoginSetupLP(lp));
    })
    .catch((err) => {
      // console.log(err);
      dispatch(
        showSnackbar(
          'Learning Plan update failed. Please try again or contact us if this keeps happening. (hello@cpeu.org)',
          'error'
        )
      );
      dispatch(errorWebhook(ADD_LP_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: ADD_LP_FAILURE, payload: err.message });
    });
};
