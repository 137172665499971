import {
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
} from '../actions/index';

const initialState = {
  fileUrl: '',
  gettingUrl: false,
  getUrlSuccess: false,

  uploadingFile: false,
  uploadFileSuccess: false,

  deletingFile: false,
  deleteFileSuccess: false,

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_FILE:
      return {
        ...state,
        gettingUrl: true,
        getUrlSuccess: false,
        error: null,
      };
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        gettingUrl: false,
        getUrlSuccess: true,
        fileUrl: action.payload,
      };
    case DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        gettingUrl: false,
        getUrlSuccess: false,
        error: action.payload,
      };

    case UPLOAD_FILE:
      return {
        ...state,
        uploadingFile: true,
        uploadFileSuccess: false,
        error: null,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadingFile: false,
        uploadFileSuccess: true,
        // ???: action.payload.results,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadingFile: false,
        uploadFileSuccess: false,
        error: action.payload,
      };

    case DELETE_FILE:
      return {
        ...state,
        deletingFile: true,
        deleteFileSuccess: false,
        error: null,
      };
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        deletingFile: false,
        deleteFileSuccess: true,
        fileUrl: '',
        getUrlSuccess: false,
        // ???: action.payload.results,
      };
    case DELETE_FILE_FAILURE:
      return {
        ...state,
        deletingFile: false,
        deleteFileSuccess: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
