/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/quotes */
import { getCpes } from './cpeActions';

export const FILTER_BY_ACTIVITY_TYPE = 'FILTER_BY_ACTIVITY_TYPE';
export const FILTER_BY_EXPIRED = 'FILTER_BY_EXPIRED';
export const FILTER_BY_HOURS = 'FILTER_BY_HOURS';
export const FILTER_BY_LEARNING_CODES = 'FILTER_BY_LEARNING_CODES';
export const FILTER_BY_LEVEL = 'FILTER_BY_LEVEL';
export const FILTER_BY_LP = 'FILTER_BY_LP';
export const FILTER_BY_LP_ON = 'FILTER_BY_LP_ON';
export const FILTER_BY_LP_OFF = 'FILTER_BY_LP_OFF';
export const FILTER_BY_LP_RESET = 'FILTER_BY_LP_RESET';
export const FILTER_BY_PERFORMANCE_INDICATORS = 'FILTER_BY_PERFORMANCE_INDICATORS';
export const FILTER_BY_PRICE = 'FILTER_BY_PRICE';
export const FILTER_BY_PROVIDERS = 'FILTER_BY_PROVIDERS';
export const FILTER_BY_TEXT = 'FILTER_BY_TEXT';
export const FILTER_RESET = 'FILTER_RESET';
export const GET_FILTERED_CPES = 'GET_FILTERED_CPES';

export const filterReset = () => (dispatch) => {
  // console.log('******filterReset******');
  dispatch({ type: FILTER_RESET });
};
export const getFilteredActivityType = (checked) => (dispatch) => {
  // console.log('******getFilteredActivityType******');
  dispatch({ type: FILTER_BY_ACTIVITY_TYPE, payload: checked });
};

export const getFilteredExpired = (expired) => (dispatch) => {
  // console.log('******getFilteredExpired******');
  dispatch({ type: FILTER_BY_EXPIRED, payload: expired });
};

export const getFilteredHours = (minHours, maxHours) => (dispatch) => {
  // console.log('******getFilteredHours******');
  dispatch({ type: FILTER_BY_HOURS, payload: [minHours, maxHours] });
};

export const getFilteredLp = () => (dispatch) => {
  // console.log('******getFilteredLp******');
  dispatch({ type: FILTER_BY_LP });
};
export const setFilteredLpSwitchOn = () => (dispatch) => {
  // console.log('******setFilteredLpSwitchOn******');
  dispatch({ type: FILTER_BY_LP_ON });
};
export const setFilteredLpSwitchOff = () => (dispatch) => {
  // console.log('******setFilteredLpSwitchOff******');
  dispatch({ type: FILTER_BY_LP_OFF });
};
export const resetFilteredLpSwitch = () => (dispatch) => {
  // console.log('******setFilteredLpSwitchOff******');
  dispatch({ type: FILTER_BY_LP_RESET });
};

export const getFilteredLearningCodes = (checked) => (dispatch) => {
  // console.log('******getFilteredLearningCodes******');
  dispatch({ type: FILTER_BY_LEARNING_CODES, payload: checked });
};

export const getFilteredLevel = (checked) => (dispatch) => {
  // console.log('******getFilteredHours******');
  dispatch({ type: FILTER_BY_LEVEL, payload: checked });
};

export const getFilteredPerformanceIndicators = (checked) => (dispatch) => {
  // console.log('******getFilteredPerformanceIndicators******');
  dispatch({ type: FILTER_BY_PERFORMANCE_INDICATORS, payload: checked });
};

export const getFilteredPrice = (minPrice, maxPrice) => (dispatch) => {
  // console.log('******getFilteredPrice******');
  dispatch({ type: FILTER_BY_PRICE, payload: [minPrice, maxPrice] });
};

export const getFilteredProviders = (checked) => (dispatch) => {
  // console.log('******getFilteredProviders******');
  dispatch({ type: FILTER_BY_PROVIDERS, payload: checked });
};

export const getFilteredText = (text) => (dispatch) => {
  // console.log('******getFilteredText******');
  dispatch({ type: FILTER_BY_TEXT, payload: text });
};

export const getFilteredCpes = (page, limit) => (dispatch, getState) => {
  // console.log('******getFilteredCpes******');
  dispatch({ type: GET_FILTERED_CPES });

  // if page or limit is 'undefined' or 'null' (intentionally using '==' here)
  if (page == null) {
    // eslint-disable-next-line no-param-reassign
    page = 1;
  }
  if (limit == null) {
    // eslint-disable-next-line no-param-reassign
    limit = 25;
  }

  const { activityTypeFilterOn } = getState().filterReducer;
  const activityTypeSelected = getState().filterReducer.activityTypeSelected.toString();
  const activityType = activityTypeFilterOn ? `&activity_type=${activityTypeSelected}` : '';
  // console.log('activityType filter:', activityTypeFilterOn, activityTypeSelected, activityType);

  const { includeExpiredFilter } = getState().filterReducer;
  const expired = includeExpiredFilter ? `&expired=true` : '&expired=false';
  // console.log('expired filter:', includeExpiredFilter, expired);

  const { hoursFilterOn } = getState().filterReducer;
  const { hoursSelected } = getState().filterReducer;
  const hours = hoursFilterOn ? `&hours=${hoursSelected[0]},${hoursSelected[1]}` : '';
  // console.log('hours filter:', hoursFilterOn, hoursSelected, hours);

  const { levelFilterOn } = getState().filterReducer;
  const levelSelected = getState().filterReducer.levelSelected.join();
  let levels = levelFilterOn ? `&cpe_level=${levelSelected}` : '';
  // If they are searching all three levels, then remove the filter so it includes NULL values
  if (levels === `&cpe_level=1,2,3`) levels = '';

  const { learningCodesFilterOn } = getState().filterReducer;
  const learningCodesSelected = getState().filterReducer.learningCodesSelected.toString();
  const learningCodes = learningCodesFilterOn ? `&learning_codes=${learningCodesSelected}` : '';
  // console.log('learning codes filter:', learningCodesFilterOn, learningCodesSelected);

  const { performanceIndicatorsFilterOn } = getState().filterReducer;
  const performanceIndicatorsSelected = getState().filterReducer.performanceIndicatorsSelected.toString();
  const performanceIndicators = performanceIndicatorsFilterOn
    ? `&performance_indicators=${performanceIndicatorsSelected}`
    : '';
  // console.log(
  //   'performance indicator filter:',
  //   performanceIndicatorsFilterOn,
  //   performanceIndicatorsSelected
  // );

  const { priceFilterOn } = getState().filterReducer;
  const { priceSelected } = getState().filterReducer;
  const price = priceFilterOn ? `&price=${priceSelected[0]},${priceSelected[1]}` : '';
  // console.log('price filter:', priceFilterOn, priceSelected, price);

  const { providersFilterOn } = getState().filterReducer;
  const providersSelected = getState().filterReducer.providersSelected.toString();
  const providers = providersFilterOn ? `&providers=${providersSelected}` : '';
  // console.log('providers filter:', providersFilterOn, providersSelected);

  const searchText = getState()
    .filterReducer.searchText.toString()
    .replace(/[^a-zA-Z0-9 ]/g, ' ');
  const text = searchText.length > 0 ? `&search=${searchText}` : '';
  // console.log('text filter:', text, searchText);

  const paginate = `page=${page}&limit=${limit}`;
  dispatch(
    getCpes(
      paginate +
        activityType +
        expired +
        hours +
        levels +
        learningCodes +
        performanceIndicators +
        price +
        providers +
        text
      // lp
    )
  );
};
