import React from 'react';
import { connect } from 'react-redux';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { DropzoneDialogBase } from 'material-ui-dropzone';

import { uploadFile, getCompleted, getCompletedList } from '../../actions';

const styles = () => ({});

function Dropzone(props) {
  const [open, setOpen] = React.useState(false);
  const [fileObject, setFileObject] = React.useState([]);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Add Certificate
      </Button>

      <DropzoneDialogBase
        // acceptedFiles={['image/*']}
        fileObjects={fileObject}
        filesLimit={1}
        cancelButtonText="cancel"
        submitButtonText="submit"
        maxFileSize={5000000}
        open={open}
        onAdd={(newFileObj) => {
          setFileObject(newFileObj);
        }}
        onDelete={() => {
          setFileObject([]);
        }}
        onClose={() => setOpen(false)}
        onSave={() => {
          setOpen(false);
          props.uploadFile(fileObject[0].file, props.completed_id);
          // props.getCompleted();
          // props.getCompletedList();
        }}
        fullWidth
        showPreviews
        showFileNamesInPreview
        useChipsForPreview
      />
    </div>
  );
}

const mapStateToProps = () => ({});

const mapActionsToProps = {
  uploadFile,
  getCompleted,
  getCompletedList,
};

const styledComponent = withStyles(styles)(Dropzone);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
