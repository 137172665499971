/* eslint-disable no-console */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import CycleCard from '../../components/profile/CycleCard';
import EditCard from '../../components/profile/EditCard';
import EditNameCard from '../../components/profile/EditNameCard';
import EditStateCard from '../../components/profile/EditStateCard';
import Feedback from '../../components/feedback/Feedback';
import { getUser, updateUser, showSnackbar } from '../../actions';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      flexDirection: 'column',
      borderRadius: '0px',
    },
  },
  content: {
    maxWidth: '1000px',
    margin: 'auto',
    display: 'flex',
  },
  heading: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
  },
});

class ProfilePersonalInfoView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gotUser: false,

      first_name: '',
      last_name: '',
      email: '',
      cycle: 0,
      cycle_begin_date: '',
      cycle_end_date: '',
      registration_number: '',
      state: '',
    };
  }

  componentDidMount() {
    if (this.props.auth0idToken && !this.props.getUserSuccess && !this.state.gotUser) {
      this.props.getUser();
    }
    if (this.props.getUserSuccess && !this.state.gotUser) {
      this.setState({
        gotUser: true,

        first_name: this.props.user.first_name === null ? '' : this.props.user.first_name,
        last_name: this.props.user.last_name === null ? '' : this.props.user.last_name,
        email: this.props.user.email === null ? '' : this.props.user.email,
        cycle: this.props.user.cycle === null ? '0' : this.props.user.cycle,
        cycle_begin_date:
          this.props.user.cycle_begin_date === null ? '' : this.props.user.cycle_begin_date,
        cycle_end_date:
          this.props.user.cycle_end_date === null ? '' : this.props.user.cycle_end_date,
        registration_number:
          this.props.user.registration_number === null ? '' : this.props.user.registration_number,
        state: this.props.user.state === null ? '' : this.props.user.state,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.auth0idToken && !this.props.getUserSuccess && !this.state.gotUser) {
      this.props.getUser();
    }
    if (this.props.getUserSuccess && !this.state.gotUser) {
      this.setState({
        gotUser: true,

        first_name: this.props.user.first_name === null ? '' : this.props.user.first_name,
        last_name: this.props.user.last_name === null ? '' : this.props.user.last_name,
        email: this.props.user.email === null ? '' : this.props.user.email,
        cycle: this.props.user.cycle === null ? '0' : this.props.user.cycle,
        cycle_begin_date:
          this.props.user.cycle_begin_date === null ? '' : this.props.user.cycle_begin_date,
        cycle_end_date:
          this.props.user.cycle_end_date === null ? '' : this.props.user.cycle_end_date,
        registration_number:
          this.props.user.registration_number === null ? '' : this.props.user.registration_number,
        state: this.props.user.state === null ? '' : this.props.user.state,
      });
    }
  }

  handleChange = (e) => {
    const change = {};
    change[e.target.name] = e.target.value;
    // console.log('change', change);

    this.setState(change);
  };

  handleSubmit = (name, value) => {
    const user = {};
    user[name] = value;
    this.props.updateUser(user).then((res) => {
      if (res.type === 'UPDATE_USER_SUCCESS') {
        this.props.showSnackbar('Updated!', 'success');
      } else {
        this.props.showSnackbar(
          'There was an error, please try again. If this keeps happening contact us at hello@cpeu.org',
          'error'
        );
      }
    });
  };

  handleNameSubmit = (first_name, last_name) => {
    const user = {};
    user.first_name = first_name;
    user.last_name = last_name;

    this.props.updateUser(user).then((res) => {
      this.setState({ first_name, last_name });
      if (res.type === 'UPDATE_USER_SUCCESS') {
        this.props.showSnackbar('Name updated!', 'success');
      } else {
        this.props.showSnackbar(
          'There was an error, please try again. If this keeps happening contact us at hello@cpeu.org',
          'error'
        );
      }
    });
  };

  handleCycleChange = (cycle) => {
    if (cycle === '0') {
      this.setState({
        cycle: 0,
        // cycle_begin_date: '2015-06-01',
        // cycle_end_date: '2020-05-31',
      });
    } else if (cycle === '2') {
      this.setState({
        cycle: 2,
        cycle_begin_date: '2015-06-01',
        cycle_end_date: '2020-05-31',
      });
    } else if (cycle === '3') {
      this.setState({
        cycle: 3,
        cycle_begin_date: '2016-06-01',
        cycle_end_date: '2021-05-31',
      });
    } else if (cycle === '4') {
      this.setState({
        cycle: 4,
        cycle_begin_date: '2017-06-01',
        cycle_end_date: '2022-05-31',
      });
    } else if (cycle === '5') {
      this.setState({
        cycle: 5,
        cycle_begin_date: '2018-06-01',
        cycle_end_date: '2023-05-31',
      });
    } else if (cycle === '6') {
      this.setState({
        cycle: 6,
        cycle_begin_date: '2019-06-01',
        cycle_end_date: '2024-05-31',
      });
    } else if (cycle === '7') {
      this.setState({
        cycle: 7,
        cycle_begin_date: '2020-06-01',
        cycle_end_date: '2025-05-31',
      });
    } else if (cycle === '8') {
      this.setState({
        cycle: 8,
        cycle_begin_date: '2021-06-01',
        cycle_end_date: '2026-05-31',
      });
    } else if (cycle === '9') {
      this.setState({
        cycle: 9,
        cycle_begin_date: '2022-06-01',
        cycle_end_date: '2027-05-31',
      });
    } else if (cycle === '10') {
      this.setState({
        cycle: 10,
        cycle_begin_date: '2023-06-01',
        cycle_end_date: '2028-05-31',
      });
    } else if (cycle === '11') {
      this.setState({
        cycle: 11,
        cycle_begin_date: '2024-06-01',
        cycle_end_date: '2029-05-31',
      });
    } else if (cycle === '12') {
      this.setState({
        cycle: 12,
        cycle_begin_date: '2025-06-01',
        cycle_end_date: '2030-05-31',
      });
    }
  };

  handleBeginDateChange = (cycle_begin_date) => {
    const date = cycle_begin_date ? moment(cycle_begin_date).format('YYYY-MM-DD') : null;
    this.setState({ cycle_begin_date: date });
  };

  handleEndDateChange = (cycle_end_date) => {
    const date = cycle_end_date ? moment(cycle_end_date).format('YYYY-MM-DD') : null;
    this.setState({ cycle_end_date: date });
  };

  handleCycleSubmit = () => {
    const user = {};
    user.cycle = this.state.cycle === 0 ? null : this.state.cycle;
    user.cycle_begin_date = this.state.cycle_begin_date;
    user.cycle_end_date = this.state.cycle_end_date;
    this.props.updateUser(user);
  };

  handleSelectState = (event) => {
    this.setState({ state: event.target.value });
  };

  render() {
    const { classes } = this.props;

    const content =
      this.state.gotUser === true ? (
        <>
          <EditNameCard
            required
            title="first_name"
            description="This is your full name."
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            handleNameSubmit={this.handleNameSubmit}
          />
          <EditCard
            required
            title="email"
            description="Use an address you’ll always have access to."
            value={this.state.email}
            valueSaved={this.props.user.email}
            label="Email address"
            placeholder="hello@cpeu.org"
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
          <CycleCard
            title="cycle"
            description="What are the dates of your cycle?"
            cycle={this.state.cycle.toString()}
            cycle_begin_date={this.state.cycle_begin_date}
            cycle_end_date={this.state.cycle_end_date}
            cycle_begin_dateSaved={this.props.user.cycle_begin_date}
            cycle_end_dateSaved={this.props.user.cycle_end_date}
            label="Registration Cycle"
            handleCycleChange={this.handleCycleChange}
            handleCycleSubmit={this.handleCycleSubmit}
            handleBeginDateChange={this.handleBeginDateChange}
            handleEndDateChange={this.handleEndDateChange}
          />
          <EditCard
            title="registration_number"
            description="Your CDR registration number."
            value={this.state.registration_number}
            valueSaved={this.props.user.registration_number}
            label="Registration Number"
            number
            placeholder="123456"
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
          <EditStateCard
            title="state"
            description="What state do you live in?"
            value={this.state.state}
            valueSaved={this.props.user.state}
            label="Registration State"
            placeholder="CA"
            handleSelectState={this.handleSelectState}
            handleSubmit={this.handleSubmit}
          />
        </>
      ) : (
        <CircularProgress />
      );

    return (
      <div className={classes.content}>
        <Helmet>
          <title>Profile - Personal Info | CPEU.org</title>
        </Helmet>
        <div className={classes.root}>
          <div className={classes.heading}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="textSecondary" to="/profile">
                Profile
              </Link>
              <Typography color="textPrimary">Personal Info</Typography>
            </Breadcrumbs>
            <Typography variant="h3" color="secondary">
              Personal Info
            </Typography>
          </div>
          <Paper className={classes.paper}>{content}</Paper>
          <Feedback />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth0idToken: state.userReducer.auth0idToken,
  getUserSuccess: state.userReducer.getUserSuccess,
  user: state.userReducer.user,
});

const mapActionsToProps = {
  getUser,
  updateUser,
  showSnackbar,
};

const styledComponent = withStyles(styles)(ProfilePersonalInfoView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
