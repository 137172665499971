/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// Icons
import AccountCircle from '@material-ui/icons/AccountCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import Feedback from '../components/feedback/Feedback';
import ProfileCard from '../components/profile/ProfileCard';
import { getUser, updateUser } from '../actions';

import auth0Client from '../auth/Auth';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      flexDirection: 'column',
      borderRadius: '0px',
    },
  },
  content: {
    maxWidth: '1000px',
    margin: 'auto',
    display: 'flex',
  },
  heading: {
    padding: theme.spacing(1),
  },
  profileCards: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '@media (max-width: 576px)': {
      justifyContent: 'center',
    },
  },
  submitButton: {
    maxWidth: 250,
    alignSelf: 'center',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  logOut: {
    margin: theme.spacing(3, 0, 0, 1),
  },
});

class ProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gotUser: false,

      // first_name: '',
      // last_name: '',
      // email: '',
      // registration_number: '',
      // credentials: '',
      // state: '',
      // registration_date: '',
      // cycle_begin_date: '',
      // cycle_end_date: '',
    };
  }

  componentDidMount() {
    if (!this.props.getUserSuccess && !this.state.gotUser) {
      this.props.getUser();
    }
    if (this.props.getUserSuccess && !this.state.gotUser) {
      this.setState({
        gotUser: true,

        // first_name: this.props.user.first_name === null ? '' : this.props.user.first_name,
        // last_name: this.props.user.last_name === null ? '' : this.props.user.last_name,
        // email: this.props.user.email === null ? '' : this.props.user.email,
        // registration_number:
        //   this.props.user.registration_number === null ? '' : this.props.user.registration_number,
        // credentials: this.props.user.credentials,
        // state: this.props.user.state === null ? '' : this.props.user.state,
        // registration_date: this.props.user.registration_date,
        // cycle_begin_date: this.props.user.cycle_begin_date,
        // cycle_end_date: this.props.user.cycle_end_date,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.getUserSuccess && !this.state.gotUser) {
      this.setState({
        gotUser: true,

        // first_name: this.props.user.first_name === null ? '' : this.props.user.first_name,
        // last_name: this.props.user.last_name === null ? '' : this.props.user.last_name,
        // email: this.props.user.email === null ? '' : this.props.user.email,
        // registration_number:
        //   this.props.user.registration_number === null ? '' : this.props.user.registration_number,
        // credentials: this.props.user.credentials,
        // state: this.props.user.state === null ? '' : this.props.user.state,
        // registration_date: this.props.user.registration_date,
        // cycle_begin_date: this.props.user.cycle_begin_date,
        // cycle_end_date: this.props.user.cycle_end_date,
      });
    }
  }

  signOut = () => {
    auth0Client.signOut();
    localStorage.clear();
  };

  render() {
    const { classes } = this.props;

    const content =
      this.state.gotUser === true ? (
        <div className={classes.profileCards}>
          <ProfileCard
            link="/profile/learningplan"
            icon={<FormatListBulletedIcon />}
            title="Learning Plan"
            subtitle="Update your learning plan."
          />
          <ProfileCard
            link="/profile/personal-info"
            icon={<AccountCircle />}
            title="Personal Info"
            subtitle="Provide personal details and how we can reach you."
          />
          <ProfileCard
            link="/profile/billing"
            icon={<CreditCardIcon />}
            title="Billing Info"
            subtitle="Review plan and billing info."
          />
        </div>
      ) : (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      );

    return (
      <div className={classes.content}>
        <Helmet>
          <title>Profile | CPEU.org</title>
        </Helmet>
        <div className={classes.root}>
          <div className={classes.heading}>
            <Typography variant="h2" color="secondary">
              Profile
            </Typography>
          </div>
          {content}
          <Divider />
          <div className={classes.logOut}>
            <Button
              onClick={() => {
                this.signOut();
              }}
              size="medium"
              color="secondary"
              variant="outlined"
            >
              Log Out
            </Button>
          </div>
          <Feedback />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getUserSuccess: state.userReducer.getUserSuccess,
  user: state.userReducer.user,
});

const mapActionsToProps = {
  getUser,
  updateUser,
};

const styledComponent = withStyles(styles)(ProfileView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
