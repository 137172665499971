/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Prices from '../payment/Prices';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    boxShadow: theme.shadows[0],
    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
    },
  },
  heading: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
  },
});

function OnboardingPayment(props) {
  const { classes, subscription_status } = props;
  // console.log('OnboardingPayment subscription_status', subscription_status);

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h2" color="secondary">
          Payment Information
        </Typography>
      </div>
      <Paper className={classes.paper}>
        {subscription_status === 'active' ? (
          <>
            <Typography variant="h6" color="secondary">
              Thank you for subscribing!
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Please click NEXT to continue.
            </Typography>
          </>
        ) : (
          <Prices />
        )}
      </Paper>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(OnboardingPayment);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
