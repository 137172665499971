/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

// Icons
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
// import BookmarksIcon from '@material-ui/icons/Bookmarks';
// import School from '@material-ui/icons/School';

import logo from '../../img/logo.svg';
// import logoCircle from '../../img/logo-circle.svg';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    borderBottomRightRadius: '36px',
  },
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    height: '40px',
  },
}));

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function OnboardingHeader(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed">
          <Toolbar>
            <div className={classes.logoContainer}>
              <img src={logo} alt="CPEU.org" className={classes.logo} />
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
}
