// eslint-disable-next-line import/prefer-default-export
export const perIndObj = {
  // "1. Ethics Professionalism"
  // "1.1 Identifies with and adheres to the code of ethics for the profession."
  '1.1.1':
    'Accepts own responsibility and accountability for actions and decisions related to customers.',
  '1.1.2': 'Recognizes and manages conflicts of interest.',
  '1.1.3': 'Understands the impact of personal values and beliefs on practice.',
  '1.1.4': 'Practices with honesty, integrity, transparency and fairness.',
  '1.1.5': 'Recognizes and maintains appropriate relationships and boundaries.',
  '1.1.6': 'Recognizes and manages situations with ethical implications.',
  '1.1.7': 'Communicates professional title and credentials accurately.',
  '1.1.8': 'Adheres to the Standards of Professional Performance for RDNs.',

  // "1.2 Works within personal and professional limitations and abilities."
  '1.2.1': 'Identifies and takes the appropriate steps to maintain and enhance competence.',
  '1.2.2': 'Identifies the knowledge and skills required to provide service.',
  '1.2.3':
    'Refers customer to the appropriate professional and/or service provider when needs are beyond personal or professional scope of practice.',
  '1.2.4': 'Seeks timely and appropriate advice and guidance to provide optimal service.',
  '1.2.5': 'Identifies a need and seeks additional resources when needed.',
  '1.2.6':
    'Anticipates and manages the potential outcomes of own actions or the actions of others.',

  // "1.3 Applies customer-centered principles in practice."
  '1.3.1': 'Applies strategies that engage the customer in a collaborative approach.',
  '1.3.2': 'Recognizes the strengths and limitations of a customer.',
  '1.3.3': 'Builds rapport and trust within the relationship while respecting boundaries.',
  '1.3.4':
    'Communicates the risks, benefits, disadvantages, alternatives, and cost of the proposed treatment plan, contract, and statement of terms or scope of work and confirms customer understanding.',
  '1.3.5':
    'Identifies and respects economic and sociocultural factors when determining the goals and wants of the customer.',
  '1.3.6': 'Develops and implements culturally appropriate strategies when delivering service.',
  '1.3.7':
    'Recognizes the limits of own cultural knowledge, skill and abilities and consults with others when needed.',
  '1.3.8':
    'Initiates collective efforts with others to implement resources to support culturally diverse customers.',
  '1.3.9':
    'Recognizes and incorporates knowledge of cultural and/or religious foods, practices and preparation.',

  // "1.4 Adheres to confidentiality and privacy legislation, standards and policies."
  '1.4.1':
    'Maintains confidentiality and security in the sharing, transmission, storage and management of personal health information.',
  '1.4.2': 'Obtains consent for the collection, storage, use and disclosure of information.',
  '1.4.3':
    'Adheres to legislative requirements and facility/employer guidelines regarding protection of privacy and security of information.',
  '1.4.4':
    'Takes action to anticipate and minimize foreseeable risks to privacy and confidentiality.',
  '1.4.5':
    'Supports others in meeting their professional obligations to maintain confidentiality and privacy of personal information.',

  // "1.5 Adheres to and models professional obligations defined in legislation, standards and organization policies."
  '1.5.1':
    'Stays current of, complies with and models national, state and local legislation, policies and standards.',
  '1.5.2': 'Stays current of, complies with and models organization policies and standards.',
  '1.5.3': 'Reports unsafe, unethical or incompetent practice or behaviors.',
  '1.5.4': 'Models a professional image as defined within organization policy and code of ethics.',
  '1.5.5': 'Engages in national, state and district professional elections.',

  // "1.6 Responsibly applies the principles of financial stewardship and/or management."
  '1.6.1':
    'Considers financial responsibility to the organization and to the population served when making decisions or recommendations.',
  '1.6.2': 'Prioritizes goals in order to effectively manage time and workload.',
  '1.6.3': 'Ensures projects meet budgetary requirements.',
  '1.6.4':
    'Takes into consideration the economic status of the customer when making recommendations and ensuring optimal care.',
  '1.6.5':
    'Advocates for financial support for customers to sustain a nutrition and treatment plan.',

  // "2. Communications"
  // "2.1 Utilizes appropriate communication methods and skills to meet the needs of various audiences."
  '2.1.1': 'Assesses the communication needs of the individual, customer or population.',
  '2.1.2': 'Identifies barriers to effective communication.',
  '2.1.3': 'Tailors message to meet the needs of the target audience.',
  '2.1.4': 'Uses a variety of media to deliver information.',
  '2.1.5': 'Observes nonverbal cues and responds appropriately.',
  '2.1.6': 'Evaluates the effectiveness of the communication.',
  '2.1.7': 'Delivers information and opinions in a respectful and professional manner.',
  '2.1.8': 'Delivers accurate and credible messaging.',
  '2.1.9':
    'Ensures written communications are timely, legible, accurate and professional in nature.',
  '2.1.10':
    'Applies relevant legislation and organization policies when communicating using all forms of media.',

  // "2.2 Collaborates with others to achieve common goals and to optimize delivery of services."
  '2.2.1':
    'Identifies the need for and negotiates common ground with customers, interprofessional team members and other stakeholders.',
  '2.2.2':
    'Considers and respects the opinions, creativity, values, beliefs and perspectives of others.',
  '2.2.3': 'Identifies opportunities for shared benefit and/or vision.',
  '2.2.4': 'Collaborates with others when the required skill is beyond his/her competence.',
  '2.2.5': 'Demonstrates conflict resolution and mediation skills.',

  // "2.3 Employs strategies and facilitates team-building skills."
  '2.3.1': 'Applies the principles of collaboration and negotiation in teamwork.',
  '2.3.2':
    'Incorporates team members knowledge, expertise and personal skills into team processes.',
  '2.3.3':
    'Models behaviors that maximize group participation by consulting, listening and communicating clearly.',
  '2.3.4':
    'Promotes a friendly, cooperative environment that is conducive to employees sense of belonging.',
  '2.3.5':
    'Facilitates an understanding and appreciation of the differences among team members and how they each contribute to the team.',

  // "3. Leadership and Advocacy"
  // "3.1 Demonstrates and applies leadership skills."
  '3.1.1': 'Identifies strengths and opportunities for self-improvement and improvement in others.',
  '3.1.2': 'Fosters a culture in which diversity and cross-team collaboration are valued.',
  '3.1.3': 'Takes initiative to facilitate change.',
  '3.1.4': 'Communicates clear performance expectations.',
  '3.1.5': 'Seeks opportunities for, and actively engages in, mentoring and mentorship.',
  '3.1.6': 'Takes an active role in sharing information and knowledge.',
  '3.1.7':
    'Participates in the development of   the organizations strategic plan, mission and vision.',

  // "3.2 Advocates and challenges others to take action to advance the profession."
  '3.2.1':
    'Advocates for, and participates in, activities that support advancement of the profession.',
  '3.2.2':
    'Participates in professional and personal development activities for career growth and skill enhancement.',
  '3.2.3': 'Pursues and embraces opportunities to advance practice.',
  '3.2.4':
    'Encourages others to engage in personal and professional development activities for career growth and skill enhancement.',
  '3.2.5':
    'Educates the employer and the public on the role and the benefits of receiving care or services from an RDN and NDTR.',
  '3.2.6':
    'Communicates with policymakers to influence decisions that impact advancement of the profession.',
  '3.2.7': 'Contributes to the development and revision of government policies.',

  // "3.3 Advocates for the customer and facilitates acquisition of services and resources."
  '3.3.1': 'Educates the customer on the availability of nutrition services.',
  '3.3.2': 'Increases public awareness of the importance of nutrition and public welfare.',
  '3.3.3': 'Collaborates with customers and others to support access to services.',
  '3.3.4':
    'Engages in active discussions with others to establish a method to best meet and serve the needs of the customer and the population.',
  '3.3.5':
    'Advocates for public health and wellness within the context of the organization and community.',

  // "4. Critical Thinking and Decision Making"
  // "4.1 Demonstrates sound professional judgment and strategic thinking in practice."
  '4.1.1': 'Demonstrates effective problem solving and professional judgment to address a need.',
  '4.1.2': 'Interprets and integrates evidence-based research and literature in decision making.',
  '4.1.3': 'Aligns work with organization policies, strategic plan and mission statement.',
  '4.1.4': 'Demonstrates effective, appropriate and timely consultation with experts and others.',
  '4.1.5':
    'Recognizes situations where services provided to a customer should be adjusted, limited, modified or discontinued.',

  // "4.2 Reflects, integrates and evaluates using critical thinking when faced with problems, issues and challenges."
  '4.2.1': 'Identifies potential or real problems, issues or challenges.',
  '4.2.2': 'Reflects on own values, beliefs and biases.',
  '4.2.3': 'Demonstrates insight into personal expertise and limitations.',
  '4.2.4': 'Investigates alternative explanations for deficits and/or issues.',
  '4.2.5': 'Considers alternative methods and provides justification for selected option(s).',
  '4.2.6':
    'Analyzes and synthesizes information and identifies new information, patterns and findings.',
  '4.2.7':
    'Integrates relevant information with previous learning, experience, professional knowledge, and current practice models.',
  '4.2.8': 'Identifies and implements a plan to address the problem, issue or challenge.',
  '4.2.9': 'Evaluates the effectiveness of a plan, and identifies required future actions.',

  // "5. Informatics"
  // "5.1 Acquires knowledge of technology systems consistent with role and responsibilities."

  '5.1.1':
    'Demonstrates proficient use of technical operating systems and software to communicate and disseminate information; to collect, track and retrieve data; and to create documents, spreadsheets and presentations.',
  '5.1.2':
    'Applies understanding of informatics terminology and input and output devices (e.g. laptop, smartphone, flash drive).',
  '5.1.3':
    'Seeks guidance from technology advisors or experts when requirements are beyond competence.',
  '5.1.4': 'Demonstrates knowledge of system interfaces used in the delivery of services.',

  // "5.2 Utilizes technology according to organization needs and workplace policies and procedures."
  '5.2.1':
    'Integrates technology platforms with other internal and external services (e.g., risk management, disease management, data tracking and reporting).',
  '5.2.2':
    'Leads or participates on teams to design or develop criteria for the selection or implementation of software programs, applications or systems to advance work objectives.',
  '5.2.3': 'Advocates, implements and monitors security and data protection practices.',
  '5.2.4':
    'Determines the workflow and resource needs for clinical information system implementation, maintenance and upgrades.',
  '5.2.5':
    'Instructs or advises others on the use of clinical information systems, nutrition informatics tools and other technology topics.',
  '5.2.6':
    'Develops and implements policies and procedures consistent with privacy and confidentiality legislation and regulation.',
  '5.2.7':
    'Suggests, develops and/or implements innovative enhancements and new software platforms, applications and technologies to meet the needs of the target group and the environment.',

  // "5.3 Demonstrates ethical and professional behavior when using technology."
  '5.3.1':
    'Complies with legislative requirements and organization policies for maintaining and storing personal health information.',
  '5.3.2':
    'Maintains technological security and confidentiality in the electronic sharing, transmission, storage and management of information.',
  '5.3.3':
    'Monitors compliance with privacy and confidentiality legislation and organization policies related to informatics.',
  '5.3.4':
    'Takes appropriate action in response to unauthorized access, use and disclosure of information.',
  '5.3.5': 'Demonstrates professional behaviors and boundaries when using social media platforms.',

  // "5.4 Demonstrates the ability to store and retrieve data using the International Dietetics and Nutrition Terminology (IDNT) and other standardized languages."
  '5.4.1':
    'Complies with IDNT terminology when documenting customer information in electronic databases.',
  '5.4.2':
    'Uses standardized terminology to describe nutrition assessment data, nutrition diagnoses, nutrition interventions, and nutrition monitoring and evaluation.',
  '5.4.3':
    'Queries databases composed of standardized terms to retrieve customer information for practice and process improvement to monitor the effectiveness of interventions in individuals and populations.',

  // "6. Research, Evidence-Informed Practice and Quality Improvement"
  // "6.1 Leads, manages and/or participates in quality improvement and customer satisfaction activities to improve delivery of services."
  '6.1.1': 'Recognizes and identifies systems errors and risk reduction measures.',
  '6.1.2':
    'Formulates a clear understanding of the nature of problems or need for improvement to achieve desired outcome.',
  '6.1.3': 'Establishes goals for improving quality of services provided.',
  '6.1.4':
    'Collects qualitative and quantitative data using mixed methodologies and interprets information.',
  '6.1.5': 'Reviews reports to identify trends and improvements.',
  '6.1.6':
    'Develops and implements strategies, techniques and tools for process improvement in consultation with others.',
  '6.1.7': 'Evaluates, documents and communicates quality improvement outcomes.',
  '6.1.8':
    'Monitors quality of own work and engages in continuing education and professional development to enhance practice knowledge.',
  '6.1.9': 'Interprets data to formulate judgments, conclusions and reports.',

  // "6.2 Demonstrates skills in researching, independent critical examination and evaluating literature to influence practice."
  '6.2.1': 'Uses established benchmarking and best practices to inform practice.',
  '6.2.2':
    'Demonstrates knowledge of research instruments and tools (e.g., surveys, interview tools) to support research initiatives.',
  '6.2.3': 'Interprets data to make recommendations and to inform decisions.',
  '6.2.4':
    'Disseminates research or performance improvement outcomes to advance knowledge, change practice and enhance effectiveness of services.',
  '6.2.5':
    'Applies research/evidence-based findings to improve practice, service delivery, and health and nutrition of customers.',

  // "6.3 Participates in and/or leads research initiatives following ethical and professional research methodology."
  '6.3.1': 'Uses established benchmarking and best practices to inform practice.',
  '6.3.2': 'Conceptualizes and formulates research hypotheses.',
  '6.3.3':
    'Obtains approval for research initiatives and follows established research code of ethics and policies.',
  '6.3.4': 'Defines and establishes appropriate measurements and evaluations.',
  '6.3.5': 'Identifies and designs appropriate research instruments or tools.',
  '6.3.6':
    'Using the scientific process, systematically investigates and searches for information from a wide variety of sources.',
  '6.3.7': 'Interprets data to make recommendations and to form realistic and valid conclusions.',
  '6.3.8': 'Interprets, analyzes, synthesizes and critically appraises research findings.',
  '6.3.9':
    'Disseminates research or performance improvement outcomes to advance knowledge, change practice and enhance effectiveness of services.',
  '6.3.10':
    'Defines appropriate measurements and evaluations for establishing benchmarking and best practices.',
  '6.3.11':
    'Applies research/evidence-based findings to improve practice, service delivery and health and nutrition of customers.',

  // "7. Safety and Risk Management"
  // "7.1 Identifies, analyzes and manages risk, adverse events and safety to self, staff, customer and public."
  '7.1.1':
    'Advocates for, interprets and adheres to workplace safety legislation, regulations and organization policies.',
  '7.1.2': 'Advocates for and maintains awareness of safety policies and procedures.',
  '7.1.3':
    'Investigates and analyzes work environment to identify safety issues and risks to self, staff, customers, public and organization.',
  '7.1.4': 'Takes action on identified risk to self, staff, customer, public and organization.',
  '7.1.5': 'Measures, analyzes and reports data to monitor adverse events, errors and accidents.',
  '7.1.6': 'Coordinates the implementation of risk management strategies.',

  // "7.2 Applies principles, standards, regulations and organization policies to reduce the risk of foodborne and waterborne illness outbreaks."
  '7.2.1':
    'Applies knowledge of biological, physical or chemical properties that may cause food to be unsafe for human and animal consumption.',
  '7.2.2':
    'Develops and provides education and counseling on safe food-handling and sustainable practices to prevent and minimize contamination.',
  '7.2.3':
    'Communicates the role of sustainable food practices and food insecurities for populations.',
  '7.2.4': 'Identifies and analyzes insecurities in food and water system.',
  '7.2.5':
    'Develops, implements and adheres to policies and procedures to optimize food and water safety.',
  '7.2.6':
    'Identifies and promotes sustainable, resilient, healthy food to staff, customers and public.',
  '7.2.7':
    'Identifies and implements risk management and environmental safety principles to enhance public safety and reduce risk to self, staff, customers, public and organization.',
  '7.2.8':
    'Develops and implements food safety and sanitation programs in compliance with state and federal regulations.',
  '7.2.9': 'Develops and communicates awareness of disaster planning and food and water safety.',
  '7.2.10':
    'Collaborates with organizations and vendors to develop and implement safety specifications and procedures for the optimization of food and water safety and to provide adequate food storage and supply.',
  '7.2.11':
    'Applies knowledge of hygienic food preparation practices and causes of foodborne illness in food preparation.',

  // "7.3 Integrates policies and adheres to infection prevention and control measures."
  '7.3.2':
    'Develops, implements and maintains policies and procedures to address infection prevention and control.',
  '7.3.3':
    'Increases awareness of infection prevention and control for self, staff, customer, public and organization.',
  '7.3.1':
    'Recognizes the environmental implications of infectious diseases, compromised health conditions and outbreaks and identifies and implements required preventive action for public safety.',

  // "8. Food, Nutrition and Dietetics and Physical Activity"
  // "8.1 Interprets and applies current food and nutrition science and principles in dietetics practice."
  '8.1.1':
    'Interprets and applies evidence-based comparative standards for determining nutritional needs.',
  '8.1.2':
    'Applies knowledge of food and nutrition as well as the biological, physical and social sciences in practice.',
  '8.1.3':
    'Integrates knowledge of macronutrients and micronutrients for absorption, digestion and metabolism throughout the life span in practice.',
  '8.1.4':
    'Demonstrates knowledge of nutrient requirements throughout the life span and their role in health promotion and disease management.',
  '8.1.5': 'Applies medical nutrition therapy in disease prevention and management.',

  // "8.2 Recognizes and respects the physical, social, cultural, institutional and economic environments of the individual, group, community and population in practice."
  '8.2.1':
    'Assesses the physical, social and cultural needs of the individual, group, community or population.',
  '8.2.2':
    'Applies knowledge of health determinants when planning, developing and implementing services, programs, meal plans and menus.',
  '8.2.3':
    'Implements individualized services to reflect customer-centered approach as it pertains to the customers physical, social, cultural, institutional and economic environment.',
  '8.2.4':
    'Imparts knowledge of the importance of physical activity and applies behavior change principles to promote physical activity and decrease inactivity.',
  '8.2.5':
    'Keeps abreast of, advocates for and integrates knowledge of national and local funding models that impact the population and services provided.',

  // "8.3 Demonstrates a commitment to maintaining and enhancing knowledge."
  '8.3.1':
    'Maintains the knowledge and skill to manage a variety of disease states and clinical conditions.',
  '8.3.2': 'Implements a plan for continual professional improvement.',
  '8.3.3': 'Takes action to address deficiencies to enhance practice.',
  '8.3.4': 'Enhances knowledge to foster career advancement.',
  '8.3.5':
    'Keeps abreast of changes in practice and within practice environments that affect scope of practice.',
  '8.3.6': 'Keeps abreast of current nutrition and dietetics knowledge and trends.',
  '8.3.7': 'Integrates new knowledge and skills into practice.',

  // "8.4 Demonstrates and applies knowledge of culinary practices to effect behavioral change, taking into consideration customer needs and demands."
  '8.4.1':
    'Plans and designs nutritionally sound meals, menus and meal plans that meet customer needs and demand and that promote health and disease management.',
  '8.4.2':
    'Develops and/or modifies recipes, menus and meals using sensory perceptions and other food components.',
  '8.4.3':
    'Uses a variety of cooking techniques, food preparation and production and delivery systems.',
  '8.4.4': 'Considers customers choice, beliefs, food sensitivities, allergies, wants and needs.',
  '8.4.5': 'Uses counseling techniques to promote behavior changes in food preparation.',

  // "9. Education and Counseling"
  // "9.1 Recognizes and applies education and learning theories and principles in practice."
  '9.1.1': 'Demonstrates and applies age-appropriate education principles.',
  '9.1.2': 'Identifies and works to minimize and overcome barriers to learning.',
  '9.1.3': 'Identifies and analyzes factors that influence behavioral change.',
  '9.1.4': 'Evaluates factors that influence the learning process and skill building.',
  '9.1.5': 'Assesses, evaluates and applies educational theories.',

  // "9.2 Establishes, develops and implements program outlines and learning plans to meet the needs of various individuals, groups and populations."
  '9.2.1': 'Assesses learning needs of the individual or target group.',
  '9.2.2':
    'Determines and takes into consideration the literacy level and readability needs of the individual, group and population.',
  '9.2.3':
    'Applies educational theories and uses assessment results for planning process and development of materials and teaching aids.',
  '9.2.4':
    'Collaborates with learner(s) and colleagues to formulate specific, measurable and attainable objectives and goals.',

  // "9.3 Designs, selects and implements education strategies to meet the learning needs of the individual, group, community and population."
  '9.3.1':
    'Critiques and selects appropriate, current, evidence-based, practice-based reference materials to support the development of nutrition education resources.',
  '9.3.2':
    'Develops and/or selects a variety of learning activities based on a plan of action and outcomes, and the needs of the individual, group, community and population.',
  '9.3.3':
    'Develops and/or selects culturally sensitive, evidence-based materials that are appropriate to the audience.',
  '9.3.4':
    'Develops and/or selects educational materials at appropriate literacy level to achieve objectives.',
  '9.3.5': 'Uses a variety of strategies to deliver a plan of care or learning plan.',

  // "9.4 Teaches, guides and instructs a variety of individuals, groups or populations."
  '9.4.1':
    'Assesses current knowledge and skills of the individual, group, community and population.',
  '9.4.2':
    'Selects and uses appropriate content and teaching methods to meet individual and group needs.',
  '9.4.3': 'Takes into consideration special needs and disabilities and provides accommodations.',
  '9.4.4': 'Adjusts education plan to meet the needs of the individual, group and population.',
  '9.4.5':
    'Implements an individualized teaching plan in order to promote, maintain and enhance nutritional health and learning.',
  '9.4.6':
    'Uses socially and culturally appropriate strategies in order to respect diverse cultures and values.',
  '9.4.7':
    'Demonstrates competent use of technology to enhance the learning experience and delivery of information.',
  '9.4.8': 'Evaluates the impact of the individuals learning and new knowledge and skills.',

  // "9.5 Evaluates learning including teaching style and delivery using appropriately designed instruments for data collection."
  '9.5.1': 'Identifies usable evaluation questions from existing instruments.',
  '9.5.2': 'Writes new items to be used in data collection for evaluation of learning.',
  '9.5.3': 'Develops procedures for standardized use of instruments.',
  '9.5.4': 'Uses evaluation instrument to collect data and assess outcomes of education process.',
  '9.5.5':
    'Analyzes and synthesizes evaluation data to recommend and make modifications to program.',

  // "9.6 Uses effective counseling and coaching skills and strategies in practice."
  '9.6.1':
    'Determines and applies counseling theories, psychological methods and strategies that empower customers to make changes.',
  '9.6.2':
    'Obtains permission and informed consent for the provision of counseling as appropriate.',
  '9.6.3':
    'Determines the customers expectations and aspirations and manages situations wherein these expectations cannot be met.',
  '9.6.4': 'Assesses customers readiness to change when applying specific counseling strategies.',
  '9.6.5': 'In collaboration with the customer, develops counseling or coaching goals.',
  '9.6.6': 'Assists with resolution of barriers to achieving counseling and coaching goals.',
  '9.6.7': 'Determines if further action is required as follow-up to counseling and coaching.',

  // "10. Clinical Care"
  // "10.1 Performs nutrition screening to evaluate individual health, malnutrition and disease while adhering to the Standards of Practice (SOP) in Nutrition Care for RDNs."
  '10.1.1':
    'Identifies and selects valid and reliable screening tool(s) to obtain and verify relevant data in support of nutrition assessment.',
  '10.1.2':
    'Conducts the nutrition screening to identify patient risks and level of criticality and to direct services.',
  '10.1.3':
    'Works collaboratively with the interdisciplinary team (including NDTRs) to identify and implement valid and reliable nutrition screening to support access to care.',

  // "10.2 Implements the Nutrition Care Process to ensure individual health goals are established, monitored and achieved while adhering to the Standards of Practice in Nutrition Care for RDNs."
  '10.2.1':
    'Identifies and selects valid and reliable tools to conduct a comprehensive nutrition assessment.',
  '10.2.2':
    'Works collaboratively with interdisciplinary team to identify and implement valid and reliable nutrition assessment tools to support access to care.',
  '10.2.3':
    'Analyzes and synthesizes the assessment data to identify nutrition problems following the Standards of Practice in Nutrition Care for RDNs.',
  '10.2.4':
    'Integrates foundational dietetics knowledge with critical appraisal of assessment data to diagnose nutrition problems (using problem solving, etiology, signs and symptoms [PES] = statements), which can be resolved or improved through treatment or nut...',
  '10.2.5': 'Develops nutrition prescription to communicate required food and nutrient needs.',
  '10.2.6':
    'Effectively communicates findings and nutrition diagnoses to clients and the health care team.',
  '10.2.7': 'Prioritizes specific nutrition problem(s).',
  '10.2.8':
    'Establishes the plan of care, directly addressing the nutrition diagnosis in collaboration with the patient in defining the time, frequency and duration of the intervention.',
  '10.2.9':
    'In collaboration with the client and interdisciplinary team (including NDTRs), selects and implements current and evidence-based nutrition interventions and patient education.',
  '10.2.10': 'Monitors and identifies factors affecting patient progress in meeting goals.',
  '10.2.11':
    'Monitors, identifies and adjusts the intervention based on patient progress in meeting established goals.',
  '10.2.12': 'Establishes new goals and a new plan of care when original or interim goals are met.',

  // "10.3 Engages patient or substitute decision maker in the informed consent process prior to and during the provision of services."
  '10.3.1':
    'Demonstrates knowledge and applies principles of the informed consent process in accordance with organization policies, protocols and state and federal regulations.',
  '10.3.2':
    'Obtains consent from patients for the involvement of support personnel, students and other providers in the provision of medical nutrition therapy.',
  '10.3.3':
    'Verifies with the patient that he/she engaged in a third-party informed consent process (if referral was obtained by a third party).',
  '10.3.4': 'Demonstrates awareness that informed consent is an ongoing process.',
  '10.3.5':
    'Identifies situations in which informed consent is problematic and takes steps to rectify issues.',

  // "10.4 Applies knowledge of the interrelationship and impact of pharmacotherapy, dietary supplements, functional foods and nutrients on health and disease in accordance with Scope of Practice and Standards of Professional Performance for RDNs."
  '10.4.1':
    'Collects information related to the patients use of pharmacotherapy and dietary supplements.',
  '10.4.2':
    'Applies knowledge of pharmacotherapy and its effect on nutrient absorption, utilization and metabolism when developing and/or revising the plan of care.',
  '10.4.3':
    'Evaluates, educates and counsels on the interrelationship and impact of pharmacotherapy on nutrient absorption.',
  '10.4.4':
    'Makes recommendations for the appropriate use of vitamin and mineral supplementation in the management of health and disease.',
  '10.4.5':
    'Adheres to legislation, regulations, standards and organization policies related to recommending, prescribing and dispensing vitamins and minerals, dietary supplements, functional foods and medical nutrition foods/products.',

  // "10.5 Documents and maintains records according to the SOP for the RDN, legislation, regulations and organization policies."
  '10.5.1': 'Uses a systematic approach to record keeping.',
  '10.5.2':
    'Maintains clear, accurate and appropriate records of patient encounters, communications with patient and others, plans and interventions according to organization policies, standards and state and federal regulations.',
  '10.5.3':
    'Adheres to legislative and organization requirements regarding the protection of privacy and security of information and storage and disclosure of patient records.',
  '10.5.4':
    'Takes action to anticipate and minimize foreseeable risk of unauthorized access to records.',

  // "11. Business, Industry and Product Development and Marketing"
  // "11.1 Leads or participates in the development of products and/or services related to food, nutrition, equipment and systems."
  '11.1.1':
    'Conducts feasibility studies to determine validity of, and need for, products or services.',
  '11.1.2':
    'Develops and implements evaluation tools and methodologies to test products and services.',
  '11.1.3':
    'Leads and facilitates product testing using research methodologies to determine acceptability and viability and to confirm desired outcomes.',
  '11.1.4':
    'Interprets and advises on food manufacturing standards, regulatory requirements, policies and national dietary guidelines to inform others and to support product development.',
  '11.1.5':
    'Incorporates market research, consumer insights and current evidence-based trends in order to develop new products and services.',

  // "11.2 Uses evidence-based literature and research to support the marketing, advertising and sales of products and services."
  '11.2.1':
    'Evaluates product characteristics, ingredients and claims in order to ensure accuracy and compliance with regulatory requirements.',
  '11.2.2':
    'Works collaboratively with the team to identify market trends and target customers wants and needs.',
  '11.2.3':
    'Conducts environmental scans and evaluates product ingredients and product/service claims to develop advertising and marketing strategies.',
  '11.2.4': 'Collects, reviews and evaluates the literature and data to define target audience.',
  '11.2.5': 'Reviews and evaluates science and evidence-based literature, to validate claims.',
  '11.2.6':
    'Communicates claims about product and service to customers, identifying validated product ingredients, indications and evidence-based characteristics.',
  '11.2.7':
    'Educates project team on nutrition claims using current science and evidence-based literature.',
  '11.2.8':
    'Develops messaging, applying principles of change management, motivation and evidence-based practice.',
  '11.2.9': 'Considers audience and adjusts messaging and delivery method accordingly.',
  '11.2.10': 'Develops evidence-based scientific information that is clear and customer focused.',

  // "11.3 Incorporates key sales principles while maintaining integrity of self, the organization and the nutrition and dietetics profession."
  '11.3.1': 'Adheres to the code of ethics of the profession.',
  '11.3.2':
    'Establishes, implements and evaluates measurable goals to meet projections or targets.',
  '11.3.3': 'Instills and maintains trust and respect from the customer, stakeholders and others.',
  '11.3.4': 'Maintains accurate and legible documentation of customer interactions.',
  '11.3.5':
    'Adheres to legislation, regulations, standards and guidelines when selling products and services.',
  '11.3.6':
    'Demonstrates advanced communication, negotiation and leadership skills and flexibility with customers, team and others.',
  '11.3.7':
    'Develops and implements marketing materials and advertisements, adhering to legislative, regulatory and organization policies.',
  '11.3.8':
    'Adheres to industry regulations, standards and policies when engaging in market testing process and market research testing activities for products or services.',
  '11.3.9':
    'Accurately and objectively interprets stakeholder consultation and data to inform product and services development and delivery.',

  // "11.4 Develops advertising messages and materials in a professional and ethical manner."
  '11.4.1':
    'Stays abreast of changing trends and technology in promotion, marketing and advertising.',
  '11.4.2': 'Adheres to advertising regulations and organization policies.',
  '11.4.3':
    "Ensures advertising information is balanced, accurate and in the public's best interest.",
  '11.4.4':
    'Collaborates with others to develop materials and messaging when the required skills exceed ability levels.',
  '11.4.5': 'Demonstrates an understanding of human sciences related to advertising and marketing.',

  // "12. Community and Population Health"
  // "12.1 Advocates for health and disease prevention in the community and population."
  '12.1.1':
    'Promotes nutrition programs and resources to address issues of food security, nutritional health and overall health and wellness.',
  '12.1.2': 'Leads or participates in the development of food, nutrition and health policies.',
  '12.1.3':
    'Collaborates with community partners and stakeholders in promoting nutritional health and disease prevention.',
  '12.1.4':
    'Influences legislation, regulation, and policy changes to impact nutrition in the community.',

  // "12.2 Assesses the need to develop and implement community or population health programs and/or intervention."
  '12.2.1': 'Identifies determinants of health and their influence on population health status.',
  '12.2.2':
    'Identifies and reviews relevant literature and evidence-based research to create program plans and to justify needs and/or actions.',
  '12.2.3':
    'Identifies individual, public/private organization and government roles and responsibilities within public health and health care systems.',
  '12.2.4': 'Identifies available resources and funding opportunities.',
  '12.2.5':
    'Collaborates with stakeholders to determine level of support, opportunities and risks.',
  '12.2.6': 'Determines resource needs for community and public health programs.',
  '12.2.7':
    'Utilizes applicable databases to analyze and assess variables associated with the target population.',
  '12.2.8': 'Synthesizes assessment data to determine and prioritize program goals and objectives.',

  // "12.3 Develops a community and population health program or intervention to meet the needs of the community and/or population."
  '12.3.1':
    'Designs programs and/or interventions based on assessment and evidence-based literature.',
  '12.3.2':
    'Applies community-based and population-based models and theories in the development of programs and/or interventions.',
  '12.3.3':
    'Takes into consideration any population and environmental disparities (health, availability, finances, access) when developing programs.',
  '12.3.4': 'Collaborates with community partners to design the program.',
  '12.3.5':
    'Takes into consideration public health policies and standards related to food and nutrition.',
  '12.3.6':
    'Prioritizes goals and objectives based on level of importance and the ability to change the health problem.',
  '12.3.7':
    'Documents social and epidemiological findings and the availability, accessibility, timetable, budget implications and allocation of the programs priority goals and objectives.',
  '12.3.8':
    'Assesses the compatibility of the program goals and objectives with those of the organization and its administration and adjusts accordingly.',
  '12.3.9': 'Identifies measurable outcomes and indicators for evaluation plan.',

  // "12.4 Implements community-based and population-based programs and/or interventions in collaboration with stakeholders."
  '12.4.1':
    'Implements programs and/or interventions while adhering to public health policies and standards.',
  '12.4.2':
    'Collaborates with community partners to implement programs based on the assessed needs, values, beliefs, limitations and strengths of the population.',
  '12.4.3':
    'Utilizes appropriate behavioral change theories, social marketing, behavior economics and communication strategies in the delivery of nutrition programs.',
  '12.4.4':
    'Identifies and implements strategies for reaching individuals and populations in collaboration with stakeholders.',
  '12.4.5': 'Provides nutrition information and education to the community.',
  '12.4.6':
    ' Applies and integrates the Nutrition Care Process to meet the complex needs of the target population.',

  // "12.5 Evaluates nutrition programs to measure program effectiveness and outcomes and recommends modifications to support changes and/or sustainability of program."
  '12.5.1':
    'Develops or contributes to an evaluation process and/or cost-benefit analysis to determine the effectiveness and outcomes of the program.',
  '12.5.2':
    'Develops a mechanism and/or maintains databases to monitor and measure goals, outcomes and trending data.',
  '12.5.3': 'Critically reviews evaluations to make recommendations for changes.',
  '12.5.4':
    'Develops recommendations considering evaluation data, needs of the population, trending data, cost-benefit analysis and funding source.',
  '12.5.5':
    'Presents evaluation findings, outcomes and recommendations to stakeholders to promote change and/or substantiate program.',

  // "13. Foodservice Management"
  // "13.1 Analyzes, designs and monitors foodservice systems to optimize operations."
  '13.1.1':
    'Conducts a needs assessment in collaboration with staff to determine overall department needs.',
  '13.1.2':
    'Designs a plan for the purchase of equipment for the production and service of food that meets required safety and sanitation standards.',
  '13.1.3':
    'Applies nutrition and foodservice principles when developing equipment specifications that meet the expectations of internal and external stakeholders.',
  '13.1.4': 'Applies budgeting principles when ordering equipment.',
  '13.1.5':
    'Considers staffing, processes, equipment and layout plans in developing and optimizing workflow.',
  '13.1.6':
    'Adheres to all relevant regulatory guidelines in establishing facility layout and design.',
  '13.1.7':
    'Applies principles of food safety and sanitation in the design and purchase of equipment for production and service of food.',

  // "13.2 Develops, directs, manages and evaluates the use of standardized recipes for food production in delivery systems."
  '13.2.1':
    'Adheres to and communicates relevant regulations, dietary guidelines and organization policies in menu development and implementation.',
  '13.2.2':
    'Evaluates and incorporates individual and target group needs and requirements in order to develop menu items.',
  '13.2.3': 'Incorporates principles of food science and preparation in recipe development.',
  '13.2.4':
    'Develops menus in consideration of production and service capabilities of facility and staff.',
  '13.2.5':
    'Participates in and coordinates with committees and staff to review and determine purchasing and product needs.',
  '13.2.6':
    'Develops tools and measurements to evaluate menu items, quality of products, costs, nutritional values and customer needs.',
  '13.2.7':
    'Modifies recipes and menus that accommodate diverse health, economic and cultural needs in order to achieve nutritional goals and requirements.',
  '13.2.8': 'Seeks and incorporates staff and customer feedback in developing menus and recipes.',

  // "13.3 Develops, manages and demonstrates accountability for operational budgets in foodservice systems."
  '13.3.1':
    'Leads the development of budget preparation in accordance with accepted accounting principles and organizational policies.',
  '13.3.2':
    'Implements a reliable system for budgets and financial record maintenance that adheres to generally accepted accounting principles.',
  '13.3.3':
    'Collects data and utilizes technology to assess current trends in order to forecast revenues and expenses.',
  '13.3.4': 'Synthesizes and analyzes data from multiple sources to manage budget.',
  '13.3.5': 'Identifies opportunities to maintain and increase revenue.',
  '13.3.6': 'Initiates capital requests, providing evidence to justify need.',
  '13.3.7':
    'Demonstrates understanding of payment and reimbursement models and their financial impact.',

  // "14. Organization Management"
  // "14.1 Employs principles of productivity to optimize safe, ethical and efficient resource utilization."
  '14.1.1':
    'Develops and applies criteria to measure and monitor productivity in order to support safe, ethical and efficient services delivery.',
  '14.1.2': 'Develops goals and outcomes to measure and enhance productivity.',
  '14.1.3': 'Assesses skills and competence of staff to optimize workflow.',
  '14.1.4': 'Monitors staff compliance with laws, policies, procedures and established systems.',
  '14.1.5':
    'Leads and participates in department and organization goal setting in order to align departments goals with organizations strategic plan.',
  '14.1.6':
    'Measures goals and tracks outcomes against established benchmarks to ensure desired goals, standards and regulatory requirements are met.',
  '14.1.7': 'Develops, implements, monitors and evaluates strategies for efficient workflow.',
  '14.1.8': 'Communicates process changes to staff, customers, vendors and other stakeholders.',
  '14.1.9': 'Ensures staff is working within individual scope of practice and competence.',
  '14.1.10':
    'Advocates and allocates resources to increase staff skills, considering the best interests of the public.',

  // "14.2 Applies principles of financial management to support and achieve budgetary goals."
  '14.2.1': 'Establishes and maintains an operational budget.',
  '14.2.2': 'Manages expenditures and revenues using established procedures and tools.',
  '14.2.3': 'Synthesizes and analyzes data from multiple sources to manage organizational budget.',
  '14.2.4': 'Conducts and participates in a cost analysis to establish budget priorities.',
  '14.2.5':
    'Interprets operational data and financial statements to manage programs and services within budget.',
  '14.2.6': 'Applies knowledge of payment and reimbursement models.',
  '14.2.7':
    'Negotiates payment and reimbursement for customers/patients in order to promote access to care.',

  // "14.3 Applies principles of project management to achieve goals and objectives."
  '14.3.1': 'Defines the project parameters in collaboration with key stakeholders.',
  '14.3.2':
    'Leads and/or participates in stakeholder analysis and collaboration in order to establish project objectives and goals.',
  '14.3.3':
    'Communicates purpose and desired outcomes to stakeholders in order to achieve project objectives and goals.',
  '14.3.4': 'Manages risks to ensure successful completion of project.',
  '14.3.5': 'Identifies and anticipates risks related to the project and/or organization.',
  '14.3.6':
    'Tracks progress and completion of deliverables and takes action to keep project within scope.',

  // "14.4 Coordinates human resource activities, adhering to labor agreements, organization policies and applicable legislation."
  '14.4.1': 'Utilizes policies and processes to manage workforce.',
  '14.4.2': 'Initiates and participates in recruitment and hiring of staff.',
  '14.4.3': 'Plans and coordinates staff orientation and training.',
  '14.4.4':
    'Reviews and updates job descriptions to reflect current practices and the organizations needs.',
  '14.4.5':
    'Initiates and manages staff remediation and termination processes, applying applicable legislation and labor agreements.',
  '14.4.6': 'Establishes, communicates and measures performance expectations of staff.',
};
