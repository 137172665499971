/* eslint-disable camelcase */
/* eslint-disable  react/jsx-one-expression-per-line */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { getCustomerPortal } from '../../actions';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      flexDirection: 'column',
      borderRadius: '0px',
    },
  },
  content: {
    maxWidth: '1000px',
    margin: 'auto',
    display: 'flex',
  },
  heading: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
  },
});

class ProfilePersonalInfoView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getCustomerPortal();
  }

  goToStripe() {
    window.location = this.props.customerPortalUrl;
  }

  render() {
    const { classes } = this.props;

    const content =
      this.props.getPortalUrlSuccess === true ? (
        <>
          Redirecting to Stripe for easy account management.
          {this.goToStripe()}
          If you are not automatically directed,{' '}
          <a href={this.props.customerPortalUrl}>click here.</a>
        </>
      ) : (
        <CircularProgress />
      );

    return (
      <div className={classes.content}>
        <Helmet>
          <title>Profile - Customer Portal Redirect | CPEU.org</title>
        </Helmet>
        <div className={classes.root}>
          <div className={classes.heading}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="textSecondary" to="/profile">
                Profile
              </Link>
              <Typography color="textPrimary">Billing Info</Typography>
            </Breadcrumbs>
            <Typography variant="h3" color="secondary">
              Billing Info
            </Typography>
          </div>
          <Paper className={classes.paper}>{content}</Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerPortalUrl: state.paymentReducer.customerPortalUrl,
  gettingPortalUrl: state.paymentReducer.gettingPortalUrl,
  getPortalUrlSuccess: state.paymentReducer.getPortalUrlSuccess,
});

const mapActionsToProps = {
  getCustomerPortal,
};

const styledComponent = withStyles(styles)(ProfilePersonalInfoView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
