import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleAuth0Authentication } from '../actions';

class Callback extends Component {
  componentDidMount() {
    // eslint-disable-next-line no-console
    // console.log('Callback CDM this.props.history', this.props.history.location);
    this.props.handleAuth0Authentication(this.props.history);
    this.props.history.replace('/');
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <p>Loading profile...</p>;
  }
}

const mapStateToProps = () => ({});

const mapActionsToProps = {
  handleAuth0Authentication,
};

export default connect(mapStateToProps, mapActionsToProps)(Callback);
