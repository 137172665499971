/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

// Style imports
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAppDispatch, useAppSelector } from '../utils/hooks';

import { getCpeDetails } from '../actions';

import CpeDetails, { CpeDetailsProps } from '../components/cpe/CpeDetails';

import Feedback from '../components/feedback/Feedback';
import Meta from '../components/Meta';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    width: '100%',
    '@media (max-width: 576px)': {
      maxWidth: '540px',
      flexDirection: 'column',
    },
  },
  content: {
    maxWidth: 1000,
    margin: 'auto',
    display: 'flex',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
}));

function CpeDetailsView(props: { computedMatch: { params: { activity_number: number } } }) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const cpeDetails = useAppSelector((state) => state.cpeReducer.cpeDetails);
  const getCpeDetailsSuccess = useAppSelector((state) => state.cpeReducer.getCpeDetailsSuccess);
  const gettingCpeDetails = useAppSelector((state) => state.cpeReducer.gettingCpeDetails);
  /* eslint-disable react/destructuring-assignment */
  const computedMatch = Number(props.computedMatch.params.activity_number);

  useEffect(() => {
    // If the details do not match the page
    // AND the details are not being fetched
    if (
      Number(cpeDetails.activity_number) !== Number(computedMatch) &&
      gettingCpeDetails === false
    ) {
      dispatch(getCpeDetails(computedMatch));
    }
  }, [computedMatch, cpeDetails, getCpeDetailsSuccess]);

  const content =
    getCpeDetailsSuccess === true ? (
      <>
        <Meta title={cpeDetails.title} description={cpeDetails.description} />
        <CpeDetails details={cpeDetails as CpeDetailsProps} />
      </>
    ) : (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.content}>
      <div className={classes.root}>
        {content}
        <Feedback />
      </div>
    </div>
  );
}

export default CpeDetailsView;
