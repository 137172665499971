/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux dependencies
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

// Material UI and Date Picker
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import GAListener from './utils/GAListener';
import App from './App';
import muiTheme from './theme/muiTheme';

import { store, persistor } from './store';

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <GAListener trackingId="UA-165700275-2">
        <MuiThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <PersistGate loading={<CircularProgress />} persistor={persistor}>
              <AppWithRouter />
            </PersistGate>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </GAListener>
    </Router>
  </Provider>,
  document.getElementById('root')
);
