/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */

import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';

// Redux-Persist
const persistConfig = {
  key: 'root',
  storage,
  version: 0.4,
  blacklist: ['uiReducer', 'userReducer'],
  stateReconciler: autoMergeLevel2,
  migrate: (state: any) => {
    if (state) {
      // If there is a new version of the app, clear it out!
      if (state._persist.version !== persistConfig.version) {
        localStorage.clear();
        // localStorage.clear('persist:root');
        // localStorage.clear('persist:userReducer');
        return Promise.resolve(state);
      }

      return Promise.resolve(state);
    }
    return Promise.resolve(state);
  },
};

const pReducer = persistReducer(persistConfig, rootReducer as any);

export const store = configureStore({
  reducer: pReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
