/* eslint-disable camelcase */
/* eslint-disable no-console */
import axiosWithAuth from '../utils/axiosWithAuth';

import { getUser } from './userActions';

import { showSnackbar } from './uiActions';
import { errorWebhook } from './slackActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------PAYMENT------->

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';
export const CHECK_COUPON = 'CHECK_COUPON';
export const CHECK_COUPON_SUCCESS = 'CHECK_COUPON_SUCCESS';
export const CHECK_COUPON_FAILURE = 'CHECK_COUPON_FAILURE';
export const GET_CUSTOMER_PORTAL_URL = 'GET_CUSTOMER_PORTAL_URL';
export const GET_CUSTOMER_PORTAL_URL_SUCCESS = 'GET_CUSTOMER_PORTAL_URL_SUCCESS';
export const GET_CUSTOMER_PORTAL_URL_FAILURE = 'GET_CUSTOMER_PORTAL_URL_FAILURE';
export const RETRY_INVOICE = 'RETRY_INVOICE';
export const RETRY_INVOICE_SUCCESS = 'RETRY_INVOICE_SUCCESS';
export const RETRY_INVOICE_FAILURE = 'RETRY_INVOICE_FAILURE';
export const SUBSCRIPTION_COMPLETE = 'SUBSCRIPTION_COMPLETE';
export const SUBSCRIPTION_COMPLETE_SUCCESS = 'SUBSCRIPTION_COMPLETE_SUCCESS';
export const SUBSCRIPTION_COMPLETE_FAILURE = 'SUBSCRIPTION_COMPLETE_FAILURE';

export const getProducts = () => (dispatch) => {
  dispatch({ type: GET_PRODUCTS });

  axiosWithAuth()
    .get(`${url}/api/payment/products/`)
    .then((res) => {
      // console.log('getProducts res.data.data[0]', res.data.data[0]);
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: res.data.data[0] });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(GET_PRODUCTS_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_PRODUCTS_FAILURE, payload: err.message });
    });
};

export const createSubscriptionAction = (subscription) => (dispatch) => {
  // console.log('******createSubscriptionAction******', subscription);
  dispatch({ type: CREATE_SUBSCRIPTION });

  return axiosWithAuth()
    .post(`${url}/api/payment/create-subscription/`, subscription)
    .then((subscriptionResponse) => {
      // console.log('subscriptionResponse', subscriptionResponse);
      dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS, payload: subscriptionResponse.data });
      return subscriptionResponse.data;
    })
    .catch((err) => {
      dispatch(showSnackbar('Subscription failed.', 'error'));
      dispatch(errorWebhook(CREATE_SUBSCRIPTION_FAILURE, JSON.stringify(err.response)));
      dispatch({
        type: CREATE_SUBSCRIPTION_FAILURE,
        payload: err.response.data.error.message,
      });
      return new Error(err.response.data.error.message);
    });
};

export const checkCoupon = (coupon) => (dispatch) => {
  // console.log('******checkCoupon******', coupon);
  dispatch({ type: CHECK_COUPON });

  return axiosWithAuth()
    .get(`${url}/api/payment/coupon/${coupon}`)
    .then((res) => {
      // console.log(res);
      dispatch(showSnackbar('Coupon valid', 'success'));
      dispatch({ type: CHECK_COUPON_SUCCESS, payload: res.data });
      return true;
    })
    .catch((err) => {
      // console.log(err);
      dispatch(showSnackbar('That coupon is invalid', 'error'));
      dispatch(errorWebhook(CHECK_COUPON_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: CHECK_COUPON_FAILURE, payload: err.message });
      return false;
    });
};

export const getCustomerPortal = () => (dispatch) => {
  dispatch({ type: GET_CUSTOMER_PORTAL_URL });

  axiosWithAuth()
    .post(`${url}/api/payment/customer-portal/`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: GET_CUSTOMER_PORTAL_URL_SUCCESS, payload: res.data.url });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(GET_CUSTOMER_PORTAL_URL_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_CUSTOMER_PORTAL_URL_FAILURE, payload: err.message });
    });
};

export const retryInvoiceWithNewPaymentMethodAction = (invoice) => (dispatch) => {
  // console.log('******retryInvoiceWithNewPaymentMethodAction******', invoice);
  dispatch({ type: RETRY_INVOICE });

  return axiosWithAuth()
    .post(`${url}/api/payment/retry-invoice/`, invoice)
    .then((invoiceResponse) => {
      // console.log('retryInvoiceWithNewPaymentMethodAction invoiceResponse', invoiceResponse);
      dispatch({ type: RETRY_INVOICE_SUCCESS, payload: invoiceResponse.data });
      return invoiceResponse.data;
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(RETRY_INVOICE_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: RETRY_INVOICE_FAILURE, payload: err.response.data.error.message });
      return new Error(err.response.data.error.message);
    });
};

export const subscriptionCompleteAction = (result) => (dispatch) => {
  // console.log('******subscriptionCompleteAction******', result);
  dispatch({ type: SUBSCRIPTION_COMPLETE });

  axiosWithAuth()
    .post(`${url}/api/payment/subscription-complete/`, result)
    .then((subscriptionResponse) => {
      // console.log('subscriptionCompleteAction subscriptionResponse', subscriptionResponse);
      dispatch({ type: SUBSCRIPTION_COMPLETE_SUCCESS, payload: subscriptionResponse.data });
      dispatch(getUser());
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(SUBSCRIPTION_COMPLETE_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: SUBSCRIPTION_COMPLETE_FAILURE, payload: err.response.data.error.message });
    });
};
