/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import Tada from 'react-reveal/Tada';

// Style imports
import IconButton from '@material-ui/core/IconButton';
import Bookmark from '@material-ui/icons/Bookmark';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import { addBookmark, deleteBookmark } from '../../actions';

import { useAppSelector, useAppDispatch } from '../../utils/hooks';
// import Theme from '../../theme/muiTheme';

const useStyles = makeStyles((theme) => ({
  heart: {
    color: theme.palette.action.selected,
    '&:hover': {
      color: theme.palette.bookmark.hover,
    },
  },
  heartSelected: {
    color: theme.palette.bookmark.main,
    '&:hover': {
      color: theme.palette.bookmark.hover,
    },
  },
  loggedOut: {
    color: theme.palette.action.selected,
  },
}));

function CpeBookmark({ cpe_id }: { cpe_id: string }) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const inputRef = useRef(null);

  const loggingInSuccess = useAppSelector((state) => state.userReducer.loggingInSuccess);
  const bookmarksList = useAppSelector((state) => state.bookmarksReducer.bookmarksList);

  const [bookmark, setBookmark] = useState(bookmarksList[cpe_id]);

  const makeBookmark = () => {
    setBookmark(true);
    dispatch(addBookmark(cpe_id));
  };

  const removeBookmark = () => {
    setBookmark(false);
    dispatch(deleteBookmark(cpe_id));
  };

  const loggedInBookmark =
    bookmark === true ? (
      <div ref={inputRef}>
        <IconButton
          onClick={removeBookmark}
          color="primary"
          size="small"
          aria-label="remove from bookmarks"
        >
          <Bookmark className={classes.heartSelected} />
        </IconButton>
      </div>
    ) : (
      <IconButton onClick={makeBookmark} color="default" size="small" aria-label="add to bookmarks">
        <BookmarkBorder className={classes.heart} />
      </IconButton>
    );
  const bookmarkComponent = loggingInSuccess ? (
    loggedInBookmark
  ) : (
    <IconButton color="default" size="small" className={classes.loggedOut}>
      <BookmarkBorder />
    </IconButton>
  );

  return (
    <Tada spy={bookmark === true}>
      <Tooltip title="Add to Bookmarks" aria-label="add to bookmarks">
        {bookmarkComponent}
      </Tooltip>
    </Tada>
  );
}

export default CpeBookmark;
