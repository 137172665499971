/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import OnboardingLearningPlan from '../../components/onboarding/OnboardingLearningPlan';
import Feedback from '../../components/feedback/Feedback';

import { getUser, updateLP } from '../../actions';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      flexDirection: 'column',
      borderRadius: '0px',
    },
  },
  content: {
    maxWidth: '1000px',
    margin: 'auto',
    display: 'flex',
  },
  heading: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
    '@media (max-width: 576px)': {
      margin: theme.spacing(0.5, 1),
      // padding: theme.spacing(2),
    },
  },
  submitButton: {
    maxWidth: '100px',
    margin: theme.spacing(1),
  },
});

class ProfileLearningPlanView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gotUser: false,
      lpUpdated: false,
    };
  }

  componentDidMount() {
    if (this.props.auth0idToken && !this.props.getUserSuccess && !this.state.gotUser) {
      this.props.getUser();
    }
    if (this.props.getUserSuccess && !this.state.gotUser) {
      this.setState({
        gotUser: true,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.auth0idToken && !this.props.getUserSuccess && !this.state.gotUser) {
      this.props.getUser();
    }
    if (this.props.getUserSuccess && !this.state.gotUser) {
      this.setState({
        gotUser: true,
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    // Format and submit learning plan
    const lpArray = [];
    const lp = {};

    // If they don't fill anything out, set 'learning_plan_completed' to false
    if (this.props.onboardingLPArray.length === 0) {
      lp.learning_plan_completed = false;
      lp.learning_plan_type = 'idk';
    } else {
      lp.learning_plan_type = this.props.onboardingLPType;

      this.props.onboardingLPArray.map((e) => {
        return lpArray.push(e.id);
      });

      if (this.props.onboardingLPType === 'pi') {
        lp.learning_plan_completed = true;
        lp.learning_plan_pi = lpArray;
        lp.learning_plan_lc = [];
      } else if (this.props.onboardingLPType === 'lc') {
        lp.learning_plan_completed = true;
        lp.learning_plan_pi = [];
        lp.learning_plan_lc = lpArray;
      } else if (this.props.onboardingLPType === 'idk') {
        lp.learning_plan_completed = false;
        lp.learning_plan_pi = [];
        lp.learning_plan_lc = [];
      }
    }

    this.props.updateLP(lp);
    this.setState({ lpUpdated: true });
    // this.props.updateUser(updatedUser); // PUT to update user profile
    // this.props.history.push('/welcome');
  };

  render() {
    const { classes } = this.props;

    const content = this.state.gotUser === true ? <OnboardingLearningPlan /> : <CircularProgress />;

    return (
      <div className={classes.content}>
        <Helmet>
          <title>Profile - Learning Plan | CPEU.org</title>
        </Helmet>
        <div className={classes.root}>
          <div className={classes.heading}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="textSecondary" to="/profile">
                Profile
              </Link>
              <Typography color="textPrimary">Learning Plan</Typography>
            </Breadcrumbs>
            <Typography variant="h3" color="secondary">
              Learning Plan
            </Typography>
          </div>
          <Paper className={classes.paper}>{content}</Paper>
          <Button
            color="primary"
            variant="contained"
            className={classes.submitButton}
            onClick={this.handleSubmit}
            // disabled={!isEnabled}
          >
            Submit
          </Button>
          {this.state.lpUpdated ? 'Your Learning Plan has been updated' : ''}
          <Feedback />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth0idToken: state.userReducer.auth0idToken,
  getUserSuccess: state.userReducer.getUserSuccess,
  user: state.userReducer.user,

  onboardingLPArray: state.lpReducer.onboardingLPArray,
  onboardingLPType: state.lpReducer.onboardingLPType,
});

const mapActionsToProps = {
  getUser,
  // updateUser,
  updateLP,
};

const styledComponent = withStyles(styles)(ProfileLearningPlanView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
