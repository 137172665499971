/* eslint-disable react/jsx-no-useless-fragment */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CpeFilter from '../components/CpeFilter/CpeFilter';
import CpeCard from '../components/cpe/CpeCard';
import Feedback from '../components/feedback/Feedback';
import Paginate from '../components/paginate/Paginate';

import { getCpes, getBookmarksList, getCompletedList, getFilteredCpes } from '../actions';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      flexDirection: 'column',
      borderRadius: '0px',
    },
    // "@media (min-width: 768px)": {
    //   maxWidth: "720px"
    // },
    // "@media (min-width: 992px)": {
    //   maxWidth: "960px"
    // },
    // "@media (min-width: 1200px)": {
    //   maxWidth: "1140px"
    // }
  },
  content: {
    maxWidth: '1000px',
    margin: 'auto',
    display: 'flex',
  },
  heading: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
  },
  cpes: {
    width: 'auto',
  },
  CpeCards: {
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
});

class CpesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // cpes: [],
      getBookmarksList: false,
      getCompletedList: false,
      limit: 25,
    };
  }

  componentDidMount() {
    // console.log('CPEsView CDM');
    // console.log('CPEsView CDM props', this.props);
    // console.log('CPEsView CDM loggingInFailure', this.props.loggingInFailure);
    let page = 0;

    // If no CPEUs AND loginFailure
    if (this.props.cpes.length === 0 && this.props.loggingInFailure) {
      // console.log(
      //   'this.props.computedMatch.params.page',
      //   this.props.computedMatch.params.page,
      //   this.props.computedMatch.params,
      //   this.props.computedMatch
      // );
      // if (!this.props.computedMatch.params.page) {
      if (!this.props.page) {
        page = 1;
      } else {
        page = this.props.computedMatch.params.page;
      }
      // console.log('CPEsView CDM getCpes()', this.props.gettingCpes);
      this.props.getCpes(`page=${page}&limit=${this.state.limit}`);
      // eslint-disable-next-line no-restricted-globals
      this.props.history.push(`/cpes/${page}`);
    }
    // // If logged in and have not gotten the bookmarks
    if (
      this.props.loggingInSuccess &&
      this.props.getCpesSuccess &&
      !this.props.getBookmarksListSuccess
    ) {
      // console.log('CPEsView CDM getBookmarksList()');
      // this.props.getBookmarksList();
      // this.setState({ getBookmarksList: true });
    }
    // If logged in and have not gotten the completed
    if (
      this.props.loggingInSuccess &&
      this.props.getCpesSuccess &&
      !this.props.getCompletedListSuccess
    ) {
      // console.log('CPEsView CDM getCompletedList()');
      this.props.getCompletedList();
      this.setState({ getCompletedList: true });
    }

    // Landed on the page, no params, already got CPEs -> Go to the page (resume location)
    if (!this.props.computedMatch.params.page && this.props.page && !this.props.gettingCpes) {
      // console.log(
      //   'CDM Landed on the page, no params, already got CPEs -> Go to the page (resume location)'
      // );
      this.props.history.push(`/cpes/${this.props.page}`);
    }
  }

  componentDidUpdate() {
    // console.log('CPEsView CDU');
    // console.log('SecuredRoute CDU props', this.props);

    if (
      this.props.loggingInSuccess &&
      this.props.getCpesSuccess &&
      !this.props.getBookmarksListSuccess &&
      !this.state.getBookmarksList &&
      !this.props.gettingBookmarksList
    ) {
      // console.log('CPEsView CDU getBookmarksList()');
      this.props.getBookmarksList();
      this.setState({ getBookmarksList: true });
    }
    if (
      this.props.loggingInSuccess &&
      this.props.getCpesSuccess &&
      !this.props.getCompletedListSuccess &&
      !this.state.getCompletedList &&
      !this.props.gettingCompletedList
    ) {
      // console.log('CPEsView CDU getCompletedList()');
      this.props.getCompletedList();
      this.setState({ getCompletedList: true });
    }

    // I need to make two different paths, one for if you are logged in and 1 for if you are not
    // Getting the second and later pages is a challenge, we need to make sure we are logged in (or not)
    // and make sure we look at the props to make sure to get the right ones
    // if we are just logging in, don't get it yet, we need to wait and get from the learning plan

    // Paginate
    // landed on a new page but both are blank -> Get first page of CPEs
    if (!this.props.computedMatch.params.page && !this.props.page) {
      // console.log('landed on a new page but both are blank');
      this.props.getFilteredCpes(1, this.state.limit);
      this.props.history.replace('/cpes/1');
    }
    // Paginate
    // landed on a new page but params don't match the CPE page -> Get new CPEs
    if (this.props.computedMatch.params.page !== this.props.page) {
      // already getting CPEs, don't do it again
      // console.log('already getting CPEs, dont do it again');
      // console.log(
      //   'already getting CPEs, dont do it again gettingCpes',
      //   this.props.gettingCpes,
      //   'to get: false'
      // );
      // console.log(
      //   'already getting CPEs, dont do it again loggingIn',
      //   this.props.loggingIn,
      //   'to get: '
      // );
      // console.log(
      //   'already getting CPEs, dont do it again loggingInFailure',
      //   this.props.loggingInFailure,
      //   'to get: '
      // );
      // console.log(
      //   'already getting CPEs, dont do it again loggingInSuccess',
      //   this.props.loggingInSuccess,
      //   'to get: '
      // );
      // console.log(
      //   'already getting CPEs, dont do it again firstLogin',
      //   this.props.firstLogin,
      //   'to get: '
      // );
      // console.log(
      //   'already getting CPEs, dont do it again this.props.computedMatch.params.page',
      //   this.props.computedMatch.params.page
      // );
      // console.log('already getting CPEs, dont do it again this.props.page', this.props.page);

      // Already got the CPEUs, but clicked the home so no params
      if (
        !this.props.computedMatch.params.page &&
        this.props.page &&
        !this.props.gettingCpes &&
        this.props.cpes.length > 0
      ) {
        // console.log(
        //   'CDU Landed on the page, no params, already got CPEs -> Go to the page (resume location)'
        // );
        this.props.history.replace(`/cpes/${this.props.page}`);
      }

      // This version if logged out
      // IF not getting CPEUs and loginFailure
      if (!this.props.gettingCpes && this.props.loggingInFailure) {
        // console.log(
        //   'CPEsView CDU getFilteredCpes() after login failed',
        //   // this.props.computedMatch.params.page,
        //   this.props.page,
        //   this.props.page,
        //   this.props.gettingCpes
        // );
        this.props.getFilteredCpes(this.props.computedMatch.params.page, this.state.limit);
      }

      // This version if logged in and it is not the initial login (LP not setup)
      // IF not getting CPEUs AND loggingInSuccess AND NOT firstLogin
      if (!this.props.gettingCpes && this.props.loggingInSuccess && !this.props.firstLogin) {
        // console.log(
        //   'CPEsView CDU getFilteredCpes() if loggingInSuccess not firstLogin',
        //   // this.props.computedMatch.params.page,
        //   this.props.page,
        //   this.props.page,
        //   this.props.gettingCpes
        // );
        this.props.getFilteredCpes(this.props.computedMatch.params.page, this.state.limit);
      }
    }
  }

  render() {
    const { classes } = this.props;

    const loadCPE =
      this.props.getCpesSuccess === true ? (
        <div className={classes.CpeCards}>
          {this.props.cpes.map((e) => (
            <CpeCard cpe={e} key={e.cpe_id} />
          ))}
        </div>
      ) : (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      );

    const loadResults =
      this.props.getCpesSuccess === true ? (
        <>{this.props.cpeCount}</>
      ) : (
        <CircularProgress size="16px" />
      );

    const pagination = this.props.getCpesSuccess === true && (
      <Paginate
        count={parseInt(this.props.cpeCount, 10)}
        page={parseInt(this.props.page, 10)}
        lastPage={parseInt(this.props.lastPage, 10)}
        limit={parseInt(this.props.limit, 10)}
      />
    );

    return (
      <div className={classes.content}>
        <Helmet>
          <title>CPEUs | CPEU.org</title>
        </Helmet>
        <div className={classes.root}>
          <div className={classes.heading}>
            <Typography variant="h2" color="secondary">
              CPEUs
            </Typography>
          </div>
          <Paper className={classes.paper}>
            <div className={classes.filter}>
              <CpeFilter history={this.props.history} />
            </div>
            {/* <Divider /> */}
            Results: {loadResults}
          </Paper>

          <div className={classes.cpes}>{loadCPE}</div>
          {pagination}
          <Feedback />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.userReducer.loggingIn,
  loggingInSuccess: state.userReducer.loggingInSuccess,
  loggingInFailure: state.userReducer.loggingInFailure,
  firstLogin: state.userReducer.firstLogin,

  cpes: state.cpeReducer.cpes,
  cpeCount: state.cpeReducer.cpeCount,
  getCpesSuccess: state.cpeReducer.getCpesSuccess,
  gettingCpes: state.cpeReducer.gettingCpes,
  lastPage: state.cpeReducer.lastPage,
  page: state.cpeReducer.page,
  limit: state.cpeReducer.limit,

  gettingBookmarksList: state.bookmarksReducer.gettingBookmarksList,
  getBookmarksListSuccess: state.bookmarksReducer.getBookmarksListSuccess,

  gettingCompletedList: state.completedReducer.gettingCompletedList,
  getCompletedListSuccess: state.completedReducer.getCompletedListSuccess,
});

const mapActionsToProps = {
  getCpes,
  getBookmarksList,
  getCompletedList,

  getFilteredCpes,
};

const styledComponent = withStyles(styles)(CpesView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
