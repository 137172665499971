import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Material UI
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// Icons
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paginate: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrow: {
    margin: theme.spacing(0, 1),
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      boxShadow: theme.shadows[7],
    },
  },
  number: {
    margin: theme.spacing(0, 1),
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  page: {
    backgroundColor: 'white',
    borderRadius: '50%',
    margin: theme.spacing(0, 1),
    height: theme.spacing(4),
    width: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dots: {
    margin: theme.spacing(0, 1),
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

// function Paginate(props) {
function Paginate({
  count,
  page,
  lastPage,
  limit,
}: {
  count: number;
  page: number;
  lastPage: number;
  limit: number;
}) {
  const classes = useStyles();
  // const { count, page, lastPage, limit } = props;
  const leftArrowSection =
    page <= 1 ? (
      ''
    ) : (
      <Link to={`/cpes/${page - 1}`} key="leftArrow" style={{ textDecoration: 'none' }}>
        <IconButton aria-label="back">
          <NavigateBeforeIcon color="secondary" />
        </IconButton>
      </Link>
    );

  const firstPageSection =
    page <= 2 ? (
      ''
    ) : (
      <Link to="/cpes/1" key={1} style={{ textDecoration: 'none' }}>
        <div className={classes.number}>
          <Typography color="textPrimary">1</Typography>
        </div>
      </Link>
    );

  const leftDotsSection =
    page <= 3 ? (
      ''
    ) : (
      <div className={classes.dots}>
        <Typography color="textPrimary">...</Typography>
      </div>
    );

  const previousPageSection =
    page <= 1 ? (
      ''
    ) : (
      <Link to={`/cpes/${page - 1}`} key={page - 1} style={{ textDecoration: 'none' }}>
        <div className={classes.number}>
          <Typography color="textPrimary">{page - 1}</Typography>
        </div>
      </Link>
    );

  const nextPageSection =
    page >= lastPage ? (
      ''
    ) : (
      <Link to={`/cpes/${page + 1}`} key={page + 1} style={{ textDecoration: 'none' }}>
        <div className={classes.number}>
          <Typography color="textPrimary">{page + 1}</Typography>
        </div>
      </Link>
    );

  const rightDotsSection =
    page >= lastPage - 2 ? (
      ''
    ) : (
      <div className={classes.dots}>
        <Typography color="textPrimary">...</Typography>
      </div>
    );

  const lastPageSection =
    page >= lastPage - 1 ? (
      ''
    ) : (
      <Link to={`/cpes/${lastPage}`} key={lastPage} style={{ textDecoration: 'none' }}>
        <div className={classes.number}>
          <Typography color="textPrimary">{lastPage}</Typography>
        </div>
      </Link>
    );

  const rightArrowSection =
    page >= lastPage ? (
      ''
    ) : (
      <Link to={`/cpes/${page + 1}`} key="rightArrow" style={{ textDecoration: 'none' }}>
        <IconButton aria-label="back">
          <NavigateNextIcon color="secondary" />
        </IconButton>
      </Link>
    );
  return (
    <div className={classes.root}>
      <div className={classes.paginate}>
        {leftArrowSection}
        {firstPageSection}
        {leftDotsSection}
        {previousPageSection}
        <div className={classes.page}>
          <Typography color="secondary">{page}</Typography>
        </div>
        {nextPageSection}
        {rightDotsSection}
        {lastPageSection}
        {rightArrowSection}
      </div>
      <div>
        <Typography color="textSecondary">
          {limit * page - limit + 1}-{Math.min(limit * page, count)} of {count} CPEs
        </Typography>
      </div>
      {/*   `1 2 3 ... 17 > */} {/* 1-18 of 300+ places to stay */}
      {/* < 1 `2 3 ... 17 > */} {/* 19-36 of 300+ places to stay */}
      {/* < 1 2 `3 4 ... 17 > */} {/* 37-54 of 300+ places to stay */}
      {/* < 1 2 3 `4 5 ... 17 > */} {/* 55-72 of 300+ places to stay */}
      {/* < 1 ... 4 `5  6 ... 17 > */} {/* 55-72 of 300+ places to stay */}
      {/* < 1 ... 5 `6  7 ... 17 > */} {/* 91-108 of 300+ places to stay */}
      {/* < 1 ... 15 16 `17 */} {/* 289-306 of 300+ places to stay */}
    </div>
  );
}

export default Paginate;
