/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect } from 'react-redux';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const styles = (theme) => ({
  root: {
    width: '100%',
    // minWidth: '300px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    margin: theme.spacing(2.5, 0),
  },
  saveButton: {
    maxWidth: '100px',
    // alignSelf: 'center',
    // margin: theme.spacing(1),
    // padding: theme.spacing(2, 0),
  },
  editOpen: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5, 0),
  },
});

function ProfileCard(props) {
  const { classes, cycle_begin_date, cycle_end_date } = props;
  const [isOpen, setIsOpen] = useState(false);

  const content = isOpen ? (
    <div className={classes.editOpen}>
      <Typography variant="subtitle2" color="textSecondary">
        {props.description}
      </Typography>
      <FormControl component="fieldset">
        {/* <FormLabel component="legend">What years are your cycle?:</FormLabel> */}
        <RadioGroup
          aria-label="cycle"
          name="cycle"
          value={props.cycle}
          onChange={(e) => props.handleCycleChange(e.target.value)}
        >
          <FormControlLabel value="2" control={<Radio />} label="June 1, 2015 - May 31, 2020" />
          <FormControlLabel value="3" control={<Radio />} label="June 1, 2016 - May 31, 2021" />
          <FormControlLabel value="4" control={<Radio />} label="June 1, 2017 - May 31, 2022" />
          <FormControlLabel value="5" control={<Radio />} label="June 1, 2018 - May 31, 2023" />
          <FormControlLabel value="6" control={<Radio />} label="June 1, 2019 - May 31, 2024" />
          <FormControlLabel value="7" control={<Radio />} label="June 1, 2020 - May 31, 2025" />
          <FormControlLabel value="0" control={<Radio />} label="Other" />
        </RadioGroup>
      </FormControl>
      {props.cycle === '0' ? (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            clearable
            // showTodayButton
            size="small"
            margin="dense"
            label="Start Date"
            inputVariant="outlined"
            placeholder="MM/DD/YYYY"
            format="MM/DD/YYYY"
            value={cycle_begin_date}
            onChange={props.handleBeginDateChange}
          />
          <DatePicker
            autoOk
            clearable
            // showTodayButton
            size="small"
            margin="dense"
            label="End Date"
            inputVariant="outlined"
            placeholder="MM/DD/YYYY"
            format="MM/DD/YYYY"
            value={cycle_end_date}
            // onChange={(cycleEndDate) => this.handleDateChange(cycleEndDate)}
            onChange={props.handleEndDateChange}
            // onChange={(cycleEndDate) => this.setState({ cycleEndDate })}
            // className={classes.date}
          />
        </MuiPickersUtilsProvider>
      ) : (
        <> </>
      )}
      <Button
        color="primary"
        variant="contained"
        size="medium"
        className={classes.saveButton}
        onClick={() => {
          props.handleCycleSubmit();
          setIsOpen(!isOpen);
        }}
        // disabled={!isEnabled}
      >
        Save
      </Button>
    </div>
  ) : (
    <Typography variant="subtitle2" color="textSecondary">
      {props.cycle_begin_dateSaved
        ? `Begin: ${moment(props.cycle_begin_dateSaved).format('MM-DD-YYYY')} End: ${moment(
            props.cycle_end_dateSaved
          ).format('MM-DD-YYYY')}`
        : ''}
    </Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.top}>
          <Typography variant="h5" color="secondary">
            {props.label}
          </Typography>
          <Button onClick={() => setIsOpen(!isOpen)}>Edit</Button>
        </div>

        {content}
      </div>
      <Divider />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(ProfileCard);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
