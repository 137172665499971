import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import Search from '@material-ui/icons/Search';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import ActivityTypeFilter from './ActivityTypeFilter';
// import LearningCodesFilter from './LearningCodesFilter';
import LpAlert from './LpAlert';
import PerformanceIndicatorsFilter from './PerformanceIndicatorsFilter';
import PriceFilter from './PriceFilter';
import ProvidersFilter from './ProvidersFilter';
import MoreFilters from './MoreFilters';

import {
  getCpes,
  getFilteredCpes,
  getFilteredLp,
  getFilteredText,
  filterReset,
  getFilteredPerformanceIndicators,
  getFilteredLearningCodes,
} from '../../actions';

const styles = (theme) => ({
  menu: {
    top: 60,
    left: 16,
  },
  menuBackdrop: {
    backgroundColor: '#00000080',
    WebkitOverflowScrolling: 'touch',
  },
  button: {
    borderRadius: '24px',
    margin: theme.spacing(0, 1, 0, 0),
    flex: '0 0 auto',
  },
  buttonRow: {
    padding: theme.spacing(0.5, 1, 0.5, 0),
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
  paper: {
    width: '70%',
    maxWidth: '500px',
    '@media (max-width: 576px)': {
      width: '90%',
    },
  },
  textField: {
    width: '80%',
    // flex: '1',
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
  searchRow: {
    // padding: theme.spacing(0.5, 1, 0.5, 1),
    // padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    // WebkitOverflowScrolling: 'touch',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      flexDirection: 'column-reverse',
      alignItems: 'end',
    },
  },
  searchButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '@media (max-width: 576px)': {
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(0.5),
    },
  },
  searchButton: {
    borderRadius: '24px',
    margin: 'auto 0px auto 4px',
    // flex: '0 0 auto',
  },
  learningPlan: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
});

class CpeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,

      menuOpenActivityType: false,

      menuOpenHours: false,
      hoursRange: [0, 250],

      menuOpenLearningCodes: false,

      menuOpenPerformanceIndicators: false,

      menuOpenPrice: false,
      priceRange: [0, 2000],

      menuOpenProviders: false,

      menuOpenLevel: false,

      menuOpenMoreFilters: false,

      searchText: '',

      menuOpenLp: false,
    };
  }

  componentDidMount() {
    this.setState({
      searchText: this.props.searchText,
    });
  }

  handleChange = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
    this.setState({ searchText: e.target.value });
  };

  handleActivityTypeMenu = (event) => {
    const anchorEl = event.currentTarget;
    this.setState((prevState) => ({
      ...prevState,
      menuOpenActivityType: !prevState.menuOpenActivityType,
      anchorEl,
    }));
  };

  // handleLevelMenu = (event) => {
  //   const anchorEl = event.currentTarget;
  //   this.setState({ ...this.state, menuOpenLevel: !this.state.menuOpenLevel, anchorEl });
  // };

  // handleLearningCodesMenu = (event) => {
  //   const anchorEl = event.currentTarget;
  //   this.setState({
  //     ...this.state,
  //     menuOpenLearningCodes: !this.state.menuOpenLearningCodes,
  //     anchorEl,
  //   });
  // };

  handleLpSwitch = () => {
    // console.log('handleLpSwitch', this.props.lpFilterOn, this.props.learning_plan_completed);
    if (!this.props.learning_plan_completed) {
      this.setState({ menuOpenLp: true });
      return;
    }
    // Was false before flipping true
    if (!this.props.lpFilterOn) {
      if (this.props.learning_plan_type === 'pi') {
        // console.log(
        //   'handleLpSwitch - pi',
        //   this.props.performanceIndicatorsSelected,
        //   this.props.learning_plan_pi
        // );
        // Need to check the PIs or LCs
        const pi = this.props.performanceIndicatorsSelected;

        this.props.learning_plan_pi.map((e) => {
          // is it already checked?
          const alreadyChecked = pi.indexOf(e);
          return alreadyChecked === -1 ? pi.push(e) : '';
        });

        this.props.getFilteredPerformanceIndicators(pi);
      } else {
        // console.log(
        //   'handleLpSwitch - lc',
        //   this.props.learningCodesSelected,
        //   this.props.learning_plan_lc
        // );
        // Need to check the PIs or LCs
        const lc = this.props.learningCodesSelected;

        this.props.learning_plan_lc.map((e) => {
          // is it already checked?
          const alreadyChecked = lc.indexOf(e);
          return alreadyChecked === -1 ? lc.push(e) : '';
        });

        this.props.getFilteredLearningCodes(lc);
      }
    }
    this.props.getFilteredLp();
  };

  handleLpMenu = (menuOpenLp) => {
    this.setState({ menuOpenLp });
  };

  handlePerformanceIndicatorsMenu = (event) => {
    const anchorEl = event.currentTarget;
    this.setState((prevState) => ({
      ...prevState,
      menuOpenPerformanceIndicators: !prevState.menuOpenPerformanceIndicators,
      anchorEl,
    }));
  };

  handlePriceMenu = (event) => {
    const anchorEl = event.currentTarget;
    this.setState((prevState) => ({
      ...prevState,
      menuOpenPrice: !prevState.menuOpenPrice,
      anchorEl,
    }));
  };

  handleProvidersMenu = (event) => {
    const anchorEl = event.currentTarget;
    this.setState((prevState) => ({
      ...prevState,
      menuOpenProviders: !prevState.menuOpenProviders,
      anchorEl,
    }));
  };

  handleMoreFiltersMenu = (event) => {
    const anchorEl = event.currentTarget;
    this.setState((prevState) => ({
      ...prevState,
      menuOpenMoreFilters: !prevState.menuOpenMoreFilters,
      anchorEl,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.getFilteredText(this.state.searchText);
    this.props.getFilteredCpes();
    this.props.history.push('/cpes/1');
  };

  handleReset = (e) => {
    e.preventDefault();
    this.setState({ searchText: '' });
    this.props.filterReset();
    this.props.getFilteredCpes();
    this.props.history.push('/cpes/1');
  };

  render() {
    const activityTypeButton = this.props.activityTypeFilterOn ? (
      <Button
        size="small"
        onClick={this.handleActivityTypeMenu}
        color="secondary"
        variant="contained"
        className={this.props.classes.button}
      >
        Activity Type
      </Button>
    ) : (
      <Button
        size="small"
        onClick={this.handleActivityTypeMenu}
        // color="secondary"
        variant="outlined"
        className={this.props.classes.button}
      >
        Activity Type
      </Button>
    );

    // const learningCodesButton = this.props.learningCodesFilterOn ? (
    //   <Button
    //     size="small"
    //     onClick={this.handleLearningCodesMenu}
    //     color="secondary"
    //     variant="contained"
    //     className={this.props.classes.button}
    //   >
    //     Learning Codes
    //   </Button>
    // ) : (
    //   <Button
    //     size="small"
    //     onClick={this.handleLearningCodesMenu}
    //     // color="secondary"
    //     variant="outlined"
    //     className={this.props.classes.button}
    //   >
    //     Learning Codes
    //   </Button>
    // );

    const performanceIndicatorsButton = this.props.performanceIndicatorsFilterOn ? (
      <Button
        size="small"
        onClick={this.handlePerformanceIndicatorsMenu}
        color="secondary"
        variant="contained"
        className={this.props.classes.button}
      >
        Performance Indicators
      </Button>
    ) : (
      <Button
        size="small"
        onClick={this.handlePerformanceIndicatorsMenu}
        // color="secondary"
        variant="outlined"
        className={this.props.classes.button}
      >
        Performance Indicators
      </Button>
    );

    const priceButton = this.props.priceFilterOn ? (
      <Button
        size="small"
        onClick={this.handlePriceMenu}
        color="secondary"
        variant="contained"
        className={this.props.classes.button}
      >
        Price
      </Button>
    ) : (
      <Button
        size="small"
        onClick={this.handlePriceMenu}
        // color="secondary"
        variant="outlined"
        className={this.props.classes.button}
      >
        Price
      </Button>
    );

    const providersButton = this.props.providersFilterOn ? (
      <Button
        size="small"
        onClick={this.handleProvidersMenu}
        color="secondary"
        variant="contained"
        className={this.props.classes.button}
      >
        Providers
      </Button>
    ) : (
      <Button
        size="small"
        onClick={this.handleProvidersMenu}
        // color="secondary"
        variant="outlined"
        className={this.props.classes.button}
      >
        Providers
      </Button>
    );

    const moreFiltersButton =
      this.props.hoursFilterOn || this.props.includeExpiredFilter || this.props.levelFilterOn ? (
        <Button
          size="small"
          onClick={this.handleMoreFiltersMenu}
          color="secondary"
          variant="contained"
          className={this.props.classes.button}
        >
          More Filters
        </Button>
      ) : (
        <Button
          size="small"
          onClick={this.handleMoreFiltersMenu}
          // color="secondary"
          variant="outlined"
          className={this.props.classes.button}
        >
          More Filters
        </Button>
      );

    return (
      <>
        <form
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
          className={this.props.classes.searchRow}
        >
          <TextField
            name="search"
            id="search"
            label="Search"
            // placeholder="Search"
            value={this.state.searchText}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <div className={this.props.classes.searchButtons}>
            <Button
              size="medium"
              color="primary"
              // variant="outlined"
              variant="contained"
              className={this.props.classes.searchButton}
              onClick={this.handleSubmit}
              type="submit"
            >
              Search
            </Button>

            <Button
              size="medium"
              color="secondary"
              variant="outlined"
              // variant="contained"
              className={this.props.classes.searchButton}
              onClick={this.handleReset}
            >
              Clear
            </Button>
          </div>
        </form>
        <div className={this.props.classes.learningPlan}>
          <Typography variant="h6" color="secondary">
            My Learning Plan:
          </Typography>
          <Switch
            checked={this.props.lpFilterOn}
            // onChange={this.props.getFilteredLp}
            onChange={this.handleLpSwitch}
            name="lp"
            inputProps={{ 'aria-label': 'Learning Plan checkbox' }}
          />
          <LpAlert
            history={this.props.history}
            handleLpMenu={this.handleLpMenu}
            menuOpenLp={this.state.menuOpenLp}
          />
        </div>
        <div className={this.props.classes.buttonRow}>
          {providersButton}
          <Popover
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.menuOpenProviders}
            onClose={this.handleProvidersMenu}
            className={this.props.classes.menuBackdrop}
            PaperProps={{
              style: {
                overflow: 'hidden',
              },
            }}
          >
            {/* Need to wrap ProvidersFilter in a div so the menu can add a ref to it */}
            <div>
              <ProvidersFilter
                providersSelectedProps={this.props.providersSelected}
                handleProvidersMenu={this.handleProvidersMenu}
                history={this.props.history}
              />
            </div>
          </Popover>

          {activityTypeButton}
          <Popover
            id="activity-type"
            anchorEl={this.state.anchorEl}
            open={this.state.menuOpenActivityType}
            onClose={this.handleActivityTypeMenu}
            className={this.props.classes.menuBackdrop}
          >
            {/* Need to wrap Activity Type Filter in a div so the menu can add a ref to it */}
            <div>
              <ActivityTypeFilter
                handleActivityTypeMenu={this.handleActivityTypeMenu}
                activityTypeSelectedProps={this.props.activityTypeSelected}
                history={this.props.history}
              />
            </div>
          </Popover>

          {/* {learningCodesButton}
          <Popover
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.menuOpenLearningCodes}
            onClose={this.handleLearningCodesMenu}
            // getContentAnchorEl={null}
            // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            // transformOrigin={{ vertical: "top", horizontal: "center" }}
            className={this.props.classes.menuBackdrop}
            // MenuListProps={{ style: { padding: 0 } }}
          >
            {/* Need to wrap Learning Codes Filter in a div so the menu can add a ref to it 
            <div>
              <LearningCodesFilter
                learningCodesSelectedProps={this.props.learningCodesSelected}
                handleLearningCodesMenu={this.handleLearningCodesMenu}
                history={this.props.history}
                lpFilterOn={this.props.lpFilterOn}
              />
            </div>
          </Popover> */}

          {performanceIndicatorsButton}
          <Popover
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.menuOpenPerformanceIndicators}
            onClose={this.handlePerformanceIndicatorsMenu}
            // getContentAnchorEl={null}
            // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            // transformOrigin={{ vertical: "top", horizontal: "center" }}
            className={this.props.classes.menuBackdrop}
            // MenuListProps={{ style: { padding: 0 } }}
          >
            {/* Need to wrap Performance Indicators filter in a div so the menu can add a ref to it */}
            <div>
              <PerformanceIndicatorsFilter
                performanceIndicatorsSelectedProps={this.props.performanceIndicatorsSelected}
                handlePerformanceIndicatorsMenu={this.handlePerformanceIndicatorsMenu}
                history={this.props.history}
                lpFilterOn={this.props.lpFilterOn}
              />
            </div>
          </Popover>

          {priceButton}
          <Popover
            id="price-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.menuOpenPrice}
            onClose={this.handlePriceMenu}
            className={this.props.classes.menuBackdrop}
            PaperProps={{ classes: { root: this.props.classes.paper } }}
          >
            {/* Need to wrap PriceFilter in a div so the menu can add a ref to it */}
            <div>
              <PriceFilter handlePriceMenu={this.handlePriceMenu} history={this.props.history} />
            </div>
          </Popover>

          {moreFiltersButton}
          <Dialog
            id="more-filters-menu"
            open={this.state.menuOpenMoreFilters}
            onClose={this.handleMoreFiltersMenu}
            className={this.props.classes.menuBackdrop}
            PaperProps={{
              classes: { root: this.props.classes.paper },
            }}
          >
            <MoreFilters
              handleMoreFiltersMenu={this.handleMoreFiltersMenu}
              levelSelectedProps={this.props.levelSelected}
              includeExpiredFilterProps={this.props.includeExpiredFilter}
              history={this.props.history}
            />
          </Dialog>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityTypeFilterOn: state.filterReducer.activityTypeFilterOn,
  activityTypeSelected: state.filterReducer.activityTypeSelected,

  hoursFilterOn: state.filterReducer.hoursFilterOn,

  // This is set after getting the LP from the user
  lpFilterOn: state.filterReducer.lpFilterOn,
  // lpSelected: state.filterReducer.lpSelected,
  // lpPiFilterOn: state.filterReducer.lpPiFilterOn,

  learningCodesFilterOn: state.filterReducer.learningCodesFilterOn,
  learningCodesSelected: state.filterReducer.learningCodesSelected,

  performanceIndicatorsFilterOn: state.filterReducer.performanceIndicatorsFilterOn,
  performanceIndicatorsSelected: state.filterReducer.performanceIndicatorsSelected,

  providersFilterOn: state.filterReducer.providersFilterOn,
  providersSelected: state.filterReducer.providersSelected,

  searchText: state.filterReducer.searchText,

  levelFilterOn: state.filterReducer.levelFilterOn,
  levelSelected: state.filterReducer.levelSelected,

  priceFilterOn: state.filterReducer.priceFilterOn,

  includeExpiredFilter: state.filterReducer.includeExpiredFilter,

  learning_plan_lc: state.lpReducer.learning_plan_lc,
  learning_plan_pi: state.lpReducer.learning_plan_pi,
  learning_plan_completed: state.lpReducer.learning_plan_completed,
  learning_plan_type: state.lpReducer.learning_plan_type,
});

const mapActionsToProps = {
  getCpes,
  getFilteredCpes,
  getFilteredLp,
  getFilteredText,
  filterReset,
  getFilteredPerformanceIndicators,
  getFilteredLearningCodes,
};

const styledComponent = withStyles(styles)(CpeFilter);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
