/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

// Icons
import AccountCircle from '@material-ui/icons/AccountCircle';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import School from '@material-ui/icons/School';

import logo from '../../img/logo.svg';
import logoCircle from '../../img/logo-circle.svg';

// import { userLogin } from '../../actions';

// import auth0Client from '../../auth/Auth';

// eslint-disable-next-line no-unused-vars
const CpeuTabs = withStyles(() => ({
  root: {
    // marginLeft: theme.spacing.unit,
    minHeight: '64px',
  },
  indicator: {
    height: 3,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
  },
}))(Tabs);

const CpeuTab = withStyles((theme) => ({
  root: {
    minHeight: '64px',
    textTransform: 'initial',
    margin: theme.spacing(0),
    minWidth: 0,
    // color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
      // color: 'rgba(255, 255, 255, 0.7)',
      opacity: 1,
    },
    '@media (max-width: 576px)': {
      margin: '0px',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottomRightRadius: '36px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '@media (max-width: 576px)': {
      marginRight: theme.spacing(1),
    },
  },
  title: {
    flexGrow: 1,
    '@media (max-width: 576px)': {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    height: '40px',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function Header(props) {
  const classes = useStyles();
  const [index, onChange] = useState(0);
  useEffect(() => {
    // console.log('Header props.location', props.location);
    const path = props.location.pathname;
    if (path === '/' && index !== 0) onChange(0);
    else if (path === '/cpes' && index !== 0) onChange(0);
    else if (path === '/bookmarks' && index !== 1) onChange(1);
    else if (path === '/completed' && index !== 2) onChange(2);
    else if (path === '/profile' && index !== 3) onChange(3);
    else if (path === '/profile/personal-info' && index !== 3) onChange(3);
    else if (path === '/profile/learningplan' && index !== 3) onChange(3);
    else if (path === '/onboarding' && index !== false) onChange(false);
    else if (path === '/welcome' && index !== false) onChange(false);
  }, [index, props.location]);
  // const signOut = () => {
  //   auth0Client.signOut();
  //   localStorage.clear();
  //   window.location = 'https://cpeu.org';
  // };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed">
          <Toolbar>
            <div className={classes.logoContainer}>
              <Link to="/">
                <Hidden smUp>
                  <img src={logoCircle} alt="CPEU.org" className={classes.logo} />
                </Hidden>
                <Hidden xsDown>
                  <img src={logo} alt="CPEU.org" className={classes.logo} />
                </Hidden>
              </Link>
            </div>
            <CpeuTabs value={index} onChange={(e, val) => onChange(val)}>
              <CpeuTab
                label={<Hidden xsDown>CPEUs</Hidden>}
                to="/cpes"
                component={Link}
                icon={<School />}
              />
              <CpeuTab
                label={<Hidden xsDown>Bookmarks</Hidden>}
                to="/bookmarks"
                component={Link}
                icon={<BookmarksIcon />}
              />
              <CpeuTab
                label={<Hidden xsDown>Completed</Hidden>}
                to="/completed"
                component={Link}
                icon={<EmojiEventsIcon />}
              />
              <CpeuTab
                label={<Hidden xsDown>Profile</Hidden>}
                // to="/profile"
                to="/profile"
                component={Link}
                icon={<AccountCircle />}
              />
            </CpeuTabs>
            {/* {!props.auth0idToken && <Button onClick={auth0Client.signIn}>Sign In</Button>}
            {props.auth0idToken && (
              <div className={classes.right}>
                <Button
                  onClick={() => {
                    signOut();
                  }}
                >
                  Sign Out
                </Button>
              </div>
            )} */}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
}

const mapStateToProps = () => ({
  // auth0idToken: state.userReducer.auth0idToken,
  // loggingInSuccess: state.userReducer.loggingInSuccess,
});

const mapActionsToProps = {
  // userLogin,
};

export default connect(mapStateToProps, mapActionsToProps)(Header);
