import React, { useState } from 'react';
import { connect } from 'react-redux';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    width: '100%',
    // minWidth: '300px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    margin: theme.spacing(1, 0),
  },
  saveButton: {
    maxWidth: '100px',
    // alignSelf: 'center',
    // margin: theme.spacing(1),
    // padding: theme.spacing(2, 0),
  },
  editOpen: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5, 0),
  },
});

function Coupon(props) {
  const { classes } = props;
  const [isOpen, setIsOpen] = useState(false);

  const content = isOpen ? (
    <div className={classes.editOpen}>
      <TextField
        autoFocus
        required={props.required}
        name={props.title}
        id={props.title}
        label={props.label}
        value={props.value}
        onChange={props.handleCouponChange}
        margin="normal"
        variant="outlined"
      />
      <Button
        color="primary"
        variant="contained"
        size="medium"
        className={classes.saveButton}
        onClick={() => {
          props.handleCouponSubmit(props.title, props.value);
          setIsOpen(!isOpen);
        }}
        disabled={props.required && props.value === ''}
      >
        Save
      </Button>
    </div>
  ) : (
    <Typography variant="subtitle2" color="textPrimary">
      {props.value}
    </Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.top}>
          <Typography variant="h6" color="primary">
            {props.description}
          </Typography>
          <Button onClick={() => setIsOpen(!isOpen)}>Enter</Button>
        </div>

        {content}
      </div>
      {/* <Divider /> */}
    </div>
  );
}

const mapStateToProps = () => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(Coupon);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
