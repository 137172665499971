/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './App.css';

import {
  // userLogin,
  silentAuth,
} from './actions';

import Header from './components/header/header';
import OnboardingHeader from './components/header/OnboardingHeader';
import SimpleSnackbar from './components/snackbar/SimpleSnackbar';
import BookmarksView from './views/BookmarksView';
import CompletedView from './views/CompletedView';
import CpeDetailsView from './views/CpeDetailsView';
import CpeEditView from './views/CpeEditView';
import CpesView from './views/CpesView';
import LoginView from './views/LoginView';
import OnboardingView from './views/OnboardingView';
import ProfileView from './views/ProfileView';
import ProfileLearningPlanView from './views/profile/ProfileLearningPlanView';
import ProfilePersonalInfoView from './views/profile/ProfilePersonalInfoView';
import ProfileCustomerProfileView from './views/profile/ProfileCustomerProfileView';
import ProvidersView from './views/ProvidersView';
import SignUpView from './views/SignUpView';
import WelcomeView from './views/WelcomeView';

// Auth
import Callback from './auth/Callback';
import SecuredRoute from './auth/SecuredRoute';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
    };
  }

  async componentDidMount() {
    // console.log('App CDM', this.props.location.pathname);
    if (this.props.location.pathname === '/callback') {
      // console.log('App CDM if callback');
      // This is the route that is called when a user logs in.
      this.setState({ checkingSession: false });

      // If they tried to go to a page but needed to login, push them to that page
      if (localStorage.loginPathname) {
        // console.log('App CDM localStorage push', localStorage.loginPathname);
        this.props.history.push(localStorage.loginPathname);
        localStorage.removeItem('loginPathname');
      }
      return;
    }
    try {
      // console.log('App CDM try, await silentAuth', this.props.location.pathname);
      await this.props.silentAuth(this.props.location.pathname, this.props.history);
      // this.forceUpdate();
    } catch (err) {
      // console.log('App CDM catch ERROR', err);
      // if (err.error !== 'login_required') console.log(err.error);
    }
    this.setState({ checkingSession: false });
    // console.log('App CDM LAST', this.props.location.pathname);
  }

  // componentDidUpdate() {
  //   console.log('App CDU');
  //   if (this.props.auth0idToken && !this.props.loggingInSuccess) {
  //     console.log('App CDU userLogin', this.props.loggingInSuccess);
  //     this.props.userLogin();
  //   }
  // }

  render() {
    return (
      <div className="App">
        {this.props.location.pathname === '/onboarding' ? (
          <OnboardingHeader />
        ) : (
          <Header location={this.props.location} />
        )}
        <Switch>
          <Route exact path="/callback" component={Callback} />
          <Route exact path="/login" component={LoginView} />
          <Route exact path="/signup" component={SignUpView} />
          <Route exact path="/onboarding" component={OnboardingView} />
          <SecuredRoute
            path="/cpes/:page"
            exact
            component={CpesView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/cpes"
            component={CpesView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/cpe/:activity_number/edit"
            exact
            component={CpeEditView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/cpe/:activity_number"
            exact
            component={CpeDetailsView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/providers"
            component={ProvidersView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />

          <SecuredRoute
            path="/welcome"
            component={WelcomeView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />

          <SecuredRoute
            path="/profile/learningplan"
            component={ProfileLearningPlanView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/profile/personal-info"
            component={ProfilePersonalInfoView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/profile/billing"
            component={ProfileCustomerProfileView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />

          <SecuredRoute
            path="/profile"
            component={ProfileView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/bookmarks"
            component={BookmarksView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/completed"
            component={CompletedView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
          <SecuredRoute
            path="/"
            component={CpesView}
            checkingSession={this.state.checkingSession}
            history={this.props.history}
          />
        </Switch>
        <SimpleSnackbar />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // auth0idToken: state.userReducer.auth0idToken,
  loggingInSuccess: state.userReducer.loggingInSuccess,
});

const mapActionsToProps = {
  // userLogin,
  silentAuth,
};

export default connect(mapStateToProps, mapActionsToProps)(App);
