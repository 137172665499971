/* eslint-disable prefer-const */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */

import React from 'react';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { updateCpe } from '../../actions';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '100%',
  },
  formControl: {
    minWidth: 200,
  },
});

class CpeEdit extends React.Component {
  constructor() {
    super();

    this.state = {
      title: '',
      activity_number: '',
      url: '',
      cost: '',
      description: '',
      activity_type: '',
      learning_codes: '',
      performance_indicators: '',
      start_date: '',
      end_date: '',
      country: '',
      state: '',
      city: '',
      contact: '',
      provider_id: '',
    };
  }

  componentDidMount() {
    this.setState({
      title: this.props.details.title,
      activity_number: this.props.details.activity_number,
      url: this.props.details.url === null ? '' : this.props.details.url,
      cost: this.props.details.cost === null ? '' : this.props.details.cost,
      description: this.props.details.description,
      activity_type: this.props.details.activity_type,
      learning_codes: this.props.details.learning_codes,
      performance_indicators: this.props.details.performance_indicators,
      start_date: this.props.details.start_date,
      end_date: this.props.details.end_date,
      country: this.props.details.country === null ? '' : this.props.details.country,
      state: this.props.details.state === null ? '' : this.props.details.state,
      city: this.props.details.city === null ? '' : this.props.details.city,
      contact: this.props.details.contact === null ? '' : this.props.details.contact,
      provider_id:
        this.props.details.provider[0].provider_id === null
          ? ''
          : this.props.details.provider[0].provider_id,
    });
  }

  handleChange = (e) => {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const updatedCPE = {
      title: this.state.title,
      activity_number: this.state.activity_number,
      url: this.state.url === '' ? null : this.state.url,
      cost: this.state.cost === '' ? null : this.state.cost,
      description: this.state.description,
      activity_type: this.state.activity_type,
      learning_codes: this.state.learning_codes,
      performance_indicators: this.state.performance_indicators,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      country: this.state.country === '' ? null : this.state.country,
      state: this.state.state === '' ? null : this.state.state,
      city: this.state.city === '' ? null : this.state.city,
      contact: this.state.contact === '' ? null : this.state.contact,
      provider_id: this.state.provider_id,
    };

    this.props.updateCpe(updatedCPE);
  };

  render() {
    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          className={this.props.classes.button}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
        <Paper className={this.props.classes.paper}>
          <Typography variant="body1" color="secondary">
            Title: {this.props.details.title}
          </Typography>
          <TextField
            required
            name="title"
            id="title"
            label="Title"
            placeholder="Title"
            value={this.state.title}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            Activity Number: {this.props.details.activity_number}
          </Typography>
          <TextField
            disabled
            required
            name="activity_number"
            id="activity_number"
            label="Activity Number"
            placeholder="activity_number"
            value={this.state.activity_number}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />
          <Typography variant="body1" color="secondary">
            URL: {this.props.details.url}
          </Typography>
          <TextField
            autoFocus
            name="url"
            id="url"
            label="CPE url"
            placeholder="url"
            value={this.state.url}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            Price: {this.props.details.cost}
          </Typography>
          <TextField
            name="cost"
            id="cost"
            label="cost"
            placeholder="cost"
            value={this.state.cost}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Typography variant="body1" color="secondary">
            Activity Type: {this.props.details.activity_type}
          </Typography>
          <FormControl className={this.props.classes.formControl} variant="outlined">
            <Select
              disabled
              value={this.state.activity_type}
              onChange={this.handleChange}
              margin="dense"
              input={
                <OutlinedInput
                  labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
                  name="activity_type"
                  id="activity_type"
                />
              }
            >
              <MenuItem value="" />
              <MenuItem value="120 Certificate Programs">120 Certificate Programs</MenuItem>
              <MenuItem value="171: Live Webinars and Teleseminars">
                171: Live Webinars and Teleseminars
              </MenuItem>
              <MenuItem value="Live Activity:  110 Case Presentation">
                Live Activity: 110 Case Presentation
              </MenuItem>
              <MenuItem value="Live Activity:  130 Exhibits">Live Activity: 130 Exhibits</MenuItem>
              <MenuItem value="Live Activity:  140 Experiential Skill Development">
                Live Activity: 140 Experiential Skill Development
              </MenuItem>
              <MenuItem value="Live Activity:  150 Interactive Workshops">
                Live Activity: 150 Interactive Workshops
              </MenuItem>
              <MenuItem value="Live Activity:  160 Journal Club">
                Live Activity: 160 Journal Club
              </MenuItem>
              <MenuItem value="Live Activity:  170 Lectures / Seminars">
                Live Activity: 170 Lectures / Seminars
              </MenuItem>
              <MenuItem value="Live Activity:  180 Posters">Live Activity: 180 Posters</MenuItem>
              <MenuItem value="Live Activity:  210 Residency and Fellowship Programs">
                Live Activity: 210 Residency and Fellowship Programs
              </MenuItem>
              <MenuItem value="Live Activity:  230 Study Groups">
                Live Activity: 230 Study Groups
              </MenuItem>
              <MenuItem value="Recorded pre-approved CPE Activity:  175 Lectures/Webinars">
                Recorded pre-approved CPE Activity: 175 Lectures/Webinars
              </MenuItem>
              <MenuItem value="Self Study Activity:  700 Audio-Based">
                Self Study Activity: 700 Audio-Based
              </MenuItem>
              <MenuItem value="Self Study Activity:  710 Computer-Based (Offline)">
                Self Study Activity: 710 Computer-Based (Offline)
              </MenuItem>
              <MenuItem value="Self Study Activity:  720 Printed">
                Self Study Activity: 720 Printed
              </MenuItem>
              <MenuItem value="Self Study Activity:  730 Video-Based">
                Self Study Activity: 730 Video-Based
              </MenuItem>
              <MenuItem value="Self Study Activity:  740 Web-Based">
                Self Study Activity: 740 Web-Based
              </MenuItem>
              <MenuItem value="Annual Meeting">Annual Meeting</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="body1" color="secondary">
            Learning Codes: {JSON.stringify(this.props.details.learning_codes)}
          </Typography>
          <TextField
            disabled
            name="learning_codes"
            id="learning_codes"
            label="Learning Codes"
            placeholder="learning_codes"
            value={this.state.learning_codes}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            Performance Indicators: {JSON.stringify(this.props.details.performance_indicators)}
          </Typography>
          <TextField
            disabled
            name="performance_indicators"
            id="performance_indicators"
            label="Performance Indicators"
            placeholder="performance_indicators"
            value={this.state.performance_indicators}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            Start Date: {this.props.details.start_date}
          </Typography>
          <TextField
            disabled
            name="start_date"
            id="start_date"
            label="Start Date"
            placeholder="start_date"
            value={this.state.start_date}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            End Date: {this.props.details.end_date}
          </Typography>
          <TextField
            disabled
            name="end_date"
            id="end_date"
            label="End Date"
            placeholder="end_date"
            value={this.state.end_date}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Typography variant="body1" color="secondary">
            Country: {this.props.details.country}
          </Typography>
          <TextField
            disabled
            name="country"
            id="country"
            label="Country"
            placeholder="country"
            value={this.state.country}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            State: {this.props.details.state}
          </Typography>
          <TextField
            disabled
            name="state"
            id="state"
            label="State"
            placeholder="state"
            value={this.state.state}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            City: {this.props.details.city}
          </Typography>
          <TextField
            disabled
            name="city"
            id="city"
            label="City"
            placeholder="city"
            value={this.state.city}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            Contact: {this.props.details.contact}
          </Typography>
          <TextField
            disabled
            name="contact"
            id="contact"
            label="Contact"
            placeholder="contact"
            value={this.state.contact}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            Provider:{' '}
            {this.props.details.provider[0] === null ? '' : this.props.details.provider[0].name}
          </Typography>
          <TextField
            disabled
            name="provider_id"
            id="provider_id"
            label="provider_id"
            placeholder="provider_id"
            value={this.state.provider_id}
            className={this.props.classes.textField}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />

          <Typography variant="body1" color="secondary">
            Description:
          </Typography>
          <TextField
            disabled
            name="description"
            id="description"
            label="Description"
            placeholder="Description"
            multiline
            className={this.props.classes.textField}
            value={this.state.description}
            onChange={this.handleChange}
            margin="dense"
            variant="outlined"
          />
        </Paper>
        <Button
          color="primary"
          variant="contained"
          className={this.props.classes.button}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
        <br />
        {JSON.stringify(this.props.details)}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapActionsToProps = {
  updateCpe,
};

const styledComponent = withStyles(styles)(CpeEdit);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
