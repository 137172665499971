/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable func-names */

import axios from 'axios';
import auth0Client from '../auth/Auth';

export default function() {
  return axios.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth0Client.getIdToken()}`,
      // "Access-Control-Allow-Origin": "*"
    },
  });
}
