/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import moment from 'moment';

// Icons
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

import CpeDetailsHeader from './CpeDetailsHeader';
import { perIndObj } from '../../_constants/pi';

export type CpeDetailsProps = {
  provider?: any[];
  title?: string;
  activity_type?: string;
  cpe_id?: string;
  cost?: string;
  urlHash?: string;
  description?: string;
  performance_indicators?: any[];
  learning_codes?: any[];
  hours?: string | number;
  cpe_level?: string | number;
  activity_number?: number;
  start_date?: moment.MomentInput;
  end_date?: moment.MomentInput;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
    '@media (max-width: 576px)': {
      margin: theme.spacing(0),
      padding: theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
  },
  descriptionContainer: {
    flex: '0 0 75%',
    flexGrow: 1,
  },
  detailsContainer: {
    flex: '0 0 25%',
    flexGrow: 1,
  },
  heading: {
    padding: theme.spacing(1, 0, 0, 1),
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 0, 0),
  },
  leftPad: {
    padding: theme.spacing(0, 0, 0, 1),
  },
}));

function CpeDetails(props: { details: CpeDetailsProps }) {
  const { details } = props;
  const {
    provider,
    title,
    activity_type,
    cpe_id,
    cost,
    urlHash,
    description,
    performance_indicators,
    learning_codes,
    hours,
    cpe_level,
    activity_number,
    start_date,
    end_date,
  } = details;

  const classes = useStyles();
  const providerObj = provider ? provider[0] : '';

  return (
    <div>
      <CpeDetailsHeader
        title={title || ''}
        provider={providerObj}
        activity_type={activity_type || ''}
        cpe_id={cpe_id}
        cost={cost}
        urlHash={urlHash}
      />
      <div className={classes.container}>
        <div className={classes.descriptionContainer}>
          <div className={classes.heading}>
            <Typography variant="h5" color="secondary">
              Description
            </Typography>
          </div>
          <Paper className={classes.paper}>
            {description && description !== ' ' && (
              <>
                <Typography variant="body1" color="textPrimary">
                  {/* In order to properly display line breaks need to turn the `\n` into <br/> */}
                  {description.split('\n').map((item: string, i: React.Key | null | undefined) => (
                    <span key={i}>
                      {item}
                      <br />
                    </span>
                  ))}
                </Typography>
                <Divider />
              </>
            )}
            {performance_indicators && (
              <div style={{ marginTop: '16px' }}>
                <Typography variant="h6" color="secondary">
                  Performance Indicators
                </Typography>
                {performance_indicators.map((e: string) => (
                  <div key={e}>
                    <Typography variant="body1" color="textPrimary">
                      <strong>{e.slice(1, -1)}:</strong>{' '}
                      {perIndObj[e.slice(1, -1) as keyof typeof perIndObj]}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
            {/* 
            {learning_codes && (
              <div style={{ marginTop: '16px' }}>
                <Typography variant="h6" color="secondary">
                  Learning Codes
                </Typography>
                {learning_codes.map((e: number) => (
                  <div key={e}>
                    <Typography variant="body1" color="textPrimary">
                      <strong>{e}:</strong> {codes[e as keyof typeof codes]}
                    </Typography>
                  </div>
                ))}
              </div>
            )} */}
          </Paper>
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.heading}>
            <Typography variant="h5" color="secondary">
              Details
            </Typography>
          </div>
          <Paper className={classes.paper}>
            <div className={classes.line}>
              <QueryBuilderIcon color="secondary" />
              <Typography variant="body1" color="textSecondary" className={classes.leftPad}>
                CPEU Hours:
              </Typography>
              <Typography variant="body1" color="textPrimary" className={classes.leftPad}>
                {hours}
              </Typography>
            </div>

            {cost && (
              <div className={classes.line}>
                <AttachMoneyIcon color="secondary" />
                <Typography variant="body1" color="textSecondary" className={classes.leftPad}>
                  Price:
                </Typography>
                <Typography variant="body1" color="textPrimary" className={classes.leftPad}>
                  ${cost}
                </Typography>
              </div>
            )}

            <div className={classes.line}>
              <EqualizerIcon color="secondary" />
              <Typography variant="body1" color="textSecondary" className={classes.leftPad}>
                CPE Level:
              </Typography>
              <Typography variant="body1" color="textPrimary" className={classes.leftPad}>
                {cpe_level}
              </Typography>
            </div>

            <div className={classes.line}>
              <WorkOutlineIcon color="secondary" />
              <Typography variant="body1" color="textSecondary" className={classes.leftPad}>
                CDR Number:
              </Typography>
              <Typography variant="body1" color="textPrimary" className={classes.leftPad}>
                {activity_number}
              </Typography>
            </div>

            <Divider />

            {start_date && (
              <div className={classes.line}>
                <EventAvailableIcon color="secondary" />
                <Typography variant="body1" color="textSecondary" className={classes.leftPad}>
                  Starts:
                </Typography>
                <Typography variant="body1" color="textPrimary" className={classes.leftPad}>
                  {moment(start_date).format('MM-DD-YYYY')}
                </Typography>
              </div>
            )}
            {end_date && (
              <div className={classes.line}>
                <EventBusyIcon color="secondary" />
                <Typography variant="body1" color="textSecondary" className={classes.leftPad}>
                  Expires:
                </Typography>
                <Typography variant="body1" color="textPrimary" className={classes.leftPad}>
                  {moment(end_date).format('MM-DD-YYYY')}
                </Typography>
              </div>
            )}
          </Paper>
        </div>
      </div>

      <div className={classes.heading}>
        <Typography variant="h5" color="secondary">
          Links
        </Typography>
      </div>
      <Paper className={classes.paper}>
        {urlHash && (
          <Typography>
            CPEU Link:{' '}
            <a href={`https://link.cpeu.org/${urlHash}`} target="_blank" rel="noopener noreferrer">
              link
            </a>
          </Typography>
        )}
        {/* 
        <Typography>
          CDR Page:{' '}
          <a href={details.cdr_url} target="_blank" rel="noopener noreferrer">
            link
          </a>
        </Typography> */}
        {providerObj.website && (
          <Typography>
            Provider Website:{' '}
            <a href={providerObj.website} target="_blank" rel="noopener noreferrer">
              link
            </a>
          </Typography>
        )}
      </Paper>
    </div>
  );
}

export default CpeDetails;
