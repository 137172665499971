import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import bookmarksReducer from './bookmarksReducer';
import cpeReducer from './cpeReducer';
import completedReducer from './completedReducer';
import fileReducer from './fileReducer';
import filterReducer from './filterReducer';
import lpReducer from './lpReducer';
import paymentReducer from './paymentReducer';
import providerReducer from './providerReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';

const userPersistConfig = {
  key: 'userReducer',
  storage,
  blacklist: [
    'gettingUser',
    'getUserSuccess',
    'loggingIn',
    'loggingInSuccess',
    'loggingInFailure',
    'firstLogin',
    'auth0signedInSuccess',
    'auth0signedInFailure',
    'auth0profile',
    'auth0gettingProfile',
    'auth0idToken',
    'auth0Tried',
    'auth0SigningIn',
    'user',
    'error',
  ],
};

const rootReducer = combineReducers({
  bookmarksReducer,
  cpeReducer,
  completedReducer,
  fileReducer,
  filterReducer,
  lpReducer,
  paymentReducer,
  providerReducer,
  uiReducer,
  userReducer: persistReducer(userPersistConfig, userReducer),
});

export default rootReducer;
