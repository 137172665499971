/* eslint-disable camelcase */
import axiosWithAuth from '../utils/axiosWithAuth';

import { showSnackbar } from './uiActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------BOOKMARKS------->

export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS';
export const SUBMIT_FEEDBACK_FAILURE = 'SUBMIT_FEEDBACK_FAILURE';
export const NEW_USER = 'NEW_USER';
export const NEW_ERROR = 'NEW_ERROR';

export const submitFeedback = (page, feedback) => (dispatch) => {
  dispatch({ type: SUBMIT_FEEDBACK });
  axiosWithAuth()
    .post(`${url}/api/slack/feedback/`, { page, feedback })
    .then(() => {
      // console.log("SUBMIT_FEEDBACK_SUCCESS response", res);
      dispatch(showSnackbar('Thank you for the feedback!', 'success'));
      dispatch({ type: SUBMIT_FEEDBACK_SUCCESS });
    })
    .catch((err) => {
      // console.log("SUBMIT_FEEDBACK_FAILURE err", err);
      dispatch(
        showSnackbar(
          'Submitting feedback failed. Please try again or contact us if this keeps happening. (hello@cpeu.org)',
          'error'
        )
      );
      dispatch({ type: SUBMIT_FEEDBACK_FAILURE, payload: err.message });
    });
};

export const newUserWebhook = () => (dispatch) => {
  dispatch({ type: NEW_USER });
  axiosWithAuth()
    .post(`${url}/api/slack/new-user/`)
    .catch();
};

export const errorWebhook = (type, error) => (dispatch) => {
  dispatch({ type: NEW_ERROR });
  axiosWithAuth()
    .post(`${url}/api/slack/error/`, { type, error })
    .catch();
};
