// <------SNACKBAR------->

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const showSnackbar = (message, severity) => (dispatch) => {
  // console.log('******showSuccessSnackbar******');
  const snackbar = { message, severity };
  dispatch({ type: SNACKBAR_OPEN, payload: snackbar });
};

export const clearSnackbar = () => (dispatch) => {
  // console.log('******clearSnackbar******');
  dispatch({ type: SNACKBAR_CLEAR });
};
