/* eslint-disable  react/jsx-one-expression-per-line */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AccountCircle from '@material-ui/icons/AccountCircle';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import School from '@material-ui/icons/School';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      flexDirection: 'column',
      borderRadius: '0px',
    },
  },
  content: {
    maxWidth: '1000px',
    margin: 'auto',
    display: 'flex',
  },
  heading: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
  icon: {
    margin: theme.spacing(1),
  },
});

class WelcomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // providers: [],
      // getProvidersSuccess: false,
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <Helmet>
          <title>Welcome | CPEU.org</title>
        </Helmet>
        <div className={classes.root}>
          <div className={classes.heading}>
            <Typography variant="h2" color="secondary">
              Welcome to CPEU.org!
            </Typography>
          </div>
          <Paper className={classes.paper}>
            <Typography>
              We are so excited you are here, and cannot wait for you to experience the time and
              energy savings that comes with simplifying the CPEU process! We also are thrilled for
              the peace of mind that you’ll have when you know you can go anytime to CPEU.org and
              find exactly the continuing education you need and have a place to keep it all
              organized and stored neatly.
            </Typography>
            <br />
            <Typography>A fast overview:</Typography>
            <div className={classes.row}>
              <School className={classes.icon} />
              <Typography>
                <strong>CPEUs.</strong> The search. You’ll find thousands of available CPEUs. If you
                have entered your learning plan, we will by default only show you CPEUs that are
                relevant to it. You can turn off this feature any time if you’d like.
              </Typography>
            </div>
            <div className={classes.row}>
              <BookmarksIcon className={classes.icon} />
              <Typography>
                <strong>Bookmarks.</strong> The list of your favorites. Helps you save and reference
                CPEUs for later.
              </Typography>
            </div>
            <div className={classes.row}>
              <EmojiEventsIcon className={classes.icon} />

              <Typography>
                <strong>Completed.</strong> The list of your finished CPEUs. Helps you keep track of
                what you have done, and makes it easy to transfer the info all at once into your CDR
                activity log.
              </Typography>
            </div>
            <div className={classes.row}>
              <AccountCircle className={classes.icon} />
              <Typography>
                <strong>Profile.</strong> Will let you update your learning plan, and personal
                information.
              </Typography>
            </div>
            <br />
            <Typography>
              If you ever have any questions or problems, we are more than happy to help! We want
              this to be a fun and easy experience for you. You can reach out anytime at{' '}
              <a href="mailto:hello@cpeu.org">hello@cpeu.org</a>.
            </Typography>
            <br />
            <Typography>Thanks, and all the best,</Typography>
            <Typography color="primary">Allison Vinyard, MS, RDN</Typography>

            <Button
              onClick={() => this.props.history.push('/')}
              color="secondary"
              variant="contained"
            >
              Lets Go!
            </Button>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(WelcomeView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
