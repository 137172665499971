/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { FixedSizeList } from 'react-window';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// Icons
import Description from '@material-ui/icons/Description';
import OndemandVideo from '@material-ui/icons/OndemandVideo';
import Update from '@material-ui/icons/Update';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Store from '@material-ui/icons/Store';
import Work from '@material-ui/icons/Work';
import Extension from '@material-ui/icons/Extension';
import Create from '@material-ui/icons/Create';
import Person from '@material-ui/icons/Person';
import InsertPhoto from '@material-ui/icons/InsertPhoto';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import Group from '@material-ui/icons/Group';
import Devices from '@material-ui/icons/Devices';
import HeadsetIcon from '@material-ui/icons/Headset';
import Computer from '@material-ui/icons/Computer';
import Book from '@material-ui/icons/Book';
import Videocam from '@material-ui/icons/Videocam';
import Language from '@material-ui/icons/Language';

// Actions
import { getFilteredActivityType, getFilteredCpes } from '../../actions';

const styles = (theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    // width: "450px",
    // width: "100%",
    // padding: theme.spacing(1),
    overflow: 'auto',
    height: '80vh',
    width: '500px',
    boxShadow: 'none',
    // WebkitOverflowScrolling: 'touch',
    '@media (max-width: 576px)': {
      width: '90vw',
      height: '70vh',
    },
  },
  cardHeader: {
    padding: theme.spacing(2),
    height: '64px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'inherit',
    height: '72px',
  },
  list: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  listRow: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0),
    WebkitOverflowScrolling: 'touch',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
});

let activityTypesArray = [];
class ActivityTypeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.activityTypeSelectedProps,
    };
  }

  createActivityFilterData = memoize((activityTypes, checked) => ({
    activityTypes,
    checked,
  }));

  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  renderRow = ({ index, style, data }) => {
    const { activityTypes, checked } = data;
    const at = activityTypes[index];

    const results = (
      <>
        <ListItem
          alignItems="flex-start"
          dense
          button
          onClick={this.handleToggle(at[0])}
          className={this.props.classes.listRow}
          style={style}
          key={at[0]}
        >
          <ListItemAvatar>
            <Avatar className={this.props.classes.avatar} style={{ backgroundColor: at[1] }}>
              {at[2]}
            </Avatar>
          </ListItemAvatar>

          <ListItemText
            disableTypography
            primary={
              <Typography color="textPrimary" variant="body2">
                {at[3]}
              </Typography>
            }
          />
          <Checkbox checked={checked.indexOf(at[0]) !== -1} tabIndex={-1} color="primary" />
        </ListItem>
        {/* <Divider /> */}
      </>
    );
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{results}</>;
  };

  saveActivityType = (target) => {
    // console.log(this.state.checked);
    this.props.handleActivityTypeMenu(target);

    this.props.getFilteredActivityType(this.state.checked);
    this.props.getFilteredCpes();
    this.props.history.push('/cpes/1');
  };

  // Function to calculate the VH so the list can be properly sized
  // eslint-disable-next-line class-methods-use-this
  vh = (v) => {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
  };

  render() {
    const activityTypeData = this.createActivityFilterData(activityTypesArray, this.state.checked);
    const content = (
      <List key={0} dense className={this.props.classes.list}>
        <FixedSizeList
          // height={this.vh(80) - 112 - 8}
          height={this.vh(80) - 64}
          itemSize={50}
          itemCount={activityTypesArray.length}
          overscanCount={3}
          itemData={activityTypeData}
        >
          {this.renderRow}
        </FixedSizeList>
      </List>
    );
    return (
      <>
        <Card className={this.props.classes.card}>
          <div className={this.props.classes.cardHeader}>
            <Typography variant="h6" color="secondary">
              Activity Type
            </Typography>
          </div>
          <div className={this.props.classes.list}>{content}</div>
        </Card>
        <div className={this.props.classes.buttons}>
          <Button onClick={this.props.handleActivityTypeMenu} color="default">
            Close
          </Button>
          <Button onClick={this.saveActivityType} color="primary">
            Save
          </Button>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityTypeSelected: state.filterReducer.activityTypeSelected,
});

const mapActionsToProps = {
  getFilteredActivityType,
  getFilteredCpes,
};

const styledComponent = withStyles(styles)(ActivityTypeFilter);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);

activityTypesArray = [
  [120, '#f0d6b3', <Description />, '120: Certificate Programs'],
  [110, '#7ea9b8', <LibraryBooks />, 'Live Activity: 110 Case Presentation'],
  [171, '#f7a687', <OndemandVideo />, '171: Live Webinars and Teleseminars'],
  [130, '#b888c4', <Store />, 'Live Activity: 130 Exhibits'],
  [140, '#afbfbb', <Work />, 'Live Activity: 140 Experiential Skill Development'],
  [150, '#e0987d', <Extension />, 'Live Activity: 150 Interactive Workshops'],
  [160, '#b8b582', <Create />, 'Live Activity: 160 Journal Club'],
  [170, '#fce795', <Person />, 'Live Activity: 170 Lectures / Seminars'],
  [180, '#4eb6ac', <InsertPhoto />, 'Live Activity: 180 Posters'],
  [210, '#a9bc92', <LocalLibrary />, 'Live Activity: 210 Residency and Fellowship Programs'],
  [230, '#d6867c', <Group />, 'Live Activity: 230 Study Groups'],
  [175, '#87ade0', <Devices />, 'Recorded pre-approved CPE Activity: 175 Lectures/Webinars'],
  [700, '#cf8c37', <HeadsetIcon />, 'Self Study Activity: 700 Audio-Based'],
  [710, '#a8e2f0', <Computer />, 'Self Study Activity: 710 Computer-Based (Offline)'],
  [720, '#d5b4b3', <Book />, 'Self Study Activity: 720 Printed'],
  [730, '#a6dc9c', <Videocam />, 'Self Study Activity: 730 Video-Based'],
  [740, '#6f6087', <Language />, 'Self Study Activity: 740 Web-Based'],
  [999, '#e0969b', <Update />, 'Annual Meeting'],
];
