/* eslint-disable no-console */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { connect } from 'react-redux';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Nouislider from 'nouislider-react';

import {
  getFilteredLevel,
  getFilteredCpes,
  getFilteredExpired,
  getFilteredHours,
} from '../../actions';
import './slider.css';

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    // height: '112px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
    // width: '500px',
    overflow: 'auto',
    padding: theme.spacing(1),
    boxShadow: 'none',
    WebkitOverflowScrolling: 'touch',

    '@media (max-width: 576px)': {
      width: '100%',
      height: '70vh',
    },
  },
  cardContainer: {
    padding: theme.spacing(1),
    height:
      (80 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) / 100 -
      (112 - 8),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'inherit',
    height: '72px',
  },
  hours: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursField: {
    width: '120px',
  },
  list: {
    padding: '0',
    WebkitOverflowScrolling: 'touch',
  },
});

class MoreFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.levelSelectedProps,
      expired: this.props.includeExpiredFilterProps,

      minHours: 0,
      maxHours: 250,
    };
  }

  componentDidMount() {
    this.setState({
      minHours: this.props.hoursSelected[0],
      maxHours: this.props.hoursSelected[1],
    });
  }

  handleSwitchChange = (event) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  handleHoursChange = (event) => {
    this.setState({ [event.target.name]: event.target.value || '' });
  };

  onSetHoursSlider = (value) => {
    this.setState({
      minHours: value[0],
      maxHours: value[1],
    });
  };

  submit = (target) => {
    // console.log(this.state.checked);
    this.props.handleMoreFiltersMenu(target);

    this.props.getFilteredHours(this.state.minHours, this.state.maxHours);
    this.props.getFilteredLevel(this.state.checked);
    this.props.getFilteredExpired(this.state.expired);
    this.props.getFilteredCpes();
    this.props.history.push('/cpes/1');
  };

  // vh = (v) => {
  //   const h = Math.max(this.document.documentElement.clientHeight, window.innerHeight || 0);
  //   return (v * h) / 100;
  // };

  render() {
    return (
      <>
        <Card className={this.props.classes.card}>
          <div className={this.props.classes.header}>
            <Typography variant="h6" color="secondary">
              More Filters
            </Typography>
          </div>
          <div className={this.props.classes.cardContainer}>
            <Typography variant="h6" color="secondary">
              Hours
            </Typography>
            <Nouislider
              start={[this.state.minHours, this.state.maxHours]}
              range={{ min: this.props.hoursRange[0], max: this.props.hoursRange[1] }}
              step={1}
              tooltips
              connect
              onSet={this.onSetHoursSlider}
            />
            <div className={this.props.classes.hours}>
              <TextField
                name="minHours"
                id="minHours"
                label="Min Hours"
                placeholder="0"
                value={parseInt(this.state.minHours, 10) || ''}
                className={this.props.classes.hoursField}
                onChange={this.handleHoursChange}
                margin="dense"
                variant="outlined"
                type="number"
              />
              <TextField
                name="maxHours"
                id="maxHours"
                label="Max Hours"
                placeholder="0"
                value={parseInt(this.state.maxHours, 10) || ''}
                className={this.props.classes.hoursField}
                onChange={this.handleHoursChange}
                margin="dense"
                variant="outlined"
                type="number"
              />
            </div>
            <Divider />

            <Typography variant="h6" color="secondary">
              CPE Level
            </Typography>

            <List key={1} dense>
              <ListItem
                alignItems="flex-start"
                dense
                onClick={this.handleToggle(1)}
                className={this.props.classes.list}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography color="textPrimary" variant="body2">
                      Level 1
                    </Typography>
                  }
                />
                <Switch
                  edge="end"
                  checked={this.state.checked.indexOf(1) !== -1}
                  name="1"
                  color="primary"
                  inputProps={{ 'aria-label': 'Level 1 checkbox' }}
                />
              </ListItem>
              <Divider />
            </List>

            <List key={2} dense>
              <ListItem
                alignItems="flex-start"
                dense
                onClick={this.handleToggle(2)}
                className={this.props.classes.list}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography color="textPrimary" variant="body2">
                      Level 2
                    </Typography>
                  }
                />
                <Switch
                  edge="end"
                  checked={this.state.checked.indexOf(2) !== -1}
                  name="2"
                  color="primary"
                  inputProps={{ 'aria-label': 'Level 2 checkbox' }}
                />
              </ListItem>
              <Divider />
            </List>

            <List key={3} dense>
              <ListItem
                alignItems="flex-start"
                dense
                onClick={this.handleToggle(3)}
                className={this.props.classes.list}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography color="textPrimary" variant="body2">
                      Level 3
                    </Typography>
                  }
                />
                <Switch
                  edge="end"
                  checked={this.state.checked.indexOf(3) !== -1}
                  name="3"
                  color="primary"
                  inputProps={{ 'aria-label': 'Level 3 checkbox' }}
                />
              </ListItem>
              <Divider />
            </List>

            <Typography variant="h6" color="secondary">
              Dates:
            </Typography>
            <List key="expired" dense>
              <ListItem alignItems="flex-start" dense className={this.props.classes.list}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography color="textPrimary" variant="body2">
                      Include Expired Courses:
                    </Typography>
                  }
                />
                <Switch
                  edge="end"
                  checked={this.state.expired}
                  onChange={this.handleSwitchChange}
                  name="expired"
                  color="primary"
                  inputProps={{ 'aria-label': 'expired checkbox' }}
                />
              </ListItem>
              <Divider />
            </List>
          </div>
        </Card>
        <div className={this.props.classes.buttons}>
          <Button onClick={this.props.handleMoreFiltersMenu} color="default">
            Close
          </Button>
          <Button onClick={this.submit} color="primary">
            Save
          </Button>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  hoursRange: state.filterReducer.hoursRange,
  hoursSelected: state.filterReducer.hoursSelected,
  levelSelected: state.filterReducer.levelSelected,
  includeExpiredFilter: state.filterReducer.includeExpiredFilter,
});

const mapActionsToProps = {
  getFilteredCpes,
  getFilteredExpired,
  getFilteredLevel,
  getFilteredHours,
};

const styledComponent = withStyles(styles)(MoreFilters);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
