import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

// import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import MomentUtils from '@date-io/moment';
// import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Info from '../util/Info';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    boxShadow: theme.shadows[0],
    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
    },
  },
  heading: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: theme.shadows[0],
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  stateLabel: {
    backgroundColor: 'white',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '250px',
  },
});

class OnboardingProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      // cycle: '',
      // cycle_begin_date: null,
      // cycle_end_date: null,
      // license: '',
      // licenseHelperText: '',
      // licenseError: false,
      rdstate: '',
    };
  }

  handleChange = (e) => {
    const change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
    // console.log(e, this.state);
  };

  // handleStartDateChange = (cycleStartDate) => {
  //   const date = cycleStartDate ? moment(cycleStartDate).format('YYYY-MM-DD') : null;
  //   this.setState({ cycleStartDate: date });
  // };

  // handleEndDateChange = (cycleEndDate) => {
  //   const date = cycleEndDate ? moment(cycleEndDate).format('YYYY-MM-DD') : null;
  //   this.setState({ cycleEndDate: date });
  // };

  // handleLicenseChange = (event) => {
  //   if (event.currentTarget.value.length > 0) {
  //     this.setState({ license: event.target.value, licenseHelperText: '', licenseError: false });
  //   } else {
  //     this.setState({
  //       license: event.target.value,
  //       licenseHelperText: 'Please enter your license',
  //       licenseError: true,
  //     });
  //   }
  // };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h2" color="secondary">
          Profile
        </Typography>
        <Paper className={classes.paper}>
          <div className={classes.heading}>
            <Typography variant="h5" color="secondary">
              Please answer a few questions to get set up!
            </Typography>
          </div>
          {/* <Typography variant="h6" color="primary">
            Choose your license type
          </Typography>
          <TextField
            autoFocus
            // required
            name="license"
            id="license"
            // label="Required"
            placeholder="What is this list for? What kind of people are in this list? Is there an overall theme?"
            multiline
            rows="4"
            // className={this.props.classes.textField}
            value={this.state.license}
            onChange={this.handleLicenseChange}
            // onKeyPress={this.props.handleNextKeypress}
            inputProps={{ maxLength: 80 }}
            margin="normal"
            variant="outlined"
            helperText={this.state.licenseHelperText}
            error={this.state.licenseError}
          /> */}
          <div className={classes.titleRow}>
            <Typography variant="h6" color="secondary">
              State
            </Typography>
            <Info text="Since many states have different rules, we use your state to help customize your experience." />
          </div>
          <Typography variant="subtitle1" color="textSecondary">
            What state do you live in?
          </Typography>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="state-select" className={classes.stateLabel}>
              Registration State
            </InputLabel>
            <Select
              name="rdstate"
              value={this.state.rdstate}
              onChange={this.handleChange}
              // margin="dense"
              // autoWidth
              // label="State"
              // labelWidth='100'
              input={<OutlinedInput name="Registration State" id="state-select" />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="AL">Alabama</MenuItem>
              <MenuItem value="AK">Alaska</MenuItem>
              <MenuItem value="AZ">Arizona</MenuItem>
              <MenuItem value="AR">Arkansas</MenuItem>
              <MenuItem value="CA">California</MenuItem>
              <MenuItem value="CO">Colorado</MenuItem>
              <MenuItem value="CT">Connecticut</MenuItem>
              <MenuItem value="DE">Delaware</MenuItem>
              <MenuItem value="FL">Florida</MenuItem>
              <MenuItem value="GA">Georgia</MenuItem>
              <MenuItem value="HI">Hawaii</MenuItem>
              <MenuItem value="ID">Idaho</MenuItem>
              <MenuItem value="IL">Illinois</MenuItem>
              <MenuItem value="IN">Indiana</MenuItem>
              <MenuItem value="IA">Iowa</MenuItem>
              <MenuItem value="KS">Kansas</MenuItem>
              <MenuItem value="KY">Kentucky</MenuItem>
              <MenuItem value="LA">Louisiana</MenuItem>
              <MenuItem value="ME">Maine</MenuItem>
              <MenuItem value="MD">Maryland</MenuItem>
              <MenuItem value="MA">Massachusetts</MenuItem>
              <MenuItem value="MI">Michigan</MenuItem>
              <MenuItem value="MN">Minnesota</MenuItem>
              <MenuItem value="MS">Mississippi</MenuItem>
              <MenuItem value="MO">Missouri</MenuItem>
              <MenuItem value="MT">Montana</MenuItem>
              <MenuItem value="NE">Nebraska</MenuItem>
              <MenuItem value="NV">Nevada</MenuItem>
              <MenuItem value="NH">New Hampshire</MenuItem>
              <MenuItem value="NJ">New Jersey</MenuItem>
              <MenuItem value="NM">New Mexico</MenuItem>
              <MenuItem value="NY">New York</MenuItem>
              <MenuItem value="NC">North Carolina</MenuItem>
              <MenuItem value="ND">North Dakota</MenuItem>
              <MenuItem value="OH">Ohio</MenuItem>
              <MenuItem value="OK">Oklahoma</MenuItem>
              <MenuItem value="OR">Oregon</MenuItem>
              <MenuItem value="PA">Pennsylvania</MenuItem>
              <MenuItem value="RI">Rhode Island</MenuItem>
              <MenuItem value="SC">South Carolina</MenuItem>
              <MenuItem value="SD">South Dakota</MenuItem>
              <MenuItem value="TN">Tennessee</MenuItem>
              <MenuItem value="TX">Texas</MenuItem>
              <MenuItem value="UT">Utah</MenuItem>
              <MenuItem value="VT">Vermont</MenuItem>
              <MenuItem value="VA">Virginia</MenuItem>
              <MenuItem value="WA">Washington</MenuItem>
              <MenuItem value="WV">West Virginia</MenuItem>
              <MenuItem value="WI">Wisconsin</MenuItem>
              <MenuItem value="WY">Wyoming</MenuItem>
            </Select>
          </FormControl>
          {/* <Typography variant="h6" color="primary">
            Credentials
          </Typography>
          <Typography variant="h6" color="primary">
            Certification date
          </Typography>
          <Typography variant="h6" color="primary">
            Licensed
          </Typography>
          <Divider /> */}
          <div className={classes.titleRow}>
            <Typography variant="h6" color="secondary">
              (Re)certification Cycle
            </Typography>
            <Info text="We use this to make sure your CPEUs are matched to the correct cycle." />
          </div>
          <Typography variant="subtitle1" color="textSecondary">
            What years are your recertification cycle?
          </Typography>
          <FormControl component="fieldset">
            {/* { console.log("profile", this.props.cycle)} */}
            {/* <FormLabel component="legend">What years are your cycle?:</FormLabel> */}
            <RadioGroup
              aria-label="cycle"
              name="cycle"
              value={this.props.cycle}
              onChange={(e) => this.props.handleCycleChange(e.target.value)}
            >
              <FormControlLabel value="2" control={<Radio />} label="June 1, 2015 - May 31, 2020" />
              <FormControlLabel value="3" control={<Radio />} label="June 1, 2016 - May 31, 2021" />
              <FormControlLabel value="4" control={<Radio />} label="June 1, 2017 - May 31, 2022" />
              <FormControlLabel value="5" control={<Radio />} label="June 1, 2018 - May 31, 2023" />
              <FormControlLabel value="6" control={<Radio />} label="June 1, 2019 - May 31, 2024" />
              <FormControlLabel value="7" control={<Radio />} label="June 1, 2020 - May 31, 2025" />
              <FormControlLabel value="0" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
          {this.props.cycle === '0' ? (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                clearable
                // showTodayButton
                size="small"
                margin="dense"
                label="Start Date"
                inputVariant="outlined"
                placeholder="MM/DD/YYYY"
                format="MM/DD/YYYY"
                value={this.props.cycle_begin_date}
                onChange={this.props.handleBeginDateChange}

                // onChange={(cycleStartDate) => this.handleDateChange(cycleStartDate)}
                // onChange={(cycleStartDate) => this.setState({ cycleStartDate })}
                // className={classes.date}
              />
              <DatePicker
                autoOk
                clearable
                // showTodayButton
                size="small"
                margin="dense"
                label="End Date"
                inputVariant="outlined"
                placeholder="MM/DD/YYYY"
                format="MM/DD/YYYY"
                value={this.props.cycle_end_date}
                // onChange={(cycleEndDate) => this.handleDateChange(cycleEndDate)}
                onChange={this.props.handleEndDateChange}
                // onChange={(cycleEndDate) => this.setState({ cycleEndDate })}
                // className={classes.date}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <> </>
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(OnboardingProfile);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
// export default OnboardingProfile;
