/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/function-component-definition */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-alert */

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
// import { Redirect } from 'react-router-dom';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// import moment from 'moment';

import Coupon from './Coupon';

import './CardSectionStyles.css';

import {
  checkCoupon,
  createSubscriptionAction,
  retryInvoiceWithNewPaymentMethodAction,
  showSnackbar,
  subscriptionCompleteAction,
  updateCustomer,
  updateUser,
} from '../../actions';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    boxShadow: theme.shadows[0],
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
  firstName: {
    width: '100%',
    marginRight: theme.spacing(3),
  },
  lastName: {
    width: '100%',
  },
  couponField: {
    width: '100%',
    maxWidth: '450px',
  },
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = (props) => {
  // const classes = useStyles();
  const {
    productSelected,
    stripe_customer_id,
    classes,
    couponValid,
    couponAmount,
    couponPercent,
    authFirstName,
    authLastName,
    authEmail,
  } = props;
  // console.log('CheckoutForm props', props);
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const [accountInformation, setAccountInformation] = useState(null);
  const [coupon, setCoupon] = useState('');
  const [first_name, setFirstName] = useState(authFirstName);
  const [last_name, setLastName] = useState(authLastName);
  const [email, setEmail] = useState(authEmail);

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    // subscription = subscription.data;
    // console.log('handlePaymentThatRequiresCustomerAction');

    // console.log('handlePaymentThatRequiresCustomerAction subscription', subscription);
    // const time = moment.unix(subscription.current_period_start).format('YYYY-MM-DD');
    // const time2 = moment.unix(subscription.current_period_end).format('YYYY-MM-DD');
    // console.log('moment current_period_start', time);
    // console.log('moment current_period_end', time2);
    // console.log('handlePaymentThatRequiresCustomerAction invoice', invoice);
    // console.log('handlePaymentThatRequiresCustomerAction priceId', priceId);
    // console.log('handlePaymentThatRequiresCustomerAction paymentMethodId', paymentMethodId);
    // console.log('handlePaymentThatRequiresCustomerAction isRetry', isRetry);

    if (subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;
    // let paymentIntent = {};
    // if (invoice) {
    //   paymentIntent = invoice.payment_intent;
    // } else {
    //   paymentIntent = subscription.latest_invoice.payment_intent;
    // }

    // console.log('handlePaymentThatRequiresCustomerAction paymentIntent', paymentIntent);

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            // console.log(
            //   'handlePaymentThatRequiresCustomerAction confirmCardPayment result.error',
            //   result.error
            // );
            setSubscribing(false);
            // alert(result.error.message);
            const cardError = result.error;
            throw cardError;
          } else if (result.paymentIntent.status === 'succeeded') {
            // console.log('handlePaymentThatRequiresCustomerAction confirmCardPayment else if result', result);
            // console.log('handlePaymentThatRequiresCustomerAction confirmCardPayment else if priceId', priceId);
            // console.log(
            //   'handlePaymentThatRequiresCustomerAction confirmCardPayment else if subscription',
            //   subscription
            // );
            // console.log('handlePaymentThatRequiresCustomerAction confirmCardPayment else if invoice', invoice);
            // console.log(
            //   'handlePaymentThatRequiresCustomerAction confirmCardPayment else if paymentMethodId',
            //   paymentMethodId
            // );

            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.payment_succeeded. This webhook endpoint
            // returns an Invoice.
            return {
              priceId,
              subscription,
              invoice,
              paymentMethodId,
            };
          }
        });
    }
    // No customer action needed
    return { subscription, priceId, paymentMethodId };
  }

  function handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId }) {
    // console.log('handleRequiresPaymentMethod');
    // console.log('handleRequiresPaymentMethod subscription', subscription);
    // console.log('handleRequiresPaymentMethod paymentMethodId', paymentMethodId);
    // console.log('handleRequiresPaymentMethod priceId', priceId);
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }
    if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error(
        'Your card was declined. Please choose a different payment method and try again.'
      );
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    const newInvoice = {
      customerId: stripe_customer_id,
      paymentMethodId,
      invoiceId,
    };
    return (
      props
        .retryInvoiceWithNewPaymentMethodAction(newInvoice)
        .then((response) => {
          // console.log('retryInvoiceWithNewPaymentMethodAction response', response);
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          // console.log('retryInvoiceWithNewPaymentMethodAction result', result);

          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          // console.log('retryInvoiceWithNewPaymentMethodAction result2', result);
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId,
            priceId: productSelected.priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // console.log(error);
          // An error has happened. Display the failure to the user here.
          setSubscribing(false);
          // setErrorToDisplay(error && error.error && error.error.decline_code);
          props.showSnackbar(`Error: ${error.message}`, 'error');
        })
    );
  }

  function onSubscriptionComplete(result) {
    // console.log('onSubscriptionComplete result', result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();

    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      localStorage.removeItem('latestInvoiceId');
      localStorage.removeItem('latestInvoicePaymentIntentStatus');
      // localStorage.clear();
    }
    props.subscriptionCompleteAction(result);
    setAccountInformation(result);
  }

  function createSubscription({ paymentMethodId }) {
    // const priceId = productSelected.name.toUpperCase();

    const subscription = {
      customerId: stripe_customer_id,
      paymentMethodId,
      priceId: productSelected.priceId,
    };
    // If valid coupon, add it to the subscription
    if (couponValid) {
      subscription.coupon = coupon;
    }
    // console.log('createSubscription subscription', subscription);
    return (
      props
        .createSubscriptionAction(subscription)
        .then((createSubscriptionActionResponse) => {
          // If there was an error creating the subscription
          if (createSubscriptionActionResponse instanceof Error) {
            throw createSubscriptionActionResponse;
          }
          // console.log(
          //   'createSubscriptionActionResponse response',
          //   createSubscriptionActionResponse
          // );
          return createSubscriptionActionResponse;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          // console.log('createSubscription result', result);
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            // console.log('createSubscription alert result.error', result.error);
            alert(result);
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the additional details we need.
        .then((result) => {
          // console.log('createSubscription result2', result);

          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId,
            priceId: productSelected.name,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // console.log('!!!createSubscription error', error);
          props.showSnackbar(`Error: ${error.message}`, 'error');
          setSubscribing(false);
        })
    );
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    // console.log('handleSubmit');
    if (!stripe || !elements || first_name.length === 0 || last_name.length === 0) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setSubscribing(true);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    );

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      // console.log('[error]', error);
      setSubscribing(false);
      props.showSnackbar(`Error: ${error.message}`, 'error');
      // alert(error.message);
    } else {
      props.updateUser({ first_name, last_name, email }).then(() => {
        props.updateCustomer({ first_name, last_name, email });
      });
      // console.log('[PaymentMethod]', paymentMethod);
      // createSubscription({ paymentMethodId: paymentMethod.id });

      // console.log('[PaymentMethod]', paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem('latestInvoiceId');
        retryInvoiceWithNewPaymentMethod({
          paymentMethodId,
          invoiceId,
        });
      } else {
        // Create the subscription
        createSubscription({
          paymentMethodId,
        });
      }
    }
  };

  const handleCouponChange = (event) => {
    setCoupon(event.currentTarget.value);
  };

  const handleCouponSubmit = () => {
    // console.log('coupon submitted');
    props.checkCoupon(coupon).then((couponGood) => {
      // If the coupon is bad, clear it from state
      if (!couponGood) {
        setCoupon('');
      }
    });
  };

  // if they successfully subscribe. (This should never display)
  if (accountInformation) {
    return (
      <Typography variant="subtitle2" color="textSecondary">
        Thank you for subscribing!
      </Typography>
    );
  }
  return (
    <div id="payment-form">
      <div>
        <Typography variant="subtitle1" color="textPrimary">
          Enter your card details. <br />
          Your subscription will start now.
        </Typography>
        {couponValid ? (
          <>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ textDecoration: 'line-through' }}
            >
              → Total due now ${(productSelected.due / 100).toFixed(2)}
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              → Total due now{' '}
              <b>
                $
                {couponAmount
                  ? ((productSelected.due - couponAmount) / 100).toFixed(2)
                  : ((productSelected.due * ((100 - couponPercent) / 100)) / 100).toFixed(2)}
              </b>
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle2" color="textSecondary">
            → Total due now <b>${(productSelected.due / 100).toFixed(2)}</b>
          </Typography>
        )}

        <Typography variant="subtitle2" color="textSecondary">
          → Subscribing to <b>{productSelected.name}</b> <br />
        </Typography>

        <Typography variant="h6" color="primary">
          Please enter your full name
        </Typography>
        <div className={classes.nameRow}>
          <TextField
            autoFocus
            required
            name="first_name"
            id="first_name"
            label="First Name"
            placeholder="First name"
            className={classes.firstName}
            value={first_name}
            // onChange={props.handleNameChange}
            onChange={(e) => setFirstName(e.target.value)}
            // onKeyPress={props.handleNextKeypress}
            margin="normal"
            variant="outlined"
            // inputProps={{ maxLength: 50 }}
            // helperText={props.nameHelperText}
            // error={props.nameError}
          />
          <TextField
            autoFocus
            required
            name="last_name"
            id="last_name"
            label="Last Name"
            placeholder="Last name"
            className={classes.lastName}
            value={last_name}
            // onChange={props.handleNameChange}
            onChange={(e) => setLastName(e.target.value)}
            // onKeyPress={props.handleNextKeypress}
            margin="normal"
            variant="outlined"
            // inputProps={{ maxLength: 50 }}
            // helperText={props.nameHelperText}
            // error={props.nameError}
          />
        </div>
        <TextField
          autoFocus
          required
          name="email"
          id="email"
          label="Email Address"
          placeholder="Email Address"
          className={classes.lastName}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          variant="outlined"
        />
        <div className={classes.couponField}>
          <Coupon
            required
            title="coupon"
            description="Do you have a coupon code?"
            value={coupon}
            label="Coupon"
            // placeholder={'hello@cpeu.org'}
            handleCouponChange={handleCouponChange}
            handleCouponSubmit={handleCouponSubmit}
          />
        </div>
        <div>
          <form id="payment-form" onSubmit={handleSubmit}>
            <div>
              <div>
                <Typography variant="h6" color="primary">
                  Card
                </Typography>
                <div
                  // className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                  id="card-element"
                >
                  {/* Styled by CSS */}
                  <CardElement />
                </div>
                <div id="card-element-errors" role="alert"></div>
              </div>
            </div>
            <Button
              disabled={first_name.length === 0 || last_name.length === 0 || email.length === 0}
              onClick={(e) => handleSubmit(e)}
              variant="contained"
              color="primary"
              type="submit"
            >
              {subscribing ? 'Subscribing...' : 'Subscribe'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

// export default PaymentForm;

// export default withStyles(styles)(PaymentForm);

const mapStateToProps = (state) => ({
  couponValid: state.paymentReducer.couponValid,
  couponAmount: state.paymentReducer.couponAmount,
  couponPercent: state.paymentReducer.couponPercent,
  stripe_customer_id: state.userReducer.user.stripe_customer_id,
  authFirstName: state.userReducer.user.first_name,
  authLastName: state.userReducer.user.last_name,
  authEmail: state.userReducer.user.email,
});

const mapActionsToProps = {
  checkCoupon,
  createSubscriptionAction,
  retryInvoiceWithNewPaymentMethodAction,
  showSnackbar,
  subscriptionCompleteAction,
  updateCustomer,
  updateUser,
};

const styledComponent = withStyles(styles)(PaymentForm);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
