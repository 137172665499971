/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import { connect } from 'react-redux';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import FeedbackIcon from '@material-ui/icons/Feedback';
import { submitFeedback } from '../../actions';

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  root: {
    margin: theme.spacing(1, 0, 1, 1),
  },
  text: {
    cursor: 'text !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    color: 'rgba(0, 0, 0, 0.38)',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    maxWidth: '250px',
  },
});

function Feedback(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    // eslint-disable-next-line no-restricted-globals
    props.submitFeedback(location.href, feedback);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <div onClick={handleClickOpen} className={classes.text}>
        <FeedbackIcon />
        Feedback about this page?
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            label="Feedback"
            type="feedback"
            variant="outlined"
            placeholder="Feedback about this page?"
            onChange={(e) => setFeedback(e.currentTarget.value)}
            value={feedback}
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            size="small"
            onClick={handleSubmit}
            disabled={feedback.length === 0}
            color="secondary"
            variant="contained"
          >
            Send Feedback
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapActionsToProps = {
  submitFeedback,
};

const styledComponent = withStyles(styles)(Feedback);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
