import { SNACKBAR_OPEN, SNACKBAR_CLEAR } from '../actions/index';

const initialState = {
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarSeverity: 'success',

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload.message,
        snackbarSeverity: action.payload.severity,
      };

    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false,
      };

    default:
      return state;
  }
};

export default reducer;
