import React from 'react';

// Style imports
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function LpAlert(props) {
  const { history, menuOpenLp, handleLpMenu } = props;

  const handleClose = () => {
    handleLpMenu(false);
  };
  const handleSubmit = () => {
    history.push('/profile/learningplan');
    handleLpMenu(false);
  };

  return (
    <div>
      <Dialog
        open={menuOpenLp}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText>
            To filter by learning plan, you must enter your learning plan first. Do you want to
            enter your plan now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose} color="primary">
            No
          </Button>
          <Button size="small" onClick={handleSubmit} color="secondary" variant="contained">
            Take me there
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LpAlert;
