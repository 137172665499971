/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import auth0Client from '../auth/Auth';

function SignUpView(props) {
  const signUp = () => {
    auth0Client.signUp();
    props.history.replace('/');
  };

  return <>{!auth0Client.isAuthenticated() ? signUp() : props.history.push('/')}</>;
}

export default SignUpView;
