/* eslint-disable camelcase */
/* eslint-disable no-console */
import axiosWithAuth from '../utils/axiosWithAuth';

import { showSnackbar } from './uiActions';
import { errorWebhook } from './slackActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------CPEs------->

// Requests the entire cpe array

export const GET_CPES = 'GET_CPES';
export const GET_CPES_SUCCESS = 'GET_CPES_SUCCESS';
export const GET_CPES_FAILURE = 'GET_CPES_FAILURE';

export const getCpes = (filter) => (dispatch) => {
  dispatch({ type: GET_CPES });
  axiosWithAuth()
    .get(`${url}/api/cpes/?${filter}`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: GET_CPES_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(GET_CPES_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_CPES_FAILURE, payload: err.message });
    });
};

// CPE Details

export const CPE_DETAILS = 'CPE_DETAILS';
export const CPE_DETAILS_SUCCESS = 'CPE_DETAILS_SUCCESS';
export const CPE_DETAILS_FAILURE = 'CPE_DETAILS_FAILURE';

export const getCpeDetails = (activity_number) => (dispatch) => {
  dispatch({ type: CPE_DETAILS });
  axiosWithAuth()
    .get(`${url}/api/cpes/${activity_number}`)
    .then((res) => {
      console.log(res);
      dispatch({ type: CPE_DETAILS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(CPE_DETAILS_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: CPE_DETAILS_FAILURE, payload: err.message });
    });
};

// Update CPE

export const UPDATE_CPE = 'UPDATE_CPE';
export const UPDATE_CPE_SUCCESS = 'UPDATE_CPE_SUCCESS';
export const UPDATE_CPE_FAILURE = 'UPDATE_CPE_FAILURE';

export const updateCpe = (cpe) => (dispatch) => {
  dispatch({ type: UPDATE_CPE });
  axiosWithAuth()
    .put(`${url}/api/cpes/`, cpe)
    .then((res) => {
      // console.log(res);
      dispatch(showSnackbar('CPEU updated!', 'success'));
      dispatch({ type: UPDATE_CPE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(showSnackbar('CPEU update failed!', 'error'));
      dispatch(errorWebhook(UPDATE_CPE_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: UPDATE_CPE_FAILURE, payload: err.message });
    });
};
