/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { connect } from 'react-redux';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
// import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';

// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    width: '100%',
    // minWidth: '300px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    margin: theme.spacing(2.5, 0),
  },
  stateLabel: {
    backgroundColor: 'white',
  },
  formControl: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  saveButton: {
    maxWidth: '100px',
  },
  editOpen: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5, 0),
  },
});

function EditStateCard(props) {
  const { classes } = props;
  const [isOpen, setIsOpen] = useState(false);
  const content = isOpen ? (
    <div className={classes.editOpen}>
      <Typography variant="subtitle2" color="textSecondary">
        {props.description}
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="state-select" className={classes.stateLabel}>
          Registration State
        </InputLabel>
        <Select
          value={props.value}
          onChange={props.handleSelectState}
          input={
            <OutlinedInput
              // labelWidth={labelRef ? labelRef.offsetWidth : 0}
              name="Registration State"
              id="state-select"
            />
          }
        >
          <MenuItem value="" />
          <MenuItem value="AL">Alabama</MenuItem>
          <MenuItem value="AK">Alaska</MenuItem>
          <MenuItem value="AZ">Arizona</MenuItem>
          <MenuItem value="AR">Arkansas</MenuItem>
          <MenuItem value="CA">California</MenuItem>
          <MenuItem value="CO">Colorado</MenuItem>
          <MenuItem value="CT">Connecticut</MenuItem>
          <MenuItem value="DE">Delaware</MenuItem>
          <MenuItem value="FL">Florida</MenuItem>
          <MenuItem value="GA">Georgia</MenuItem>
          <MenuItem value="HI">Hawaii</MenuItem>
          <MenuItem value="ID">Idaho</MenuItem>
          <MenuItem value="IL">Illinois</MenuItem>
          <MenuItem value="IN">Indiana</MenuItem>
          <MenuItem value="IA">Iowa</MenuItem>
          <MenuItem value="KS">Kansas</MenuItem>
          <MenuItem value="KY">Kentucky</MenuItem>
          <MenuItem value="LA">Louisiana</MenuItem>
          <MenuItem value="ME">Maine</MenuItem>
          <MenuItem value="MD">Maryland</MenuItem>
          <MenuItem value="MA">Massachusetts</MenuItem>
          <MenuItem value="MI">Michigan</MenuItem>
          <MenuItem value="MN">Minnesota</MenuItem>
          <MenuItem value="MS">Mississippi</MenuItem>
          <MenuItem value="MO">Missouri</MenuItem>
          <MenuItem value="MT">Montana</MenuItem>
          <MenuItem value="NE">Nebraska</MenuItem>
          <MenuItem value="NV">Nevada</MenuItem>
          <MenuItem value="NH">New Hampshire</MenuItem>
          <MenuItem value="NJ">New Jersey</MenuItem>
          <MenuItem value="NM">New Mexico</MenuItem>
          <MenuItem value="NY">New York</MenuItem>
          <MenuItem value="NC">North Carolina</MenuItem>
          <MenuItem value="ND">North Dakota</MenuItem>
          <MenuItem value="OH">Ohio</MenuItem>
          <MenuItem value="OK">Oklahoma</MenuItem>
          <MenuItem value="OR">Oregon</MenuItem>
          <MenuItem value="PA">Pennsylvania</MenuItem>
          <MenuItem value="RI">Rhode Island</MenuItem>
          <MenuItem value="SC">South Carolina</MenuItem>
          <MenuItem value="SD">South Dakota</MenuItem>
          <MenuItem value="TN">Tennessee</MenuItem>
          <MenuItem value="TX">Texas</MenuItem>
          <MenuItem value="UT">Utah</MenuItem>
          <MenuItem value="VT">Vermont</MenuItem>
          <MenuItem value="VA">Virginia</MenuItem>
          <MenuItem value="WA">Washington</MenuItem>
          <MenuItem value="WV">West Virginia</MenuItem>
          <MenuItem value="WI">Wisconsin</MenuItem>
          <MenuItem value="WY">Wyoming</MenuItem>
        </Select>
      </FormControl>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        className={classes.saveButton}
        onClick={() => {
          props.handleSubmit(props.title, props.value);
          setIsOpen(!isOpen);
        }}
        disabled={props.required && props.value === ''}
      >
        Save
      </Button>
    </div>
  ) : (
    <Typography variant="subtitle2" color="textSecondary">
      {props.valueSaved}
    </Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.top}>
          <Typography variant="h5" color="secondary">
            {props.label}
          </Typography>
          <Button onClick={() => setIsOpen(!isOpen)}>Edit</Button>
        </div>

        {content}
      </div>
      <Divider />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(EditStateCard);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
