import React from 'react';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: '250px',
  },
  card: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    height: '210px',
    width: '210px',
    boxShadow: theme.shadows[1],
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      margin: theme.spacing(0.5, 1),
      padding: theme.spacing(2),
    },
  },
  cardSelected: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    height: '210px',
    width: '210px',
    boxShadow: theme.shadows[7],
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      margin: theme.spacing(0.5, 1),
      padding: theme.spacing(2),
    },
  },
  button: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
});

function Product(props) {
  const { product, productSelected, handleClick, classes } = props;

  // If the product is selected, make selected true
  let selected = false;
  if (productSelected) {
    selected = product.key === productSelected.key;
  }

  return (
    <div className={classes.root}>
      <Card className={selected ? classes.cardSelected : classes.card}>
        <Typography variant="subtitle1" color="textSecondary">
          {product.name}
        </Typography>
        <Typography variant="h6" color="secondary">
          {product.price}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Per {product.interval}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Billed {product.billed}
        </Typography>

        <div className={classes.button}>
          {selected ? (
            <Button type="submit" color="primary" variant="contained">
              Selected
            </Button>
          ) : (
            <Button onClick={() => handleClick(product.key)} variant="contained" type="submit">
              Select
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}

export default withStyles(styles)(Product);
