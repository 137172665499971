/* eslint-disable react/require-default-props */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

function Meta(props: {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  siteName?: string;
  domain?: string;
}) {
  const location = useLocation();
  const { ...customPageMeta } = props;

  // Meta values that are the same across all pages
  const globalMeta = {
    // Site name
    siteName: 'CPEU.org',
    // Your production domain (example: https://myapp.com)
    domain: 'https://cpeu.org',
  };

  // Default meta values for current page (override with props)
  const defaultPageMeta = {
    // Page title
    title: 'CPEU.org',
    // Page description
    description: 'The best place for Registered Dietitians to find CPEUs.',
    // Social share image (create this file in /public/images/)
    image: '/op-image.jpg',
    // Page type (see https://ogp.me/#types)
    type: 'website',
  };

  // Construct meta object from global, default, and custom meta
  const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta };

  // Note: Each tag should have a unique `key` so that they are de-deduped if other
  // `Meta` components are rendered on the same page or within nested components.
  // prettier-ignore
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta content={meta.description.substring(0, 200)} name="description" key="description" />
      {meta.domain && <link rel="canonical" href={`${meta.domain}${location.pathname}${location.search}`} key="canonical" />}

      {/* Open Graph */}
      <meta property="og:title" content={meta.title} key="og-title" />
      <meta property="og:description" content={meta.description.substring(0, 200)} key="og-description" />
      <meta property="og:site_name" content={meta.siteName} key="og-site-name" />
      <meta property="og:type" content="website" key="og-type" />
      {meta.domain && <meta property="og:url" content={`${meta.domain}${location.pathname}${location.search}`} key="og-url" />}
      {meta.domain && meta.image && <meta property="og:image" content={`${meta.domain}${meta.image}`} key="og-image" />}

      {/* Twitter */}
      <meta name="twitter:title" content={meta.title} key="twitter-title" />
      <meta name="twitter:description" content={meta.description.substring(0, 200)} key="twitter-description"/>
      <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
      {meta.domain && meta.image && <meta name="twitter:image" content={`${meta.domain}${meta.image}`} key="twitter-image" />}
    </Helmet>
  );
}

export default Meta;
