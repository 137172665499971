/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import { updateUser, updateLP, newUserWebhook } from '../actions';

import Info from '../components/util/Info';
import OnboardingPayment from '../components/onboarding/OnboardingPayment';
import OnboardingProfile from '../components/onboarding/OnboardingProfile';
import OnboardingLearningPlan from '../components/onboarding/OnboardingLearningPlan';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
    },
  },
  content: {
    maxWidth: 1000,
    margin: 'auto',
    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
  },
  actionsContainer: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: theme.shadows[0],
  },
});

class OnboardingView extends React.Component {
  constructor() {
    super();
    // this.handleKeypress = this.handleKeypress.bind(this);
    // this.handleChipAdd = this.handleChipAdd.bind(this);
    // this.handleNextKeypress = this.handleNextKeypress.bind(this);

    this.state = {
      activeStep: 0,
      // name: '',
      // nameHelperText: '',
      // nameError: false,
      state: '',

      cycle: 0,
      cycle_begin_date: null,
      cycle_end_date: null,
      // license: '',
      // licenseHelperText: '',
      // licenseError: false,
      // email: '',
      // emailHelperText: '',
      // emailError: false,
      // username: '',
      // twitterHandles: [],
      // usernameHelperText: '',
      // search_users: null,
      // listParams: null,
      // newListResponseUpdated: false,
      // usernameError: false,
      // buttonDisabled: false,
      // open: false,
      // skipped: new Set(),
      gotLoggedIn: false,

      // Used for Payment
      // subscriptionStatus: '',
    };
    // this.lastId = -1;
  }

  componentDidMount() {
    this.setState({
      first_name: this.props.user.first_name === null ? '' : this.props.user.first_name,
      last_name: this.props.user.last_name === null ? '' : this.props.user.last_name,
      state: this.props.user.state,
      email: this.props.user.email,
      cycle: this.props.user.cycle === null ? '0' : this.props.user.cycle,
      cycle_begin_date: this.props.user.cycle_begin_date,
      cycle_end_date: this.props.user.cycle_end_date,
    });
  }

  componentDidUpdate() {
    if (this.props.loggingInSuccess && !this.state.gotLoggedIn) {
      this.setState({
        first_name: this.props.user.first_name === null ? '' : this.props.user.first_name,
        last_name: this.props.user.last_name === null ? '' : this.props.user.last_name,
        state: this.props.user.state,
        email: this.props.user.email,
        cycle: this.props.user.cycle === null ? '0' : this.props.user.cycle,
        cycle_begin_date: this.props.user.cycle_begin_date,
        cycle_end_date: this.props.user.cycle_end_date,
        gotLoggedIn: true,
      });
    }
  }

  // isStepOptional = (step) => step === 0;

  // isStepFailed = (step) => step === 0;

  // handleLicenseChange = (event) => {
  //   if (event.currentTarget.value.length > 0) {
  //     this.setState({ license: event.target.value, licenseHelperText: '', licenseError: false });
  //   } else {
  //     this.setState({
  //       license: event.target.value,
  //       licenseHelperText: 'Please enter your license',
  //       licenseError: true,
  //     });
  //   }
  // };

  handleCycleChange = (cycle) => {
    // console.log('handleCycleChange', cycle, this.state.cycle);
    if (cycle === '0') {
      this.setState({
        cycle: 0,
        // cycle_begin_date: '2015-06-01',
        // cycle_end_date: '2020-05-31',
      });
    } else if (cycle === '2') {
      this.setState({
        cycle: 2,
        cycle_begin_date: '2015-06-01',
        cycle_end_date: '2020-05-31',
      });
    } else if (cycle === '3') {
      this.setState({
        cycle: 3,
        cycle_begin_date: '2016-06-01',
        cycle_end_date: '2021-05-31',
      });
    } else if (cycle === '4') {
      this.setState({
        cycle: 4,
        cycle_begin_date: '2017-06-01',
        cycle_end_date: '2022-05-31',
      });
    } else if (cycle === '5') {
      this.setState({
        cycle: 5,
        cycle_begin_date: '2018-06-01',
        cycle_end_date: '2023-05-31',
      });
    } else if (cycle === '6') {
      this.setState({
        cycle: 6,
        cycle_begin_date: '2019-06-01',
        cycle_end_date: '2024-05-31',
      });
    } else if (cycle === '7') {
      this.setState({
        cycle: 7,
        cycle_begin_date: '2020-06-01',
        cycle_end_date: '2025-05-31',
      });
    } else if (cycle === '8') {
      this.setState({
        cycle: 8,
        cycle_begin_date: '2021-06-01',
        cycle_end_date: '2026-05-31',
      });
    } else if (cycle === '9') {
      this.setState({
        cycle: 9,
        cycle_begin_date: '2022-06-01',
        cycle_end_date: '2027-05-31',
      });
    } else if (cycle === '10') {
      this.setState({
        cycle: 10,
        cycle_begin_date: '2023-06-01',
        cycle_end_date: '2028-05-31',
      });
    } else if (cycle === '11') {
      this.setState({
        cycle: 11,
        cycle_begin_date: '2024-06-01',
        cycle_end_date: '2029-05-31',
      });
    } else if (cycle === '12') {
      this.setState({
        cycle: 12,
        cycle_begin_date: '2025-06-01',
        cycle_end_date: '2030-05-31',
      });
    }
  };

  handleBeginDateChange = (cycle_begin_date) => {
    const date = cycle_begin_date ? moment(cycle_begin_date).format('YYYY-MM-DD') : null;
    this.setState({ cycle_begin_date: date });
  };

  handleEndDateChange = (cycle_end_date) => {
    const date = cycle_end_date ? moment(cycle_end_date).format('YYYY-MM-DD') : null;
    this.setState({ cycle_end_date: date });
  };

  canBeSubmitted = () => {
    // const { name, license, email } = this.state;
    // return name.length > 0 && license.length > 0 && email.length > 0;
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const updatedUser = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      registration_number: this.state.registration_number,
      // credentials: this.state.credentials,
      state: this.state.state,
      // registration_date: this.state.registration_date,

      cycle: this.state.cycle === 0 ? null : this.state.cycle,
      cycle_begin_date: this.state.cycle_begin_date,
      cycle_end_date: this.state.cycle_end_date,
      onboarded: true,
    };

    // Format and submit learning plan
    const lpArray = [];
    const lp = {};

    // console.log('this.props.onboardingLPArray', this.props.onboardingLPArray);
    // If they don't fill anything out, set 'learning_plan_completed' to false
    if (this.props.onboardingLPArray.length === 0) {
      lp.learning_plan_completed = false;
      lp.learning_plan_type = 'idk';
    } else {
      lp.learning_plan_type = this.props.onboardingLPType;

      this.props.onboardingLPArray.map((e) => {
        return lpArray.push(e.id);
      });

      if (this.props.onboardingLPType === 'pi') {
        lp.learning_plan_completed = true;
        lp.learning_plan_pi = lpArray;
        lp.learning_plan_lc = null;
      } else if (this.props.onboardingLPType === 'lc') {
        lp.learning_plan_completed = true;
        lp.learning_plan_pi = null;
        lp.learning_plan_lc = lpArray;
      } else if (this.props.onboardingLPType === 'idk') {
        lp.learning_plan_completed = false;
        lp.learning_plan_pi = null;
        lp.learning_plan_lc = null;
      }
    }

    // console.log('this.props.lp', lp);
    // console.log('updatedUser', updatedUser);

    // PUT to update user profile
    this.props.updateUser(updatedUser).then(() => {
      this.props.updateLP(lp);
      this.props.newUserWebhook();
    });
    this.props.history.push('/welcome');
  };

  // handlePopoverClose = () => {
  //   this.setState({ open: null });
  // };

  getSteps = () => {
    return ['Payment Info', 'Create Profile', 'Learning Plan'];
  };

  getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <OnboardingPayment
            // first_name={this.props.user.first_name === null ? this.state.first_name : this.props.user.first_name}
            // last_name={this.props.user.last_name === null ? this.state.last_name : this.props.user.last_name}
            // handleNameChange={this.handleNameChange}
            // handleNextKeypress={this.handleNextKeypress}
            // nameHelperText={this.state.nameHelperText}
            // nameError={this.state.nameError}
            subscription_status={this.props.user.subscription_status}
          />
        );
      case 1:
        return (
          <OnboardingProfile
            handleBeginDateChange={this.handleBeginDateChange}
            cycle_begin_date={this.state.cycle_begin_date}
            handleEndDateChange={this.handleEndDateChange}
            cycle_end_date={this.state.cycle_end_date}
            handleCycleChange={this.handleCycleChange}
            cycle={this.state.cycle.toString()}

            // license={this.state.license}
            // handleLicenseChange={this.handleLicenseChange}
            // handleNextKeypress={this.handleNextKeypress}
            // licenseHelperText={this.state.licenseHelperText}
            // licenseError={this.state.licenseError}
            // stateChoice={this.state.state}
            // handleStateChange={this.handleStateChange}
          />
        );
      case 2:
        return (
          <>
            <Typography variant="h2" color="secondary">
              Learning Plan
            </Typography>
            <Paper className={this.props.classes.paper}>
              {/* <Typography variant="h5" color="primary">
                In order to help you select CPEUs related to your Learning Plan, please enter your
                Learning Plan.
              </Typography> */}
              <Info
                text={
                  "We ask for your learning plan so we can personalize your experience and show you CPEUs that match. If you don't want to fill this out right now, you can easily enter your plan later."
                }
              />
              <OnboardingLearningPlan />
            </Paper>
          </>
        );
      case 3:
        return (
          <Typography variant="h6" color="primary">
            Please enter?
          </Typography>
        );
      default:
        return 'Unknown step';
    }
  }

  // handleSkip = () => {
  //   const { activeStep } = this.state;
  //   if (!this.isStepOptional(activeStep)) {
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   this.setState((state) => {
  //     const skipped = new Set(state.skipped.values());
  //     skipped.add(activeStep);
  //     return {
  //       activeStep: state.activeStep + 1,
  //       skipped,
  //     };
  //   });
  // };

  // handleNextKeypress(event) {
  //   if (event.key === 'Enter') {
  //     this.handleNext();
  //   }
  // }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  // isStepSkipped(step) {
  //   return this.state.skipped.has(step);
  // }

  handleDisableNext = (activeStep) => {
    if (activeStep === 0 && this.props.user.subscription_status !== 'active') {
      return true;
    }
    // if (activeStep === 1 && this.state.cycle_begin_date === null) {
    //   return true;
    // }
    // if (activeStep === 1 && this.state.license.length === 0) {
    // if (activeStep === 1) {
    //   return true;
    // }
    // else if (activeStep === 3 && this.state.twitterHandles.length === 0) {
    //   return true
    // }
    return false;
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const isEnabled = this.canBeSubmitted();
    const content = this.props.loggingInSuccess ? (
      <>
        {' '}
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep === steps.length ? (
          <div className={classes.actionsContainer}>
            <Typography>Thank you! Click below to start finding CPEUs.</Typography>
            <Button onClick={this.handleReset} className={classes.button}>
              Reset
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={this.handleSubmit}
              disabled={!isEnabled}
            >
              Submit
            </Button>
          </div>
        ) : (
          <>
            <div>{this.getStepContent(activeStep)}</div>
            {/* {console.log(
              'this.props.onboardingLPArray',
              this.props.onboardingLPArray,
              this.props.onboardingLPType
            )} */}
            <div className={classes.actionsContainer}>
              <Button
                disabled={activeStep === 0}
                onClick={this.handleBack}
                className={classes.button}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                className={classes.button}
                disabled={this.handleDisableNext(activeStep)}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </>
        )}
      </>
    ) : (
      <> Loading... </>
    );

    return (
      <div className={classes.content}>
        <Helmet>
          <title>Onboarding | CPEU.org</title>
        </Helmet>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  loggingInSuccess: state.userReducer.loggingInSuccess,
  onboardingLPArray: state.lpReducer.onboardingLPArray,
  onboardingLPType: state.lpReducer.onboardingLPType,
});

const mapActionsToProps = {
  updateUser,
  updateLP,
  newUserWebhook,
};

const styledComponent = withStyles(styles)(OnboardingView);

const routedComponent = withRouter(styledComponent);

export default connect(mapStateToProps, mapActionsToProps)(routedComponent);
