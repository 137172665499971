import {
  GET_LP,
  GET_LP_SUCCESS,
  GET_LP_FAILURE,
  ADD_LP,
  ADD_LP_SUCCESS,
  ADD_LP_FAILURE,
  // UPDATE_LP,
  // UPDATE_LP_SUCCESS,
  // UPDATE_LP_FAILURE,
  DELETE_LP,
  DELETE_LP_SUCCESS,
  DELETE_LP_FAILURE,
  UPDATE_ONBOARDING_LP,
  ON_LOGIN_SETUP_LP,
} from '../actions/index';

const initialState = {
  // lp: [],
  gettingLP: false,
  getLPSuccess: false,

  addingLP: false,
  addLPSuccess: false,

  updatingLP: false,
  updateLPSuccess: false,

  deletingLP: false,
  deleteLPSuccess: false,

  onboardingLPArray: [],
  onboardingLPType: 'idk',

  learning_plan_lc: [],
  learning_plan_pi: [],
  learning_plan_completed: false,
  learning_plan_type: 'idk',

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LP:
      return {
        ...state,
        gettingLP: true,
        getLPSuccess: false,
        error: null,
      };
    case GET_LP_SUCCESS:
      return {
        ...state,
        gettingLP: false,
        getLPSuccess: true,
        learning_plan_lc: action.payload.learning_plan_lc,
        learning_plan_pi: action.payload.learning_plan_pi,
        learning_plan_completed: action.payload.learning_plan_completed,
        learning_plan_type: action.payload.learning_plan_type,
      };
    case GET_LP_FAILURE:
      return {
        ...state,
        gettingLP: false,
        getLPSuccess: false,
        error: action.payload,
      };

    case ADD_LP:
      return {
        ...state,
        addingLP: true,
        addLPSuccess: false,
        error: null,
      };
    case ADD_LP_SUCCESS:
      return {
        ...state,
        addingLP: false,
        addLPSuccess: true,
        // lp: action.payload.results,
      };
    case ADD_LP_FAILURE:
      return {
        ...state,
        addingLP: false,
        addLPSuccess: false,
        error: action.payload,
      };

    // case UPDATE_LP:
    //   return {
    //     ...state,
    //     updatingLP: true,
    //     updateLPSuccess: false,
    //     error: null,
    //   };
    // case UPDATE_LP_SUCCESS:
    //   return {
    //     ...state,
    //     updatingLP: false,
    //     updateLPSuccess: true,
    //     // lp: action.payload.results,
    //   };
    // case UPDATE_LP_FAILURE:
    //   return {
    //     ...state,
    //     updatingLP: false,
    //     updateLPSuccess: false,
    //     error: action.payload,
    //   };

    case DELETE_LP:
      return {
        ...state,
        deletingLP: true,
        deleteLPSuccess: false,
        error: null,
      };
    case DELETE_LP_SUCCESS:
      return {
        ...state,
        deletingLP: false,
        deleteLPSuccess: true,
        // lp: action.payload.results,
      };
    case DELETE_LP_FAILURE:
      return {
        ...state,
        deletingLP: false,
        deleteLPSuccess: false,
        error: action.payload,
      };

    case UPDATE_ONBOARDING_LP:
      return {
        ...state,
        onboardingLPArray: action.payload.learningPlanArray,
        onboardingLPType: action.payload.learningPlanType,
      };

    case ON_LOGIN_SETUP_LP:
      return {
        ...state,
        learning_plan_lc: action.payload.learning_plan_lc,
        learning_plan_pi: action.payload.learning_plan_pi,
        learning_plan_completed: action.payload.learning_plan_completed,
        learning_plan_type: action.payload.learning_plan_type,
      };

    default:
      return state;
  }
};

export default reducer;
