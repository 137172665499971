import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  CHECK_COUPON,
  CHECK_COUPON_SUCCESS,
  CHECK_COUPON_FAILURE,
  GET_CUSTOMER_PORTAL_URL,
  GET_CUSTOMER_PORTAL_URL_SUCCESS,
  GET_CUSTOMER_PORTAL_URL_FAILURE,
  RETRY_INVOICE,
  RETRY_INVOICE_SUCCESS,
  RETRY_INVOICE_FAILURE,
  SUBSCRIPTION_COMPLETE,
  SUBSCRIPTION_COMPLETE_SUCCESS,
  SUBSCRIPTION_COMPLETE_FAILURE,
} from '../actions/index';

const initialState = {
  products: {},
  gettingProducts: false,
  getProductsSuccess: false,

  subscription: {},
  creatingSubscription: false,
  createSubscriptionSuccess: false,

  couponValid: false,
  couponAmount: 0,
  couponPercent: 0,
  checkingCoupon: false,
  checkCouponSuccess: false,

  customerPortalUrl: '',
  gettingPortalUrl: false,
  getPortalUrlSuccess: false,

  invoice: {},
  retryingInvoice: false,
  retryingInvoiceSuccess: false,

  completingSubscription: false,
  completingSubscriptionSuccess: false,

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        gettingProducts: true,
        getProductsSuccess: false,
        error: null,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        gettingProducts: false,
        getProductsSuccess: true,
        products: action.payload,
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        gettingProducts: false,
        getProductsSuccess: false,
        error: action.payload,
      };

    case CREATE_SUBSCRIPTION:
      return {
        ...state,
        creatingSubscription: true,
        createSubscriptionSuccess: false,
        error: null,
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        creatingSubscription: false,
        createSubscriptionSuccess: true,
        subscription: action.payload,
      };
    case CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        creatingSubscription: false,
        createSubscriptionSuccess: false,
        error: action.payload,
      };
    case CHECK_COUPON:
      return {
        ...state,
        checkingCoupon: true,
        checkCouponSuccess: false,
        couponValid: false,
        couponAmount: 0,
        couponPercent: 0,
        error: null,
      };
    case CHECK_COUPON_SUCCESS:
      return {
        ...state,
        checkingCoupon: false,
        checkCouponSuccess: true,
        couponValid: action.payload.valid,
        couponAmount: action.payload.amount_off,
        couponPercent: action.payload.percent_off,
      };
    case CHECK_COUPON_FAILURE:
      return {
        ...state,
        checkingCoupon: false,
        checkCouponSuccess: false,
        couponValid: false,
        couponAmount: 0,
        couponPercent: 0,
        error: action.payload,
      };

    case GET_CUSTOMER_PORTAL_URL:
      return {
        ...state,
        gettingPortalUrl: true,
        getPortalUrlSuccess: false,
        error: null,
      };
    case GET_CUSTOMER_PORTAL_URL_SUCCESS:
      return {
        ...state,
        gettingPortalUrl: false,
        getPortalUrlSuccess: true,
        customerPortalUrl: action.payload,
      };
    case GET_CUSTOMER_PORTAL_URL_FAILURE:
      return {
        ...state,
        gettingPortalUrl: false,
        getPortalUrlSuccess: false,
        error: action.payload,
      };

    case RETRY_INVOICE:
      return {
        ...state,
        retryingInvoice: true,
        retryingInvoiceSuccess: false,
        error: null,
      };
    case RETRY_INVOICE_SUCCESS:
      return {
        ...state,
        retryingInvoice: false,
        retryingInvoiceSuccess: true,
        invoice: action.payload,
      };
    case RETRY_INVOICE_FAILURE:
      return {
        ...state,
        retryingInvoice: false,
        retryingInvoiceSuccess: false,
        error: action.payload,
      };

    case SUBSCRIPTION_COMPLETE:
      return {
        ...state,
        completingSubscription: true,
        completingSubscriptionSuccess: false,
        error: null,
      };
    case SUBSCRIPTION_COMPLETE_SUCCESS:
      return {
        ...state,
        completingSubscription: false,
        completingSubscriptionSuccess: true,
      };
    case SUBSCRIPTION_COMPLETE_FAILURE:
      return {
        ...state,
        completingSubscription: false,
        completingSubscriptionSuccess: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
