import React from 'react';
import { connect } from 'react-redux';

// Style imports
// import { withStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

// import { borderRadius } from '@material-ui/system';
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Nouislider from 'nouislider-react';

import { getFilteredPrice, getFilteredCpes } from '../../actions';
import './slider.css';

const styles = (theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    boxShadow: 'none',
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  price: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  priceField: {
    width: '120px',
  },
});

class PriceFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minPrice: 0,
      maxPrice: 350,
    };
  }

  componentDidMount() {
    this.setState({
      minPrice: this.props.priceSelected[0],
      maxPrice: this.props.priceSelected[1],
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value || '' });
  };

  onSet = (value) => {
    this.setState({
      minPrice: value[0],
      maxPrice: value[1],
    });
  };

  savePrice = (target) => {
    this.props.handlePriceMenu(target);
    this.props.getFilteredPrice(this.state.minPrice, this.state.maxPrice);
    this.props.getFilteredCpes();
    this.props.history.push('/cpes/1');
  };

  render() {
    return (
      <div className={this.props.classes.cardContainer}>
        <Card className={this.props.classes.card}>
          <CardContent>
            <Typography variant="h6" color="secondary">
              Price
            </Typography>
            <Nouislider
              start={[this.state.minPrice, this.state.maxPrice]}
              range={{ min: this.props.priceRange[0], max: this.props.priceRange[1] }}
              step={1}
              tooltips
              connect
              onSet={this.onSet}
            />
            <div className={this.props.classes.price}>
              <TextField
                name="minPrice"
                id="minPrice"
                label="Minimum Price"
                placeholder="Free"
                value={parseInt(this.state.minPrice, 10) || ''}
                className={this.props.classes.priceField}
                onChange={this.handleChange}
                margin="dense"
                variant="outlined"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                name="maxPrice"
                id="maxPrice"
                label="Maximum Price"
                placeholder="Free"
                value={parseInt(this.state.maxPrice, 10) || ''}
                className={this.props.classes.priceField}
                onChange={this.handleChange}
                margin="dense"
                variant="outlined"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </div>
          </CardContent>
        </Card>
        <div className={this.props.classes.buttons}>
          <Button onClick={this.props.handlePriceMenu} color="default">
            Close
          </Button>
          <Button onClick={this.savePrice} color="primary">
            Save
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  priceSelected: state.filterReducer.priceSelected,
  priceRange: state.filterReducer.priceRange,
});

const mapActionsToProps = {
  getFilteredPrice,
  getFilteredCpes,
};

const styledComponent = withStyles(styles)(PriceFilter);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
