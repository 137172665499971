import {
  GET_BOOKMARKS,
  GET_BOOKMARKS_SUCCESS,
  GET_BOOKMARKS_FAILURE,
  GET_BOOKMARKS_LIST,
  GET_BOOKMARKS_LIST_SUCCESS,
  GET_BOOKMARKS_LIST_FAILURE,
  ADD_BOOKMARK,
  ADD_BOOKMARK_SUCCESS,
  ADD_BOOKMARK_FAILURE,
  DELETE_BOOKMARK,
  DELETE_BOOKMARK_SUCCESS,
  DELETE_BOOKMARK_FAILURE,
} from '../actions/index';

const initialState = {
  bookmarks: [],
  gettingBookmarks: false,
  getBookmarksSuccess: false,

  bookmarksList: {},
  gettingBookmarksList: false,
  getBookmarksListSuccess: false,

  addingBookmark: false,
  addBookmarksuccess: false,

  deletingBookmark: false,
  deleteBookmarksuccess: false,

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKMARKS:
      return {
        ...state,
        gettingBookmarks: true,
        getBookmarksSuccess: false,
        error: null,
      };
    case GET_BOOKMARKS_SUCCESS:
      return {
        ...state,
        gettingBookmarks: false,
        getBookmarksSuccess: true,
        bookmarks: action.payload.results,
      };
    case GET_BOOKMARKS_FAILURE:
      return {
        ...state,
        gettingBookmarks: false,
        getBookmarksSuccess: false,
        error: action.payload,
      };

    case GET_BOOKMARKS_LIST:
      return {
        ...state,
        gettingBookmarksList: true,
        getBookmarksListSuccess: false,
        error: null,
      };
    case GET_BOOKMARKS_LIST_SUCCESS:
      return {
        ...state,
        gettingBookmarksList: false,
        getBookmarksListSuccess: true,
        bookmarksList: action.payload,
      };
    case GET_BOOKMARKS_LIST_FAILURE:
      return {
        ...state,
        gettingBookmarksList: false,
        getBookmarksListSuccess: false,
        error: action.payload,
      };

    case ADD_BOOKMARK:
      return {
        ...state,
        addingBookmark: true,
        addBookmarksuccess: false,
        error: null,
      };
    case ADD_BOOKMARK_SUCCESS:
      return {
        ...state,
        addingBookmark: false,
        addBookmarksuccess: true,
        // bookmarks: action.payload.results,
      };
    case ADD_BOOKMARK_FAILURE:
      return {
        ...state,
        addingBookmark: false,
        addBookmarksuccess: false,
        error: action.payload,
      };

    case DELETE_BOOKMARK:
      return {
        ...state,
        deletingBookmark: true,
        deleteBookmarksuccess: false,
        error: null,
      };
    case DELETE_BOOKMARK_SUCCESS:
      return {
        ...state,
        deletingBookmark: false,
        deleteBookmarksuccess: true,
        // bookmarks: action.payload.results,
      };
    case DELETE_BOOKMARK_FAILURE:
      return {
        ...state,
        deletingBookmark: false,
        deleteBookmarksuccess: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
