/* eslint-disable camelcase */
import axiosWithAuth from '../utils/axiosWithAuth';

import { showSnackbar } from './uiActions';
import { errorWebhook } from './slackActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------BOOKMARKS------->

export const GET_BOOKMARKS = 'GET_BOOKMARKS';
export const GET_BOOKMARKS_SUCCESS = 'GET_BOOKMARKS_SUCCESS';
export const GET_BOOKMARKS_FAILURE = 'GET_BOOKMARKS_FAILURE';
export const GET_BOOKMARKS_LIST = 'GET_BOOKMARKS_LIST';
export const GET_BOOKMARKS_LIST_SUCCESS = 'GET_BOOKMARKS_LIST_SUCCESS';
export const GET_BOOKMARKS_LIST_FAILURE = 'GET_BOOKMARKS_LIST_FAILURE';
export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const ADD_BOOKMARK_SUCCESS = 'ADD_BOOKMARK_SUCCESS';
export const ADD_BOOKMARK_FAILURE = 'ADD_BOOKMARK_FAILURE';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
export const DELETE_BOOKMARK_FAILURE = 'DELETE_BOOKMARK_FAILURE';

export const getBookmarks = () => (dispatch) => {
  dispatch({ type: GET_BOOKMARKS });
  axiosWithAuth()
    .get(`${url}/api/favorites/`)
    .then((res) => {
      // console.log("GET_BOOKMARKS_SUCCESS response", res);
      dispatch({ type: GET_BOOKMARKS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log("GET_BOOKMARKS_FAILURE err", err);
      dispatch(errorWebhook(GET_BOOKMARKS_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_BOOKMARKS_FAILURE, payload: err.message });
    });
};

export const getBookmarksList = () => (dispatch) => {
  dispatch({ type: GET_BOOKMARKS_LIST });
  axiosWithAuth()
    .get(`${url}/api/favorites/list/`)
    .then((res) => {
      // console.log("GET_BOOKMARKS_LIST_SUCCESS response", res);
      const bookDict = {};
      // eslint-disable-next-line no-return-assign
      res.data.map((e) => (bookDict[e] = true));
      // console.log("bookDict", bookDict)
      dispatch({ type: GET_BOOKMARKS_LIST_SUCCESS, payload: bookDict });
    })
    .catch((err) => {
      // console.log("GET_BOOKMARKS_LIST_FAILURE err", err);
      dispatch(errorWebhook(GET_BOOKMARKS_LIST_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_BOOKMARKS_LIST_FAILURE, payload: err.response });
    });
};

export const addBookmark = (cpe_id) => (dispatch) => {
  dispatch({ type: ADD_BOOKMARK });
  axiosWithAuth()
    .post(`${url}/api/favorites/${cpe_id}`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: ADD_BOOKMARK_SUCCESS, payload: res.data });
    })
    .then(() => {
      // After adding the bookmark, update the list
      dispatch(getBookmarksList());
      dispatch(showSnackbar('Bookmarked!', 'success'));
    })
    .catch((err) => {
      // console.log(err);
      dispatch(
        showSnackbar(
          'Adding bookmark failed. Please try again or contact us if this keeps happening. (hello@cpeu.org)',
          'error'
        )
      );
      dispatch(errorWebhook(ADD_BOOKMARK_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: ADD_BOOKMARK_FAILURE, payload: err.message });
    });
};

export const deleteBookmark = (cpe_id) => (dispatch) => {
  dispatch({ type: DELETE_BOOKMARK });
  axiosWithAuth()
    .delete(`${url}/api/favorites/${cpe_id}`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: DELETE_BOOKMARK_SUCCESS, payload: res.data });
    })
    .then(() => {
      // After deleting the bookmark, update the list
      dispatch(getBookmarksList());
      dispatch(showSnackbar('Bookmark removed.', 'info'));
    })
    .catch((err) => {
      // console.log(err);
      dispatch(
        showSnackbar(
          'Removing bookmark failed. Please try again or contact us if this keeps happening. (hello@cpeu.org)',
          'error'
        )
      );
      dispatch(errorWebhook(DELETE_BOOKMARK_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: DELETE_BOOKMARK_FAILURE, payload: err.message });
    });
};
