import React, { useState } from 'react';
import { connect } from 'react-redux';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    width: '100%',
    // minWidth: '300px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    margin: theme.spacing(2.5, 0),
  },
  saveButton: {
    maxWidth: '100px',
  },
  editOpen: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5, 0),
  },
});

function EditCard(props) {
  const { classes } = props;
  const [isOpen, setIsOpen] = useState(false);

  const content = isOpen ? (
    <div className={classes.editOpen}>
      <Typography variant="subtitle2" color="textSecondary">
        {props.description}
      </Typography>

      <TextField
        autoFocus
        type={props.number ? 'number' : null}
        required={props.required}
        name={props.title}
        id={props.title}
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.handleChange}
        margin="normal"
        variant="outlined"
      />
      <Button
        color="primary"
        variant="contained"
        size="medium"
        className={classes.saveButton}
        onClick={() => {
          props.handleSubmit(props.title, props.value);
          setIsOpen(!isOpen);
        }}
        disabled={props.required && props.value === ''}
      >
        Save
      </Button>
    </div>
  ) : (
    <Typography variant="subtitle2" color="textSecondary">
      {props.valueSaved}
    </Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.top}>
          <Typography variant="h5" color="secondary">
            {props.label}
          </Typography>
          <Button onClick={() => setIsOpen(!isOpen)}>Edit</Button>
        </div>

        {content}
      </div>
      <Divider />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(EditCard);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
