/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
// import Divider from '@material-ui/core/Divider';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// import styled from '@material-ui/styles/styled';
import CpeCard from '../components/cpe/CpeCard';
import EmptyBookmarks from '../components/bookmarks/EmptyBookmarks';

import Feedback from '../components/feedback/Feedback';
import { getBookmarks, getBookmarksList } from '../actions';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
  },
  content: {
    maxWidth: 1000,
    margin: 'auto',
  },
  heading: {
    padding: theme.spacing(1),
  },
  cpes: {
    width: 'auto',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  CpeCards: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class BookmarksView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // bookmarks: [],
      getBookmarksList: false,
    };
  }

  componentDidMount() {
    // console.log('BookmarksView CDM');
    // Get the bookmarks if they don't exist, OR if the lengths don't match between the bookmarks (cards) and bookmarksList (dictionary)
    if (
      this.props.bookmarks.length === 0 ||
      this.props.bookmarks.length !== Object.keys(this.props.bookmarksList).length
    ) {
      // console.log('BookmarksView CDM getBookmarks()');
      this.props.getBookmarks();
    }
    // If logged in and have not gotten the bookmarksList (dictionary)
    if (
      this.props.loggingInSuccess &&
      !this.props.gettingBookmarksList &&
      !this.props.getBookmarksListSuccess
    ) {
      // console.log('BookmarksView CDM getBookmarksList()');
      this.props.getBookmarksList();
      this.setState({ getBookmarksList: true });
    }
  }

  componentDidUpdate() {
    // console.log('BookmarksView CDU');
    // console.log(
    //   'FV CDU',
    //   this.props.loggingInSuccess,
    //   this.props.gettingBookmarksList,
    //   this.props.getBookmarksListSuccess,
    //   this.state.getBookmarksList
    // );
    if (
      this.props.loggingInSuccess &&
      !this.props.gettingBookmarksList &&
      !this.props.getBookmarksListSuccess &&
      !this.state.getBookmarksList
    ) {
      // console.log('BookmarksView CDU getBookmarksList()');
      this.props.getBookmarksList();
      this.setState({ getBookmarksList: true });
    }
  }

  render() {
    // console.log(this.props.bookmarks);
    const { classes } = this.props;

    const emptyOrNot =
      this.props.bookmarks.length === 0 ? (
        <EmptyBookmarks />
      ) : (
        <div className={classes.CpeCards}>
          {this.props.bookmarks.map((e) => (
            <CpeCard cpe={e} key={e.cpe_id} />
          ))}
        </div>
      );

    const content =
      this.props.getBookmarksSuccess === true ? (
        <div>{emptyOrNot}</div>
      ) : (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      );

    return (
      <div className={classes.content}>
        <Helmet>
          <title>Bookmarked CPEUs | CPEU.org</title>
        </Helmet>
        <div className={classes.root}>
          <div className={classes.heading}>
            <Typography variant="h3" color="secondary">
              Bookmarks
            </Typography>
          </div>
          <div className={classes.cpes}>{content}</div>
          <Feedback />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingInSuccess: state.userReducer.loggingInSuccess,
  bookmarks: state.bookmarksReducer.bookmarks,
  getBookmarksSuccess: state.bookmarksReducer.getBookmarksSuccess,

  bookmarksList: state.bookmarksReducer.bookmarksList,
  gettingBookmarksList: state.bookmarksReducer.gettingBookmarksList,
  getBookmarksListSuccess: state.bookmarksReducer.getBookmarksListSuccess,
});

const mapActionsToProps = {
  getBookmarks,
  getBookmarksList,
};

const styledComponent = withStyles(styles)(BookmarksView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
