import {
  GET_COMPLETED,
  GET_COMPLETED_SUCCESS,
  GET_COMPLETED_FAILURE,
  GET_COMPLETED_LIST,
  GET_COMPLETED_LIST_SUCCESS,
  GET_COMPLETED_LIST_FAILURE,
  ADD_COMPLETED,
  ADD_COMPLETED_SUCCESS,
  ADD_COMPLETED_FAILURE,
  UPDATE_COMPLETED,
  UPDATE_COMPLETED_SUCCESS,
  UPDATE_COMPLETED_FAILURE,
  DELETE_COMPLETED,
  DELETE_COMPLETED_SUCCESS,
  DELETE_COMPLETED_FAILURE,
} from '../actions/index';

const initialState = {
  completed: [],
  gettingCompleted: false,
  getCompletedSuccess: false,

  completedList: {},
  gettingCompletedList: false,
  getCompletedListSuccess: false,

  addingCompleted: false,
  addCompletedSuccess: false,

  updatingCompleted: false,
  updateCompletedSuccess: false,

  deletingCompleted: false,
  deleteCompletedSuccess: false,

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPLETED:
      return {
        ...state,
        gettingCompleted: true,
        getCompletedSuccess: false,
        error: null,
      };
    case GET_COMPLETED_SUCCESS:
      return {
        ...state,
        gettingCompleted: false,
        getCompletedSuccess: true,
        completed: action.payload.results,
      };
    case GET_COMPLETED_FAILURE:
      return {
        ...state,
        gettingCompleted: false,
        getCompletedSuccess: false,
        error: action.payload,
      };

    case GET_COMPLETED_LIST:
      return {
        ...state,
        gettingCompletedList: true,
        getCompletedListSuccess: false,
        error: null,
      };
    case GET_COMPLETED_LIST_SUCCESS:
      return {
        ...state,
        gettingCompletedList: false,
        getCompletedListSuccess: true,
        completedList: action.payload,
      };
    case GET_COMPLETED_LIST_FAILURE:
      return {
        ...state,
        gettingCompletedList: false,
        getCompletedListSuccess: false,
        error: action.payload,
      };

    case ADD_COMPLETED:
      return {
        ...state,
        addingCompleted: true,
        addCompletedSuccess: false,
        error: null,
      };
    case ADD_COMPLETED_SUCCESS:
      return {
        ...state,
        addingCompleted: false,
        addCompletedSuccess: true,
        // completed: action.payload.results,
      };
    case ADD_COMPLETED_FAILURE:
      return {
        ...state,
        addingCompleted: false,
        addCompletedSuccess: false,
        error: action.payload,
      };

    case UPDATE_COMPLETED:
      return {
        ...state,
        updatingCompleted: true,
        updateCompletedSuccess: false,
        error: null,
      };
    case UPDATE_COMPLETED_SUCCESS:
      return {
        ...state,
        updatingCompleted: false,
        updateCompletedSuccess: true,
      };
    case UPDATE_COMPLETED_FAILURE:
      return {
        ...state,
        updatingCompleted: false,
        updateCompletedSuccess: false,
        error: action.payload,
      };

    case DELETE_COMPLETED:
      return {
        ...state,
        deletingCompleted: true,
        deleteCompletedSuccess: false,
        error: null,
      };
    case DELETE_COMPLETED_SUCCESS:
      return {
        ...state,
        deletingCompleted: false,
        deleteCompletedSuccess: true,
        // completed: action.payload.results,
      };
    case DELETE_COMPLETED_FAILURE:
      return {
        ...state,
        deletingCompleted: false,
        deleteCompletedSuccess: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
