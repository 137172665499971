import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ProviderCard from '../components/providers/ProviderCard';
import { getProviders } from '../actions';

const styles = (theme) => ({
  root: {},
  content: {
    maxWidth: 1000,
    margin: 'auto',
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
  },
  heading: {
    padding: theme.spacing(1),
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '76px',
  },
});

class ProvidersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // providers: [],
      // getProvidersSuccess: false,
    };
  }

  componentDidMount() {
    if (this.props.providers.length === 0) {
      this.props.getProviders();
    }
  }

  render() {
    const { classes } = this.props;

    const content =
      this.props.getProvidersSuccess === true ? (
        <div className={classes.content}>
          <div className={classes.heading}>
            <Typography variant="h2" color="secondary">
              Providers
            </Typography>
          </div>
          {this.props.providers.map((e) => (
            <ProviderCard provider={e} key={e.provider_id} />
          ))}
        </div>
      ) : (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      );

    return (
      <>
        <Helmet>
          <title>Providers | CPEU.org</title>
        </Helmet>
        {content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  providers: state.providerReducer.providers,
  getProvidersSuccess: state.providerReducer.getProvidersSuccess,
});

const mapActionsToProps = {
  getProviders,
};

const styledComponent = withStyles(styles)(ProvidersView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
