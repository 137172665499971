/* eslint-disable react/jsx-no-bind */

import React, { useState } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import PaymentForm from './PaymentForm';
import Product from './Product';

const styles = (theme) => ({
  root: {
    // padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    textAlign: 'center',
  },
  productBoxes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paymentForm: {
    // width: '50%',
  },
});
// NEED TO GET PRICES -> Add GET
const products = [
  {
    key: 0,
    price: '$8.99',
    name: 'Monthly Plan',
    interval: 'month',
    billed: 'monthly',
    due: '899',
    priceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
  },
  {
    key: 1,
    price: '$7.99',
    name: 'Annual Plan',
    interval: 'month',
    billed: 'yearly',
    due: '9588',
    priceId: process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID,
  },
];

function Prices(props) {
  const { classes, handleSubscriptionStatusChange } = props;
  const [productSelected, setProduct] = useState(null);

  function handleClick(key) {
    setProduct(products[key]);
  }

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h4" color="secondary">
          Subscribe to a plan
        </Typography>
        Get one and a half months free with annual plan.
      </div>
      <div className={classes.productBoxes}>
        {products.map((product) => {
          return (
            <Product
              key={product.key}
              product={product}
              handleClick={handleClick}
              productSelected={productSelected}
            />
          );
        })}
      </div>
      <div className={classes.paymentForm}>
        {productSelected ? (
          <PaymentForm
            productSelected={productSelected}
            handleSubscriptionStatusChange={handleSubscriptionStatusChange}
          />
        ) : null}
      </div>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(Prices);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
