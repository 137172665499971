/* eslint-disable no-console */
import axiosWithAuth from '../utils/axiosWithAuth';
import auth0Client from '../auth/Auth';

// eslint-disable-next-line import/no-cycle
import { onLoginSetupLP } from './lpActions';
import { getBookmarksList } from './bookmarksActions';
import { getCompletedList } from './completedActions';

import { showSnackbar } from './uiActions';
import { errorWebhook } from './slackActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------USER------->
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const FIRST_LOGIN = 'FIRST_LOGIN';

// <------AUTH0------->
export const AUTH0_SET_SESSION = 'AUTH0_SET_SESSION';
export const AUTH0_SIGN_IN = 'AUTH0_SIGN_IN';
export const AUTH0_SILENT_AUTH = 'AUTH0_SILENT_AUTH';
export const AUTH0_SILENT_AUTH_SUCCESS = 'AUTH0_SILENT_AUTH_SUCCESS';
export const AUTH0_SILENT_AUTH_FAILURE = 'AUTH0_SILENT_AUTH_FAILURE';
export const AUTH0_HANDLE_AUTHENTICATION = 'AUTH0_HANDLE_AUTHENTICATION';
export const AUTH0_HANDLE_AUTHENTICATION_SUCCESS = 'AUTH0_HANDLE_AUTHENTICATION_SUCCESS';
export const AUTH0_HANDLE_AUTHENTICATION_FAILURE = 'AUTH0_HANDLE_AUTHENTICATION_FAILURE';

// <------STRIPE------->
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const getUser = () => (dispatch) => {
  // console.log('******getUser******');
  dispatch({ type: GET_USER });
  axiosWithAuth()
    .get(`${url}/api/users/${auth0Client.getProfile().sub}`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: GET_USER_SUCCESS, payload: res.data });
      dispatch(getBookmarksList());
      dispatch(getCompletedList());
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(GET_USER_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_USER_FAILURE, payload: err.message });
    });
};

export const updateUser = (user) => (dispatch) => {
  // console.log('******updateUser******');
  dispatch({ type: UPDATE_USER });
  return axiosWithAuth()
    .put(`${url}/api/users/`, user)
    .then((res) => {
      // console.log(res);
      return dispatch({ type: UPDATE_USER_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log('updateUser err', err.response);
      dispatch(errorWebhook(UPDATE_USER_FAILURE, JSON.stringify(err.response)));
      return dispatch({ type: UPDATE_USER_FAILURE, payload: err.response });
    });
};

export const firstLogin = () => (dispatch) => {
  // console.log('******setSessionAuth0******');
  dispatch({ type: FIRST_LOGIN });
};

export const userLogin = (history) => (dispatch, getState) => {
  // console.log('******userLogin******');
  let user = {};
  dispatch({ type: USER_LOGIN });
  axiosWithAuth()
    .post(`${url}/api/users/login/`)
    .then((res) => {
      // console.log(res);
      // eslint-disable-next-line prefer-destructuring
      user = res.data[0];
      dispatch({ type: USER_LOGIN_SUCCESS, payload: user });
      if (user.stripe_customer_id === null) {
        // console.log('USER_LOGIN_SUCCESS user', user);
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(createCustomer(user.email));
      }
    })
    .then(() => {
      // If they have logged on before and onboarded
      if (user.onboarded) {
        // If the Bookmarks are not saved in the store, get them
        if (!getState().bookmarksReducer.getBookmarksListSuccess) {
          dispatch(getBookmarksList());
        }
        // If the Completed are not saved in the store, get them
        if (!getState().completedReducer.getCompletedListSuccess) {
          dispatch(getCompletedList());
        }

        // After the user logs in, update LP
        dispatch(onLoginSetupLP(user));
      } else {
        // console.log('push it', history);
        history.push('/onboarding');
      }
    })
    .then(() => {
      dispatch(firstLogin());
    })
    .catch((err) => {
      if (err.message === 'Network Error') {
        dispatch(
          showSnackbar(
            'There appears to be a network error.Are you offline? If this keeps happening contact us at hello@cpeu.org',
            'error'
          )
        );
      }
      dispatch(errorWebhook(USER_LOGIN_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: USER_LOGIN_FAILURE, payload: err.message });
    });
};

// <------AUTH0------->

export const setSessionAuth0 = () => (dispatch) => {
  // console.log('******setSessionAuth0******');
  dispatch({ type: AUTH0_SET_SESSION, payload: auth0Client.getProfile() });
};

export const signInAuth0 = () => (dispatch) => {
  // console.log('******signInAuth0******');
  dispatch({ type: AUTH0_SIGN_IN });
  auth0Client.signIn();
};

export const silentAuth = (pathname, history) => (dispatch) => {
  // console.log('******silentAuth******');
  dispatch({ type: AUTH0_SILENT_AUTH });
  localStorage.setItem('loginPathname', pathname);
  auth0Client
    .silentAuth()
    .then((result) => {
      // console.log('AUTH0_SILENT_AUTH result', result);
      if (result instanceof Error) {
        dispatch({ type: AUTH0_SILENT_AUTH_FAILURE, payload: result.message });
      } else {
        dispatch({ type: AUTH0_SILENT_AUTH_SUCCESS });
        dispatch(setSessionAuth0());
        dispatch(userLogin(history));
      }
    })
    .catch((err) => {
      // console.log('AUTH0_HANDLE_AUTHENTICATION_FAILURE catch err', err);
      dispatch({ type: AUTH0_SILENT_AUTH_FAILURE, payload: err.message });
    });
};

export const handleAuth0Authentication = (history) => (dispatch) => {
  // console.log('******handleAuth0Authentication******');
  dispatch({ type: AUTH0_HANDLE_AUTHENTICATION });

  auth0Client
    .handleAuthentication()
    .then((result) => {
      // console.log('AUTH0_HANDLE_AUTHENTICATION result', result);
      if (result instanceof Error) {
        dispatch({ type: AUTH0_HANDLE_AUTHENTICATION_FAILURE, payload: result.message });
      } else {
        dispatch({ type: AUTH0_HANDLE_AUTHENTICATION_SUCCESS });
        dispatch(setSessionAuth0());
        dispatch(userLogin(history));
      }
    })
    .catch((err) => {
      // console.log('AUTH0_HANDLE_AUTHENTICATION_FAILURE catch err', err);
      dispatch({ type: AUTH0_HANDLE_AUTHENTICATION_FAILURE, payload: err.message });
    });
};

// <------STRIPE------->

// This is dispatched when a new user logs in for the first time
// We need to create the new user at Stripe and get their ID
export const createCustomer = (email) => (dispatch) => {
  // console.log('******createCustomer******', email);
  dispatch({ type: CREATE_CUSTOMER });
  axiosWithAuth()
    .post(`${url}/api/payment/create-customer/`, { email })
    .then((res) => {
      // console.log('createCustomer res', res);
      // res.customer.id is used to map back to the customer object
      // res.setupIntent.client_secret is used to create the payment method
      dispatch({ type: CREATE_CUSTOMER_SUCCESS, payload: res.data[0] });
    })
    .catch((err) => {
      // console.log('createCustomer err', err);
      dispatch(errorWebhook(CREATE_CUSTOMER_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: CREATE_CUSTOMER_FAILURE, payload: err.message });
    });
};

// Update the customer in Stripe
export const updateCustomer = (customer) => (dispatch) => {
  // console.log('******updateCustomer******', customer);

  dispatch({ type: UPDATE_CUSTOMER });

  axiosWithAuth()
    .post(`${url}/api/payment/update-customer/`, customer)
    .then((res) => {
      // console.log('updateCustomer res', res);
      dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(UPDATE_CUSTOMER_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: UPDATE_CUSTOMER_FAILURE, payload: err.message });
    });
};
