import {
  FILTER_BY_ACTIVITY_TYPE,
  FILTER_BY_EXPIRED,
  FILTER_BY_HOURS,
  FILTER_BY_LEARNING_CODES,
  FILTER_BY_LEVEL,
  FILTER_BY_LP,
  FILTER_BY_LP_ON,
  FILTER_BY_LP_OFF,
  FILTER_BY_LP_RESET,
  FILTER_BY_PERFORMANCE_INDICATORS,
  FILTER_BY_PRICE,
  FILTER_BY_PROVIDERS,
  FILTER_BY_TEXT,
  FILTER_RESET,
  GET_FILTERED_CPES,
} from '../actions/index';

const initialState = {
  activityTypeFilterOn: false,
  activityTypeSelected: [],

  includeExpiredFilter: false,

  hoursFilterOn: false,
  hoursSelected: [0, 250],
  hoursRange: [0, 250],

  learningCodesFilterOn: false,
  learningCodesSelected: [],

  lpFilterOn: false,
  lpFilterSet: false,

  levelFilterOn: false,
  levelSelected: [1, 2, 3],

  performanceIndicatorsFilterOn: false,
  performanceIndicatorsSelected: [],

  priceFilterOn: false,
  priceSelected: [0, 2000],
  priceRange: [0, 2000],

  providersFilterOn: false,
  providersSelected: [],

  searchText: '',

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_BY_ACTIVITY_TYPE:
      return {
        ...state,
        activityTypeFilterOn: true,
        activityTypeSelected: action.payload,
        error: null,
      };
    case FILTER_BY_EXPIRED:
      return {
        ...state,
        includeExpiredFilter: action.payload,
        error: null,
      };
    case FILTER_BY_HOURS:
      return {
        ...state,
        hoursFilterOn: true,
        hoursSelected: action.payload,
        error: null,
      };
    case FILTER_BY_LEARNING_CODES:
      return {
        ...state,
        learningCodesFilterOn: true,
        learningCodesSelected: action.payload,
        error: null,
      };
    case FILTER_BY_LEVEL:
      return {
        ...state,
        levelFilterOn: true,
        levelSelected: action.payload,
        error: null,
      };
    case FILTER_BY_LP:
      return {
        ...state,
        lpFilterOn: !state.lpFilterOn,
        error: null,
      };
    case FILTER_BY_LP_ON:
      return {
        ...state,
        lpFilterOn: true,
        lpFilterSet: true,
      };
    case FILTER_BY_LP_OFF:
      return {
        ...state,
        lpFilterOn: false,
        lpFilterSet: true,
      };
    case FILTER_BY_LP_RESET:
      return {
        ...state,
        lpFilterOn: false,
        lpFilterSet: false,
      };
    case FILTER_BY_PERFORMANCE_INDICATORS:
      return {
        ...state,
        performanceIndicatorsFilterOn: true,
        performanceIndicatorsSelected: action.payload,
        error: null,
      };
    case FILTER_BY_PRICE:
      return {
        ...state,
        priceFilterOn: true,
        priceSelected: action.payload,
        error: null,
      };
    case FILTER_BY_PROVIDERS:
      return {
        ...state,
        providersFilterOn: true,
        providersSelected: action.payload,
        error: null,
      };
    case FILTER_BY_TEXT:
      return {
        ...state,
        searchText: action.payload,
        error: null,
      };
    case FILTER_RESET:
      return {
        ...state,
        activityTypeFilterOn: false,
        activityTypeSelected: [],
        includeExpiredFilter: false,
        hoursFilterOn: false,
        hoursSelected: [0, 250],
        learningCodesFilterOn: false,
        learningCodesSelected: [],
        levelFilterOn: false,
        levelSelected: [1, 2, 3],
        lpFilterOn: false,
        performanceIndicatorsFilterOn: false,
        performanceIndicatorsSelected: [],
        priceFilterOn: false,
        priceSelected: [0, 2000],
        providersFilterOn: false,
        providersSelected: [],
        searchText: '',
        error: null,
      };

    case GET_FILTERED_CPES:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
