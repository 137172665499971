/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import { connect } from 'react-redux';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';

import IconButton from '@material-ui/core/IconButton';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Bookmark from '@material-ui/icons/Bookmark';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing(9),
    // padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '@media (max-width: 576px)': {
      padding: theme.spacing(0),
      flexDirection: 'column',
      borderRadius: '0px',
    },
  },
  content: {
    maxWidth: '1000px',
    margin: 'auto',
    display: 'flex',
  },
  heading: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    boxShadow: theme.shadows[0],
  },
  heart: {
    color: theme.palette.action.selected,
    '&:hover': {
      color: theme.palette.bookmark.hover,
    },
  },
  heartSelected: {
    color: theme.palette.bookmark.main,
    '&:hover': {
      color: theme.palette.bookmark.hover,
    },
  },
});

function EmptyBookmarks(props) {
  const { classes } = props;

  return (
    <div className={classes.content}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.heading}>
            <Typography variant="h6" color="secondary">
              This is where your bookmarks will be.
            </Typography>
          </div>
          <Typography>
            <strong>What are bookmarks?</strong>
          </Typography>
          <Typography>
            Great question! Bookmarks are what you make of them. It is an easy way to keep track of
            specific CPEUs that you might want to refer to later. Here are some ideas:
            <ul>
              <li>Keep track of all CPEUs on a topic like &apos;diabetes&apos;.</li>
              <li>Easily reference all CPEUs from your favorite provider.</li>
              <li>Make a record of that upcoming webinar.</li>
              <li>All the interesting CPEUs you come across can be kept here for later!</li>
            </ul>
          </Typography>
          <br />
          <Typography>
            <strong>How do I add a CPEU to bookmarks?</strong>
          </Typography>
          <Typography>
            All you have to do is click on the bookmark{' '}
            <IconButton
              // onClick={this.makeBookmark}
              color="default"
              size="small"
              aria-label="add to bookmarks"
            >
              <BookmarkBorder className={classes.heart} />
            </IconButton>
            icon on a CPEU and it will be automatically added here. When you click the bookmark, it
            will turn solid red{' '}
            <IconButton
              // onClick={this.removeBookmark}
              color="primary"
              size="small"
              aria-label="remove from bookmarks"
            >
              <Bookmark className={classes.heartSelected} />
            </IconButton>
            to let you know it is on the bookmarks list
          </Typography>
          <br />
          <Typography>
            <strong>Any other questions?</strong>
          </Typography>
          <Typography>
            Email us at <a href="mailto:hello@cpeu.org">hello@cpeu.org</a> and we will be happy to
            help!
          </Typography>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(EmptyBookmarks);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
