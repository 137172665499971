/* eslint-disable no-console */
import axiosWithAuth from '../utils/axiosWithAuth';

import { errorWebhook } from './slackActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------PROVIDERS------->

// Requests the entire providers array

export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAILURE = 'GET_PROVIDERS_FAILURE';

export const getProviders = () => (dispatch) => {
  dispatch({ type: GET_PROVIDERS });
  axiosWithAuth()
    .get(`${url}/api/providers/`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: GET_PROVIDERS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(GET_PROVIDERS_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_PROVIDERS_FAILURE, payload: err.message });
    });
};
