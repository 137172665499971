import React, { useState } from 'react';
import { connect } from 'react-redux';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
// import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const styles = (theme) => ({
  root: {
    width: '100%',
    // minWidth: '300px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  saveButton: {
    maxWidth: '100px',
  },
  editOpen: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 0, 2.5, 0),
    width: '100%',
  },
  detailsText: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  date: {
    width: '140px',
  },
});

function EditBox(props) {
  const { classes } = props;
  const [isOpen, setIsOpen] = useState(false);

  const content = isOpen ? (
    <div className={classes.editOpen}>
      <div>
        <Tooltip title="Edit" aria-label="edit">
          <IconButton aria-label="edit" size="small" onClick={() => setIsOpen(!isOpen)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>
      {props.date ? (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            clearable
            showTodayButton
            size="small"
            margin="dense"
            label="Date Completed"
            inputVariant="outlined"
            placeholder="MM/DD/YYYY"
            format="MM/DD/YYYY"
            value={props.date_completed}
            onChange={(value) => {
              props.handleDateChange(value, props.activity_number);
              setIsOpen(!isOpen);
            }}
            className={classes.date}
          />
        </MuiPickersUtilsProvider>
      ) : (
        <>
          <TextField
            autoFocus
            required={props.required}
            name="paragraph"
            id="paragraph"
            value={props.value}
            onChange={(e) => props.handleParagraphChange(e, props.activity_number)}
            margin="normal"
            variant="outlined"
            multiline
            style={{ width: '80%' }}
            rows={4}
          />
          <Button
            color="primary"
            variant="contained"
            size="medium"
            className={classes.saveButton}
            onClick={() => {
              props.handleParagraphSave(props.activity_number);
              setIsOpen(!isOpen);
            }}
            disabled={props.required && props.value === ''}
          >
            Save
          </Button>
        </>
      )}
    </div>
  ) : (
    <div className={classes.detailsText}>
      <Tooltip title="Edit" aria-label="edit">
        <IconButton aria-label="edit" size="small" onClick={() => setIsOpen(!isOpen)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      {props.date_completed ? moment(props.value).format('MM/DD/yyyy') : props.value}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>{content}</div>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(EditBox);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
