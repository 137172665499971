/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect } from 'react-redux';

// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    width: '100%',
    // minWidth: '300px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    margin: theme.spacing(2.5, 0),
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
  firstName: {
    width: '100%',
    marginRight: theme.spacing(3),
  },
  lastName: {
    width: '100%',
  },
  saveButton: {
    maxWidth: '100px',
  },
  editOpen: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5, 0),
  },
});

function EditCard(props) {
  const { classes } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [first_name, setFirstName] = useState(props.first_name);
  const [last_name, setLastName] = useState(props.last_name);

  const content = isOpen ? (
    <div className={classes.editOpen}>
      <Typography variant="subtitle2" color="textSecondary">
        {props.description}
      </Typography>
      <div className={classes.nameRow}>
        <TextField
          autoFocus
          required
          name="first_name"
          id="first_name"
          label="First Name"
          placeholder="Jane"
          value={first_name}
          onChange={(e) => setFirstName(e.target.value)}
          margin="normal"
          variant="outlined"
          className={classes.firstName}
        />
        <TextField
          required
          name="last_name"
          id="last_name"
          label="Last Name"
          placeholder="Doe"
          value={last_name}
          onChange={(e) => setLastName(e.target.value)}
          margin="normal"
          variant="outlined"
          className={classes.lastName}
        />
      </div>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        className={classes.saveButton}
        onClick={() => {
          props.handleNameSubmit(first_name, last_name);
          setIsOpen(!isOpen);
        }}
        disabled={first_name === '' || last_name === ''}
      >
        Save
      </Button>
    </div>
  ) : (
    <Typography variant="subtitle2" color="textSecondary">
      {props.first_name} {props.last_name}
    </Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.top}>
          <Typography variant="h5" color="secondary">
            Full Name
          </Typography>
          <Button onClick={() => setIsOpen(!isOpen)}>Edit</Button>
        </div>

        {content}
      </div>
      <Divider />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

const styledComponent = withStyles(styles)(EditCard);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
