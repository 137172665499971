import React from 'react';

// Icons
import Description from '@material-ui/icons/Description';
import OndemandVideo from '@material-ui/icons/OndemandVideo';
import Update from '@material-ui/icons/Update';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Store from '@material-ui/icons/Store';
import Work from '@material-ui/icons/Work';
import Extension from '@material-ui/icons/Extension';
import Create from '@material-ui/icons/Create';
import Person from '@material-ui/icons/Person';
import InsertPhoto from '@material-ui/icons/InsertPhoto';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import Group from '@material-ui/icons/Group';
import Devices from '@material-ui/icons/Devices';
import HeadsetIcon from '@material-ui/icons/Headset';
import Computer from '@material-ui/icons/Computer';
import Book from '@material-ui/icons/Book';
import Videocam from '@material-ui/icons/Videocam';
import Language from '@material-ui/icons/Language';

export type ActivityKey =
  | '120'
  | '171'
  | '110'
  | '130'
  | '140'
  | '150'
  | '160'
  | '170'
  | '180'
  | '210'
  | '230'
  | '175'
  | '700'
  | '710'
  | '720'
  | '730'
  | '740'
  | 'null';

export type IconType = { [key in ActivityKey]: React.ReactElement };

export const icons = {
  '120': <Description />,
  '171': <OndemandVideo />,
  '110': <LibraryBooks />,
  '130': <Store />,
  '140': <Work />,
  '150': <Extension />,
  '160': <Create />,
  '170': <Person />,
  '180': <InsertPhoto />,
  '210': <LocalLibrary />,
  '230': <Group />,
  '175': <Devices />,
  '700': <HeadsetIcon />,
  '710': <Computer />,
  '720': <Book />,
  '730': <Videocam />,
  '740': <Language />,
  null: <Update />,
} as IconType;

export const colors = {
  '120': '#f0d6b3',
  '171': '#f7a687',
  '110': '#7ea9b8',
  '130': '#b888c4',
  '140': '#afbfbb',
  '150': '#e0987d',
  '160': '#b8b582',
  '170': '#fce795',
  '180': '#4eb6ac',
  '210': '#a9bc92',
  '230': '#d6867c',
  '175': '#87ade0',
  '700': '#cf8c37',
  '710': '#a8e2f0',
  '720': '#d5b4b3',
  '730': '#a6dc9c',
  '740': '#6f6087',
  null: '#e0969b',
};
