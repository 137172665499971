/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Tada from 'react-reveal/Tada';

// Style imports
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import { addCompleted, deleteCompleted } from '../../actions';

import { useAppSelector, useAppDispatch } from '../../utils/hooks';

const useStyles = makeStyles((theme) => ({
  done: {
    color: theme.palette.action.selected,
    '&:hover': {
      color: theme.palette.completed.hover,
    },
  },
  doneSelected: {
    color: theme.palette.completed.main,
    '&:hover': {
      color: theme.palette.completed.hover,
    },
  },
  loggedOut: {
    color: theme.palette.action.selected,
  },
}));

function CpeCompleted({ cpe_id }: { cpe_id: string }) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const inputRef = useRef(null);

  const loggingInSuccess = useAppSelector((state) => state.userReducer.loggingInSuccess);
  const completedList = useAppSelector((state) => state.completedReducer.completedList);

  const [complete, setComplete] = useState(completedList[cpe_id]);

  const makeCompleted = () => {
    setComplete(true);
    dispatch(addCompleted(cpe_id));
  };

  const removeCompleted = () => {
    setComplete(false);
    dispatch(deleteCompleted(cpe_id));
  };

  const loggedInCompleted =
    complete === true ? (
      <div ref={inputRef}>
        <IconButton
          onClick={removeCompleted}
          color="primary"
          size="small"
          aria-label="remove from completed"
        >
          <EmojiEventsIcon className={classes.doneSelected} />
        </IconButton>
      </div>
    ) : (
      <IconButton
        onClick={makeCompleted}
        color="default"
        size="small"
        aria-label="add to completed"
      >
        <EmojiEventsOutlinedIcon className={classes.done} />
      </IconButton>
    );
  const completedComponent = loggingInSuccess ? (
    loggedInCompleted
  ) : (
    <IconButton color="default" size="small" className={classes.loggedOut}>
      <EmojiEventsOutlinedIcon />
    </IconButton>
  );

  return (
    <Tada spy={complete === true}>
      <Tooltip title="Add to Completed" aria-label="add to completed">
        {completedComponent}
      </Tooltip>
    </Tada>
  );
}

export default CpeCompleted;
