/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Style imports
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getCpeDetails, getUser } from '../actions';

import CpeEdit from '../components/cpe/CpeEdit';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(9),
    padding: theme.spacing(3, 2),
    width: '100%',

    '@media (max-width: 576px)': {
      maxWidth: '540px',
      flexDirection: 'column',
    },
  },
  content: {
    maxWidth: 1000,
    margin: 'auto',
    display: 'flex',
  },
  card: {
    width: 448,
    margin: 18,
    // minWidth: 475,
  },
  selection: {
    width: '25%',
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
  cpes: {
    // width: "75%",
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
});

class CpeEditView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // cpeDetails: [],
      // getCpeDetailsSuccess: false,
      // loggedIn: false,
    };
  }

  componentDidMount() {
    console.log('props.computedMatch.params', this.props.computedMatch.params);
    console.log('this.props.cpeDetails.length', this.props.cpeDetails.length);
    console.log('this.props.cpeDetails', this.props.cpeDetails);
    // CPEs
    this.props.getCpeDetails(this.props.computedMatch.params.activity_number);
  }

  componentDidUpdate() {
    console.log('this.props.cpeDetails', this.props.cpeDetails);
    if (this.props.updateCpeSuccess === true) {
      this.props.getCpeDetails(this.props.computedMatch.params.activity_number);
    }
  }

  render() {
    const { classes } = this.props;
    let restricted = <CircularProgress />;

    if (this.props.loggingInSuccess === true && this.props.admin === true) {
      restricted = <CpeEdit details={this.props.cpeDetails} />;
    } else if (this.props.loggingInSuccess === true && this.props.admin !== true) {
      this.props.history.push(`/cpe/${this.props.computedMatch.params.activity_number}`);
    } else {
      restricted = <CircularProgress />;
    }

    const content =
      this.props.getCpeDetailsSuccess === true ? (
        <div className={classes.content}>
          <div className={classes.root}>{restricted}</div>
        </div>
      ) : (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      );

    return (
      <>
        <Helmet>
          <title>Edit CPEU | CPEU.org</title>
        </Helmet>
        {content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cpeDetails: state.cpeReducer.cpeDetails,
  getCpeDetailsSuccess: state.cpeReducer.getCpeDetailsSuccess,
  updateCpeSuccess: state.cpeReducer.updateCpeSuccess,
  admin: state.userReducer.user.is_admin,
  loggingIn: state.userReducer.loggingIn,
  loggingInSuccess: state.userReducer.loggingInSuccess,
  user: state.userReducer.user,
});

const mapActionsToProps = {
  getCpeDetails,
  getUser,
};

const styledComponent = withStyles(styles)(CpeEditView);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
