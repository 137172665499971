/* eslint-disable consistent-return */

// import ReactGA from 'react-ga';
// import auth0Client from '../auth/Auth';

// const trackingId = 'UA-165700275-2';
// ReactGA.initialize(trackingId);
// ReactGA.set({
//   userId: auth0Client.getProfile().user_id,
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// });

// import { Location, LocationListener, UnregisterCallback } from 'history';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';

const sendPageView = (location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  // console.debug('GA|Pageview Sent: ', location.pathname);
};

// interface Props {
//   children: JSX.Element;
//   trackingId?: string;
// }
const GAListener = ({ children, trackingId }) => {
  const history = useHistory();
  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId);
      sendPageView(history.location, 'REPLACE');
      return history.listen(sendPageView);
    }
  }, [history, trackingId]);

  return children;
};

// export default GAListener;

const mapStateToProps = (state) => ({
  auth0_id: state.userReducer.user.auth0_id,
});

const mapActionsToProps = {};

// const styledComponent = withStyles(styles)(Prices);

export default connect(mapStateToProps, mapActionsToProps)(GAListener);
