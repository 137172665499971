/* eslint-disable camelcase */
import axiosWithAuth from '../utils/axiosWithAuth';

import { showSnackbar } from './uiActions';
import { errorWebhook } from './slackActions';

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// <------COMPLETED------->

export const GET_COMPLETED = 'GET_COMPLETED';
export const GET_COMPLETED_SUCCESS = 'GET_COMPLETED_SUCCESS';
export const GET_COMPLETED_FAILURE = 'GET_COMPLETED_FAILURE';
export const GET_COMPLETED_LIST = 'GET_COMPLETED_LIST';
export const GET_COMPLETED_LIST_SUCCESS = 'GET_COMPLETED_LIST_SUCCESS';
export const GET_COMPLETED_LIST_FAILURE = 'GET_COMPLETED_LIST_FAILURE';
export const ADD_COMPLETED = 'ADD_COMPLETED';
export const ADD_COMPLETED_SUCCESS = 'ADD_COMPLETED_SUCCESS';
export const ADD_COMPLETED_FAILURE = 'ADD_COMPLETED_FAILURE';
export const UPDATE_COMPLETED = 'UPDATE_COMPLETED';
export const UPDATE_COMPLETED_SUCCESS = 'UPDATE_COMPLETED_SUCCESS';
export const UPDATE_COMPLETED_FAILURE = 'UPDATE_COMPLETED_FAILURE';
export const DELETE_COMPLETED = 'DELETE_COMPLETED';
export const DELETE_COMPLETED_SUCCESS = 'DELETE_COMPLETED_SUCCESS';
export const DELETE_COMPLETED_FAILURE = 'DELETE_COMPLETED_FAILURE';

export const getCompleted = () => (dispatch) => {
  dispatch({ type: GET_COMPLETED });
  axiosWithAuth()
    .get(`${url}/api/completed/`)
    .then((res) => {
      // console.log("GET_COMPLETED_SUCCESS response", res);
      dispatch({ type: GET_COMPLETED_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      // console.log("GET_COMPLETED_FAILURE err", err);
      dispatch(errorWebhook(GET_COMPLETED_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_COMPLETED_FAILURE, payload: err.message });
    });
};

export const getCompletedList = () => (dispatch) => {
  dispatch({ type: GET_COMPLETED_LIST });
  axiosWithAuth()
    .get(`${url}/api/completed/list/`)
    .then((res) => {
      // console.log("GET_COMPLETED_LIST_SUCCESS response", res);
      const completedDict = {};
      // eslint-disable-next-line no-return-assign
      res.data.map((e) => (completedDict[e] = true));
      // console.log("completedDict", completedDict)
      dispatch({ type: GET_COMPLETED_LIST_SUCCESS, payload: completedDict });
    })
    .catch((err) => {
      // console.log("GET_COMPLETED_LIST_FAILURE err", err);
      dispatch(errorWebhook(GET_COMPLETED_LIST_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: GET_COMPLETED_LIST_FAILURE, payload: err.message });
    });
};

export const addCompleted = (cpe_id) => (dispatch) => {
  dispatch({ type: ADD_COMPLETED });
  axiosWithAuth()
    .post(`${url}/api/completed/${cpe_id}`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: ADD_COMPLETED_SUCCESS, payload: res.data });
    })
    .then(() => {
      // After adding the completed, update the list
      dispatch(getCompletedList());
      dispatch(showSnackbar('Added to completed!', 'success'));
    })
    .catch((err) => {
      // console.log(err);
      dispatch(
        showSnackbar(
          'Adding to completed list failed. Please try again or contact us if this keeps happening. (hello@cpeu.org)',
          'error'
        )
      );
      dispatch(errorWebhook(ADD_COMPLETED_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: ADD_COMPLETED_FAILURE, payload: err.message });
    });
};

export const updateCompleted = (completed) => (dispatch) => {
  dispatch({ type: UPDATE_COMPLETED });
  axiosWithAuth()
    .put(`${url}/api/completed/`, completed)
    .then((res) => {
      // console.log(res);
      dispatch({ type: UPDATE_COMPLETED_SUCCESS, payload: res.data });
    })
    // .then(() => {
    //   // After updating the completed, update the list
    //   dispatch(getCompletedList());
    // })
    .catch((err) => {
      // console.log(err);
      dispatch(errorWebhook(UPDATE_COMPLETED_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: UPDATE_COMPLETED_FAILURE, payload: err.message });
    });
};

export const deleteCompleted = (cpe_id) => (dispatch) => {
  dispatch({ type: DELETE_COMPLETED });
  axiosWithAuth()
    .delete(`${url}/api/completed/${cpe_id}`)
    .then((res) => {
      // console.log(res);
      dispatch({ type: DELETE_COMPLETED_SUCCESS, payload: res.data });
    })
    .then(() => {
      // After deleting the completed, update the list
      dispatch(getCompletedList());
      dispatch(showSnackbar('Removed from completed.', 'info'));
    })
    .catch((err) => {
      // console.log(err);
      dispatch(
        showSnackbar(
          'Removing from completed list failed. Please try again or contact us if this keeps happening. (hello@cpeu.org)',
          'error'
        )
      );
      dispatch(errorWebhook(DELETE_COMPLETED_FAILURE, JSON.stringify(err.response)));
      dispatch({ type: DELETE_COMPLETED_FAILURE, payload: err.message });
    });
};
